<div class="global-searcher-parent">
  <div>
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="white global-searcher onboarading-big-mama" floatLabel="always">
      <input (keyup.enter)="search(input.value)" (input)="search(input.value)" #input matInput
        [placeholder]="responsiveS.w > 700 ? 'Buscar clientes, productos o vehículos' : 'Buscar'" [formControl]="myControl"
        [matAutocomplete]="auto">
      <mat-icon matSuffix class="cp" (click)="search(input.value)">search</mat-icon>
      <mat-autocomplete [displayWith]="getOptionText" #auto="matAutocomplete" class="panel300">
        <mat-option class="big-mama-loading" *ngIf="loading">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </mat-option>
        <!-- GROUP RESULTS -->
        <div *ngIf="hasResults()">
          <mat-optgroup *ngFor="let g of resultOptionsGroup" [label]="g.groupId">
            <mat-option *ngFor="let option of g.options" [value]="option" (onSelectionChange)="optionClick(option)">
              <div>
                <p style="margin-bottom : 0px;">
                  <mat-icon>{{option.icon}}</mat-icon>
                  {{option.label}}
                  <span style="white-space: break-spaces;" class="c_t2 fss">{{option.sublabel}}</span>
                </p>
              </div>
            </mat-option>
          </mat-optgroup>
        </div>

        <!-- NO RESULT -->
        <mat-option *ngIf="showNoData()">
          <div>
            <p style="margin-bottom : 0px;">
              <mat-icon>search</mat-icon>
              {{loading ? 'Cargando resultados' : 'Sin resultados'}}
            </p>
          </div>
        </mat-option>

      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>