import { getWarnColor } from "@sinigual/angular-lib";
import { M_Vehicle } from "../models/M_Vehicle";

export class AutocompleteColor{
    constructor(  public color : string, public name : string){}
  }

export const allColors =[
    new AutocompleteColor("#f55142", "Rojo"),
    new AutocompleteColor("#f59c42", "Naranja"),
    new AutocompleteColor("#f5ef42", "Amarillo"),
    new AutocompleteColor("#aaf542", "Verde lima"),
    new AutocompleteColor("#33ab37", "Verde"),
    new AutocompleteColor("#33aba9", "Turquesa"),
    new AutocompleteColor("#4287f5", "Azul"),
    new AutocompleteColor("#06139c", "Azul oscuro"),
    new AutocompleteColor("#5133ab", "Morado"),
    new AutocompleteColor("#de7cd6", "Rosa"),
    new AutocompleteColor("#242122", "Negro"),
    new AutocompleteColor("#f2edef", "Blanco"),
];

export function getColorByName(name : string) : string | undefined {
    if (name){
        for (let i =0; i < allColors.length; i++){
            let element = allColors[i];
            if(element.name.toLocaleLowerCase() == name.toLocaleLowerCase()){
                return element.color;
            }
        }
    }
    return undefined;
}

export function getColorByVehicle(v : M_Vehicle | undefined){
    if (v){
      let c = getColorByName(v.color);
      if (c){
        return c;
      }
    }

    return getWarnColor()
}