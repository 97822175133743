import { CustomDate, CustomFile, match, M_Parser, parseOBJ, SearchFiltrable, Endpoint, getArrayOf } from "@sinigual/angular-lib";
import { M_Action } from "./M_Action";
import { M_Invoice } from "./M_Invoice";
import { M_Vehicle } from "./M_Vehicle";
import { I_ClassSearcher } from "../interfaces/I_ClassSearcher";
import { endpoints } from "../api/Enpoints";
import { M_LastMovements } from "./M_LastMovement";
import { COUNTRIES_DB, COUNTRIES_DB_ES, Country } from "@angular-material-extensions/select-country";
import { COUNTRY_ESP } from "../constants/constants";
import { M_AdminCenters } from "./M_AdminCenters";

export class M_Client implements M_Parser, SearchFiltrable, I_ClassSearcher<M_Client> {
    needsToBeParsed = true;
    client_id: number;
    company_scope_id: number;
    company_id: number
    email: string | undefined;
    nif: string;
    address: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    province: string | undefined;
    phone: string | undefined;
    phone2: string | undefined;
    last_reception: CustomDate | undefined;
    createdAt: CustomDate | undefined;
    updatedAt: CustomDate | undefined;
    dob: CustomDate | undefined; //Date of birth
    name: string;
    surname: string;
    vehicles: M_Vehicle[] = [];
    zip: string | undefined;
    countable_num: string | undefined;
    observations: string | undefined;
    dni_front: CustomFile | undefined;
    dni_back: CustomFile | undefined;
    documentation: CustomFile[] | undefined;
    email_always: boolean = false;
    profile: boolean = false;
    marketing: boolean = false;
    newsletter: boolean = false;
    is_company: boolean = false;
    actions: M_Action[] = [];
    invoices: M_Invoice[] = [];
    last_movement: M_LastMovements[] = [];
    deleted: boolean = false;
    minify = false;
    discount: number;
    country: Country;
    tax_free: boolean = false;
    cli_is_invoice_e: boolean = false
    clients_admincenters: M_AdminCenters[] = []

    constructor(d: any) {
        this.client_id = d.id ? d.id : d.client_id ? d.client_id : undefined;
        this.company_scope_id = d.countable_num;
        this.company_id = d.company_id;
        this.name = d.name;
        this.cli_is_invoice_e = d.cli_is_invoice_e;
        this.clients_admincenters = d.clients_admincenters ? getArrayOf(M_AdminCenters, d.clients_admincenters) : [];
        this.surname = d.surname;
        this.zip = d.zip;
        this.nif = d.nif;
        this.email = d.email;
        this.address = d.address;
        this.address2 = d.address2;
        this.city = d.city;
        this.province = d.province;
        this.phone = d.phone;
        this.phone2 = d.phone2;
        this.countable_num = d.countable_num;
        this.observations = d.observations;
        this.deleted = d.deleted;
        this.last_reception = d.last_reception ? new CustomDate(d.last_reception) : undefined;
        this.createdAt = d.created_at ? new CustomDate(d.created_at) : undefined;
        this.updatedAt = d.updated_at ? new CustomDate(d.updated_at) : undefined;
        this.dob = d.dob ? new CustomDate(d.dob) : undefined;
        this.is_company = d.company ? d.company : d.is_company ? d.is_company : false;
        this.email_always = d.email_always ? d.email_always : false;
        this.discount = d.discount;
        this.tax_free = d.tax_free;
        if (d.last_movement) {
            this.last_movement = getArrayOf(M_LastMovements, d.last_movement);
        } else {
            this.last_movement = [];
        }
        /** Dni images */
        if (d.images) {
            for (let i = 0; i < d.images.length; i++) {
                if (d.images[i].name != undefined) {
                    if (d.images[i].name == "dni_front") {
                        this.dni_front = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id);
                    }
                    else if (d.images[i].name == "dni_back") {
                        this.dni_back = new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id);
                    }
                    else if (d.images[i].name == "docu_img") {
                        if (!this.documentation) { this.documentation = [] }
                        this.documentation.push(new CustomFile(d.images[i].file, undefined, undefined, d.images[i].id));
                    }
                }
            }
        }

        /** User consents */
        if (d.gdrps) {
            this.profile = d.gdrps.profile ? d.gdrps.profile : false
            this.marketing = d.gdrps.marketing ? d.gdrps.marketing : false
            this.newsletter = d.gdrps.newsletter ? d.gdrps.newsletter : false
        }
        else if (d.profile || d.marketing || d.newsletter) {
            this.profile = d.profile ? d.profile : false
            this.marketing = d.marketing ? d.marketing : false
            this.newsletter = d.newsletter ? d.newsletter : false
        }

        /** User vehicles */
        if (d.vehicles) {
            for (let i = 0; i < d.vehicles.length; i++) {
                this.vehicles.push(new M_Vehicle(d.vehicles[i]))
            }
        }

        if (d.actions) {
            for (let i = 0; i < d.actions.length; i++) {
                this.actions.push(new M_Action(d.actions[i]));
            }
        }

        if (d.invoices) {
            for (let i = 0; i < d.invoices.length; i++) {
                this.invoices.push(new M_Invoice(d.invoices[i]));
            }
        }

        this.last_reception = this.getLastReceptionByActions();
        this.country = this.getCountry(d.country);
    }

    getCountry(country: any): Country {

        if (!country) { return COUNTRY_ESP; }

        /** Gettign coutntry from backend */
        if (country && typeof country == "string") {
            let filtered = COUNTRIES_DB_ES.find(c => c.alpha2Code == country);
            if (filtered) { return filtered; }
        }
        /** Creating client object form frontend */
        else if ('alpha2Code' in country) {
            return country;
        }

        /** By default spain */
        return COUNTRY_ESP;
    }

    parse() {
        let obj = parseOBJ(this);
        if (obj.last_reception) {
            obj.last_reception = new CustomDate(obj.last_reception).dataBaseFormat;
        }
        if (obj.dob) {
            obj.dob = new CustomDate(obj.dob).dataBaseFormat;
        }
        if (obj.vehicles) {
            for (let i = 0; i < obj.vehicles.length; i++) {
                obj.vehicles[i] = obj.vehicles[i].parse();;
            }
        }
        return obj;
    }

    getPhoneIfExists() {
        return this.phone ? this.phone : this.phone2 ? this.phone2 : undefined;
    }

    getLocation() {
        if (!this.address && !this.zip && !this.city) {
            return undefined;
        }
        else {
            let str: string[] = [];
            if (this.address) {
                str.push(this.address);
            }
            if (this.zip) {
                str.push(this.zip);
            }
            if (this.city) {
                str.push(this.city);
            }

            let finalStr = "";
            str.forEach((v, i) => {
                if (i != str.length - 1 && str.length > 1) {
                    finalStr += v + ", "
                }
                else {
                    finalStr += v;
                }
            })

            return finalStr;
        }
    }

    getName() {
        return this.is_company ? this.name : this.name + " " + this.surname;
    }
    getDiscount() {

        return this.discount + "%";
    }

    hasVehicles() {
        return this.vehicles.length != 0;
    }

    hasDniFront() {
        return this.dni_front != undefined;
    }

    hasDniBack() {
        return this.dni_back != undefined;
    }

    get lastOr() {
        return this.actions.filter(action => action.isOr())
            .sort((a, b) => a.created_at.value > b.created_at.value ? -1 : 1)[0];
    }

    get orPendingArray() {
        return this.actions.filter(action => action.isOr() && action.hasPendingToInvoiceGroups() && !action.status.invoiced);
    }

    get paymentPendingArray() {
        return this.invoices.filter(invoice => !invoice.state.isPayed && !invoice.isInternoInvoice);
    }

    get advancesTotal() {
        return this.last_movement.filter(move => !move.deleted)
            .reduce((sum, current) => sum + current.import, 0);
    }

    get paymentsTotal() {
        return this.invoices.filter(invoice => !invoice.state.isPayed)
            .reduce((sum, current) => sum + current.total, 0);
    }

    get ORtotal() {
        return this.actions.reduce((sum, current) => sum + current.totalToInvoice, 0);
    }



    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text,
            this.getName(),
            this.nif,
            this.email ? this.email : '',
            this.phone ? this.phone : '',
            this.getLocation() ? this.getLocation()! : '',
            this.last_reception ? this.last_reception.dayMonthYearFormat : '')
    }

    getLastReceptionByActions() {
        if (!this.actions) { return undefined; }
        return this.actions
            .filter(a => a.type_id.isOr)
            .sort((a, b) => a.created_at.value > b.created_at.value ? -1 : 1)[0]?.created_at;
    }

    /** CLASS SEARCHER INTERFACE */
    get endpoint() { return !this.minify ? endpoints.clients : endpoints.clientsminify }
    get cs_id() { return this.client_id; }
    get icon() { return "person"; }
    get typeof() { return M_Client }
    createNew(d: any) { return new M_Client(d) }
    getInputText(): string { return this.getName(); }
    getOptionText(): [string, string] { return ([this.getName(), this.nif]) }
}