import { CustomDate } from "@sinigual/angular-lib";
import { M_Invoice } from "./M_Invoice";


export class M_HistoryAdvances  {
    id : number;
    last_movement_id:string;
    type :boolean;
    import : number;
    invoices_id:number;
    invoices : M_Invoice;
    created_at : CustomDate;

    
    constructor(d: any){
        this.id=d.id;
        this.last_movement_id = d.last_movement_id;
        this.type = d.type;
        this.import = d.import;
        this.invoices_id = d.invoices_id;
        this.invoices = d.invoices;
        this.created_at  = new CustomDate(d.created_at);
    }



}