import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Client } from '../../models/M_Client';
import { M_Product } from '../../models/M_Product';
import { M_User } from '../../models/M_User';
import { M_Vehicle } from '../../models/M_Vehicle';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonComponent implements OnInit {
  v = ViewPath;
  @Input() item! : M_Client| M_Vehicle | M_Product | M_User;
  @Output() onDelete : EventEmitter<M_Client | M_Vehicle | M_Product | M_User> = new EventEmitter()
  constructor(public d: DeleteDialogComponent, private routerS : RouterService) {}
  ngOnInit(): void {}

  openDeleteDialog(){
    this.d.show(this.item).afterClosed().subscribe(res =>{
      /** If the object/master is deleted */
      if (res) {
        this.onDelete.emit(this.item);
        this.routerS.goTo(this.getViewFromObj());
      }
    });
  }

  getViewFromObj(){
    if (this.item instanceof M_Client) {
      return this.v.clientes;
    }
    else if (this.item instanceof M_Vehicle) {
      return this.v.vehiculos;
    }
    else if (this.item instanceof M_Product) {
      return this.v.products;
    }
    else if (this.item instanceof M_User) {
      return this.v.business;
    }
    return this.v.dashboard;
  }

}
