<app-card [noStyle]="true">
    <mat-stepper linear #stepper class="hidden-stepper-header">

        <!-- FIST STEP -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¿Que es la carga de taller?</p>
                    <div mat-dialog-content>
                        <p> La carga de taller te permite visualizar <span class="fw500">el estado general</span> de tu
                            taller.</p>
                        <p> También te permite <span class="fw500">filtrar</span> las OR por su estado, tipo, operario
                            assignado...</p>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <lord-icon src="https://cdn.lordicon.com/ciqarrsc.json" trigger="loop"
                        colors="primary:{{primary()}},secondary:#ebe6ef,tertiary:{{accent()}},quaternary:#1663c7"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">Bienvenido a la carga de taller</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="warn" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- SECOND STEP -->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>¿Cómo lo utilizo?</p>
                    <div mat-dialog-content>
                        <p>Puedes ordenar todas las ordenes de reparación por <span class="fw500">prioridad.</span></p>
                        <p>Para hacerlo, <span class="fw500">arrastra</span> las ordenes de reparación
                            <span class="fw500">verticalmente.</span>
                        </p>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
                    <lord-icon src="https://cdn.lordicon.com/zgrlsioy.json" trigger="loop"
                        colors="primary:#121331,secondary:#ebe6ef,tertiary:{{primary()}}"
                        class="lord-large">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">¿Cómo lo utilizo?</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="warn" class="btn_init_comp" matStepperNext>Siguiente</button>
            </div>
        </mat-step>

        <!-- LAST STEP-->
        <mat-step>
            <div class="df aic step tac">
                <div class="step-content">
                    <p mat-dialog-title>Modificar el día de las OR</p>
                    <div mat-dialog-content>
                        <p>También Puedes modificar <span class="fw500">el día de entrega</span> de las las OR.</p>
                        <p>Para hacerlo, <span class="fw500">arrastra</span> las ordenes de reparación
                            <span class="fw500">horizontalmente.</span>
                    </div>
                </div>
                <div class="side-icon">
                    <script src="https://cdn.lordicon.com/zgrlsioy.json"></script>
                    <lord-icon class="rotate90" src="https://cdn.lordicon.com/zgrlsioy.json" trigger="loop"
                        colors="primary:#121331,secondary:#ebe6ef,tertiary:{{primary()}}"
                        class="lord-large rotate90">
                    </lord-icon>
                </div>
                <p mat-dialog-title class="phone">Modificar el día de las OR</p>
            </div>
            <div class="df jcfe">
                <button mat-flat-button color="warn" class="btn_init_comp" mat-dialog-close>Finalizar</button>
            </div>
        </mat-step>

    </mat-stepper>
    <div class="df jcc dot-indicator">
        <mat-icon (click)="go(i)" *ngFor="let step of stepper.steps; let i = index"
            [fontSet]=" stepper.selectedIndex != i ?  'material-icons-outlined' : ''">
            fiber_manual_record
        </mat-icon>
    </div>
</app-card>