<lord-icon *ngIf="client?.email_always" [matTooltip]="'Recibe las facturas por correo'"
    src="https://cdn.lordicon.com/vtdlikci.json" trigger="hover" state="morph-document" class="lord-small"
    colors="primary:#121331,secondary:{{accent()}},tertiary:#ebe6ef,quaternary:#f4a09c,quinary:#3a3347,senary:#b26836,septenary:#ffc738,octonary:#f28ba8,nonary:#f9c9c0"
    style="position: absolute; top: 15px;">
</lord-icon>

<lord-icon *ngIf="!client?.email_always" [matTooltip]="'NO recibe las facturas por correo'"
    src="https://cdn.lordicon.com/fsswexfm.json" trigger="hover" class="lord-small"
    colors="primary:#121331,secondary:#ebe6ef,tertiary:{{accent()}},quaternary:{{warn()}}"
    style="position: absolute; top: 15px;">
</lord-icon>