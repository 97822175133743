import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { endpoints } from 'src/app/core/api/Enpoints';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { M_Product } from 'src/app/core/models/M_Product';
import { M_Task } from 'src/app/core/models/M_Task';
import { AddProductComponent } from 'src/app/views/create-bill/add-product/add-product.component';
import { DragGroupComponent } from '../drag-group/drag-group.component';
import { ApiService } from 'src/app/core/api/api.service';
import { ClassSearcherComponent } from '@sinigual/angular-lib';

@Component({
  selector: 'app-drag-task',
  templateUrl: './drag-task.component.html',
  styleUrls: ['./drag-task.component.css', '../drag.component.css']
})
export class DragTaskComponent implements OnInit {
  products = M_Product;
  e = endpoints;
  @Input() task!: M_Task;
  @Input() product_list: M_Product[] = [];
  @Input() dg!: DragGroupComponent;
  @Input() canModify: boolean = true;
  @Input() abono: boolean = false;
  @ViewChild(ClassSearcherComponent) cs?: ClassSearcherComponent<M_Product>;
  @ViewChild(AddProductComponent) apc?: AddProductComponent;
  @Output() onRemoveTask: EventEmitter<any> = new EventEmitter();
  @Output() onTaskChanges: EventEmitter<any> = new EventEmitter();

  constructor(private apiS: ApiService) { }

  ngOnInit(): void { }

  onQuantityChange() {
    this.markUnsaved();
  }

  onTaskTypeChange() {
    if (this.task.getProduct()) {
      this.markUnsaved();
    }
  }

  remove(_val: { componentId: number | undefined, producLine: number | undefined }) {
    this.apiS.rmTask(this.task.task_id);
    this.dg.remove(this.task);
    this.task.changes = false;
    //this.markUnsaved();
  }


  onChangeDiscount() {
    if (this.task.getProduct()) {
      this.markUnsaved();
    }
  }

  
  onChangePrice() {
    if (this.task.getProduct()) {
      this.markUnsaved();
    }
  }

  onSelectProduct(p: M_Product | M_CustomProduct | undefined) {
    if (p instanceof M_Product || p instanceof M_CustomProduct) {
      if (this.apc && !this.apc.isInterno) {
        p.discount = this.apc.parent.getClientDiscount();
      }
      this.task.setProduct(p);
      this.apc?.setProduct(p);
      this.markUnsaved();
    }
  }

  markUnsaved() {
    this.task.changes = true;
    this.onTaskChanges.emit();
  }

  saveTask() {
    this.apiS.addEditTasks([this.task]).then(res => {
      this.task.changes = false;
    });
  }

}
