import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { M_User } from '../../models/M_User';

@Component({
  selector: 'app-userform',
  templateUrl: './userform.component.html',
  styles:[':host ::ng-deep mat-form-field, ._customInput  {width : 100%}']
})
export class UserformComponent implements OnInit {
  @Input() u? : M_User;
  public form: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      name :            ['', [Validators.required]],
      email :         [''],
    });
   }

  ngOnInit(): void {
    if (this.u != undefined){
      this.form.patchValue({
        "name" : this.u.name,
        "email" : this.u.email
      })
      this.form.get('email')?.disable();
    }
    else{
    }
  }
}
