import { environment } from "src/environments/environment";

/** Maintain control of which functionalities should be shown or hidden */
export const feature = {

    /** DEV environment or LOCAL environment */
    devOrLocal: environment.local || environment.dev,
    /** Tracking user actions */
    logRocket: environment.production,
    /** Translations */
    translations: environment.local || environment.dev,
    /** Data upload */
    dataUpload: false,
    /** Import tariff */
    importTariff: false,
    /** Global searcher */
    bigMama: environment.local || environment.production,

    /** Flotas */
    flotas : false,

    /** OR TYPES --> On removing this, check also the "carga de taller" filter!!! */
    /** Mostrar cargo interno? */
    cargoInterno: true,
    /** Mostrar garantia? */
    garantia: false
}