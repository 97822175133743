<p mat-dialog-title>Modificar observaciones</p>
<div mat-dialog-content>
    <div class="df jcc">
        <form [formGroup]="form" style="width: 100%">
            <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>Observaciones</mat-label>
                <input matInput #input formControlName="observaciones">
            </mat-form-field>
        </form>
    </div>
</div>
<div mat-dialog-actions>
    <button [mat-dialog-close]="undefined" mat-button>Cancelar</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="input.value" [disabled]="!form.valid">Modificar</button>
</div>