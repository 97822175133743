<app-page-structure [masterClass]="'or'" pageTitle="ÓRDENES DE REPARACIÓN" [autocompleteID]="'or'"
    searchLabel="Buscar or" circleIcon="or_add" [addNewView]="v.createOr" circleIconClass="onboarding-create-or"
    noDataCreateNew="Crear una nueva or" [data]="[]"
    [displayedHeader]=" ['Número',     'Matrícula',                'Marca y modelo',    'Estado',       'Tipo',     'Fecha de entrega']"
    [displayedColumns]="['title_id',   'vehicle.license',          'vehicle.brand' ,    'status',       'type',     'delivery']"
    [specialText]="     [undefined,     license,                   brand,                undefined,     undefined,   delivery]"
    [specialClass]="    [undefined,     undefined,                 undefined,            undefined,     undefined,   undefined]"
    [cellZize]="        [undefined,     undefined,                 undefined,             'big',      undefined,   undefined]"
    [card]="card" [filters]="filters" [lordIcon]="lordIcon" [filter]="f" [basicUserSubscription]="subS.isBasic"
    (onSubscriptionAddFail)="subS.openTryProDialog()">
    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/dvqeipeg.json" trigger="in" class="lord-small">
        </lord-icon>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir a la OR'" mat-flat-button color="warn"
                (click)="handleClick($event)" class="mb10" (contextmenu)="handleContextMenu($event)">
                OR Nº {{ps.selected!.title_id}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <button mat-stroked-button (click)="previsualize(ps.selected!)">
                <mat-icon>find_in_page</mat-icon>
                Apertura OR
            </button>

            <app-card-subtitle>Operario</app-card-subtitle>
            <div class="cp" *ngIf="ps.selected && ps.selected.assigned; else noData">
                <app-circular-letter [showTooltip]="false" [user]="ps.selected.assigned"></app-circular-letter>
            </div>
            <ng-template #noData>
                <p class="c_t2">Sin operario asignado</p>
            </ng-template>

            <app-card-subtitle>Cliente y vehículo</app-card-subtitle>
            <app-vehicle-row [item]="ps.selected!.vehicle!"></app-vehicle-row>
            <app-client-row [item]="ps.selected!.client!"></app-client-row>

            <app-card-footer>
                <app-whatsapp [phone]="ps.selected!.client?.phone"></app-whatsapp>
                <app-email-button [email]="ps.selected!.client?.email"></app-email-button>
                <app-phone-button [phone]="ps.selected!.client?.phone"></app-phone-button>
            </app-card-footer>
            <div class="envioMails">
                <div class="w100" style="display: contents;">
                    <app-layout-send-email *ngIf="ps.selected?.status !== undefined && ps.selected?.status?.num == 1"
                        [temaplte]="'recogida'" [endpoint]="pickUpMail.bind(this)"
                        style="justify-content: start;"></app-layout-send-email>
                </div>
                <div class="w100">
                    <app-layout-send-email *ngIf="ps.selected && ps.selected.client && !ps.selected.client.deleted"
                        [temaplte]="'resend-or'" [endpoint]="reSendOR.bind(this)"
                        [disabled]="ps.selected.client.email == undefined"></app-layout-send-email>
                </div>
            </div>
        </app-card>
    </ng-template>
</app-page-structure>