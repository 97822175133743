import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BurgerMenuInterface, BurgerOption, ResponsiveService, RouterService, Views } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { UserServiceService } from 'src/app/views/profile/user-service.service';
import { feature } from '../../features-controller/FeaturesController';
import { ReferenceService } from '../../services/onboarding.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, BurgerMenuInterface {
  @Input() isDesktop = true;
  @Input() drawer?: MatDrawer;
  @Input() refService?: ReferenceService;
  @ViewChild('menu') menu!: ElementRef<HTMLDivElement>;
  features = feature;
  opened = false;
  enter = false;
  exit = false;
  lockmenu = false;
  v = ViewPath;
  panel = new BurgerOption("Panel de control", "dashboard", this.v.dashboard);
  clientes = new BurgerOption("Clientes", "person", this.v.clientes, [this.v.createClient, this.v.clientDetails], undefined, "onboarding-client");
  vehiculos = new BurgerOption("Vehículos", "wheel", this.v.vehiculos, [this.v.createvehicle, this.v.vehicleDetails], undefined, "onboarding-vehicle");
  productos = new BurgerOption("Productos", "barcode", this.v.products, [this.v.createProduct, this.v.productDetails], undefined, "onboarding-product");
  facturas = new BurgerOption("Facturas", "receipt", this.v.facturas, undefined, undefined, "onboarding-invoice");
  empresa = new BurgerOption("Empresa", "store", this.v.business, undefined, undefined, "onboarding-company");
  or = new BurgerOption("Órdenes de reparación", "build", this.v.or, undefined, undefined, "onboarding-or");
  budget = new BurgerOption("Presupuestos", "request_quote", this.v.budget, undefined, undefined, "onboarding-budget");
  cargataller = new BurgerOption("Carga de taller", "date_range", this.v.cargataller, undefined, undefined, "onboarding-workload");
  upload = new BurgerOption("Importar datos", "upload_file", this.v.dataupload, undefined, undefined, "onboarding-upload");
  subscription = new BurgerOption("Subscripción", "credit_card", this.v.subscription);
  issues = new BurgerOption("Incidencias", "support_agent", this.v.issues);

  constructor(private routerS: RouterService, public userS: UserServiceService, private responsiveS: ResponsiveService) { }

  goTo(v: Views) {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.opened = false;
      }
    }
    else {
      if (this.drawer) {
        this.drawer.close()
        this.opened = false;
      }
    }
    this.routerS.goTo(v);
  }

  get hiddenToUser() {
    return this.responsiveS.isPhone();
  }

  ngOnInit(): void {
    /** When the onboarding inits, aut oassigns itself to the service input parameter.
     *  Like this, is possible to call this component inside the service of 'refService',
     */
    if (this.refService) {
      this.refService.menuComponent = this;
    }
  }

  onMouseEnterComponent() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.enter = true;
        this.exit = false;
        setTimeout(() => {
          if (!this.exit) {
            if (this.isDesktop) {
              this.open();
            }
          }
        }, 350);
      }
    }
  }

  onMouseLeaveComponent() {
    if (this.isDesktop) {
      if (!this.lockmenu) {
        this.exit = true;
        this.enter = false;
        if (this.isDesktop) {
          this.close();
        }
      }
    }
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
    if (!this.drawer) {
      this.lockmenu = false;
    }
  }

  toggle() {
    if (this.drawer) {
      if (this.drawer.opened) {
        this.drawer.close();
        this.opened = false;
      }
      else {
        this.drawer.open();
        this.opened = true;
      }
    }
    else {
      this.lockmenu = !this.lockmenu;
      this.opened = !this.opened;
    }
  }
  openDiscount(){}
  toggleTo(opened: boolean) {
    if (this.drawer) {
      if (!opened) {
        this.drawer.close();
        this.opened = false;
      }
      else {
        this.drawer.open();
        this.opened = true;
      }
    }
    else {
      this.lockmenu = opened;
      this.opened = opened;
    }
  }
}
