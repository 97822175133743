import { ApexNonAxisChartSeries, ApexChart, ApexPlotOptions, ApexFill, ApexStroke, ApexResponsive, ApexGrid, ApexDataLabels, ApexLegend } from "ng-apexcharts";
import { R_Dashboard } from "src/app/core/api/responses/R_Dashboard";

export type SemiCircleChart = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    plotOptions: ApexPlotOptions;
    grid: ApexGrid
    labels: string[];
    colors: string[];
    legend: ApexLegend;
    stroke: ApexStroke
    dataLabels : ApexDataLabels
};

export function semiCircleChart(d: R_Dashboard): Partial<SemiCircleChart> {
    const workloadChart: Partial<SemiCircleChart> = {
        series: [d.bill_pending, d.open_or, d.companyPlacesDis <0 ? 0: d.companyPlacesDis],
        chart: {
            width: 430,
            type: "donut",
            fontFamily: 'Inter',
        },
        colors: ["#ff525b", "var(--r2)", "#afdd85"],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10
            }

        },
        grid: {
            padding: {
                bottom: -80
            }
        },
        legend: {
            position: 'bottom',
            offsetY: -80,
        },
        stroke: {
            width: 7,
            curve: "smooth",
            lineCap: "round",
        },
        dataLabels: {
            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                return w.config.series[seriesIndex]
              },
        },
        labels: ["Cerrada", "Abierta", "Disponible"],
        responsive: [
            {
                breakpoint: 1131,
                options: {
                    chart: {
                        width: 400
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: -65,
                    }
                }
            },
            {
                breakpoint: 872,
                options: {
                    chart: {
                        width: 350
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: -45,
                    }
                }

            },
            {
                breakpoint: 622,
                options: {
                    chart: {
                        width: 350
                    },
                    legend: {
                        position: 'bottom',
                        offsetY: -45,
                    }
                }

            }
        ]
    };
    return workloadChart;
}