import { Component, Input } from '@angular/core';
import { M_Client } from '../../models/M_Client';
import { M_Vehicle } from '../../models/M_Vehicle';
import { MatDialog } from '@angular/material/dialog';
import { ClientVechileInfoDialogComponent as ClientVehicleInfoDialogComponent } from './client-vechile-info-dialog/client-vechile-info-dialog.component';

@Component({
  selector: 'app-client-vehicle-in-dialog',
  templateUrl: './client-vehicle-in-dialog.component.html',
  styleUrls: ['./client-vehicle-in-dialog.component.css']
})
export class ClientVehicleInDialogComponent {

  @Input() c: M_Client | undefined;
  @Input() v: M_Vehicle | undefined;
  @Input() t: string | undefined;

  constructor(private d: MatDialog) { }

  openD() {
    this.d.open(ClientVehicleInfoDialogComponent, { data: [this.c, this.v], autoFocus: false });
  }

  openWithParams(c: M_Client | undefined) {
    this.d.open(ClientVehicleInfoDialogComponent, { data: [c], autoFocus: false });
  }
}
