<div cdkDrag [cdkDragDisabled]="!canModify || aap.currentFase == 'search'">
    <app-add-product [isOr]="true" #aap 
        style="width: 100%; position: relative;"
        [fase]="'search'" 
        [p]="task.getProduct()"
        [canModify]="canModify"
        [instantChangeEmission]="true"
        [dragHandle]="true"
        [unsavedChanges]="task.changes"
        [abono]="abono"
        (onSelectProduct)="onSelectProduct($event)"
        (onModifyStock)="onQuantityChange()"
        (onChangeDiscount)="onChangeDiscount()"
        (onChangePrice)="onChangePrice()"
        (onDestroy)="remove($event)">
        <div class="cdk-drag-handle" cdkDragHandle *ngIf="canModify && !task.isEmpty()">
            <svg width="24px" fill="var(--t2)" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </div>
    </app-add-product>
</div>
