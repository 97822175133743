<form [formGroup]="form">
    <div class="form">
        <app-card-subtitle>Datos obligatorios</app-card-subtitle>
        <div class="onboarding-vehicle-required">
            <div class="df">
                <mat-form-field class="w50" appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option [value]="1">Moto</mat-option>
                        <mat-option [value]="2">Coche</mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <app-plate-input [strict]="false" (onFocusOut)="checkPlate()" class="w50" [formCName]="'license'"
                    [form]="form"></app-plate-input> -->
                <mat-form-field class="w50" appearance="outline">
                    <mat-label>Matricula</mat-label>
                    <input
                    matInput
                    placeholder="Matricula"
                    [disabled]="!isEditing"
                    (blur)="onBlur($event)"
                    formControlName="license"
                    />
                    <button *ngIf="!Iscreate" mat-icon-button matSuffix type="button"  (click)="enableEditing()">
                    <mat-icon>edit</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('license')?.hasError('license-exists')">
                        El NIF ya existe.
                      </mat-error>
                </mat-form-field>
            </div>
        </div>
        <app-card-subtitle>Datos opcionales</app-card-subtitle>
        <div class="df fww jcc">

            <!-- CAR TYPE -->
            <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'"
                *ngIf="showMe(VT.car)">
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m1.png" text="Microcoche"
                    [formValue]="0"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m2.png" text="Urbano"
                    [formValue]="1"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m3.png" text="Sedan"
                    [formValue]="2"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m4.png" text="Descapotable"
                    [formValue]="3"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m5.png" text="Coupé"
                    [formValue]="4"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m6.png" text="Deportivo"
                    [formValue]="5"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m7.png" text="Monovolúmen"
                    [formValue]="6"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m8.png" text="Todo Terreno"
                    [formValue]="7"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m9.png" text="SUV"
                    [formValue]="8"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/car-types/m10.png" text="Camioneta"
                    [formValue]="9"></app-img-toggle-item>
            </app-image-toggle>

            <!-- MOTO TYPE -->
            <app-image-toggle [updateForm]="false" [default]="false" [form]="form" [formCName]="'serie'" *ngIf="showMe(VT.bike)">
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m1.png" text="Sport"
                    [formValue]="0"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m2.png" text="Touring"
                    [formValue]="1"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m3.png" text="Trail"
                    [formValue]="2"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m4.png" text="Scooter"
                    [formValue]="3"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m5.png" text="Offroad"
                    [formValue]="4"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m6.png" text="Custom"
                    [formValue]="5"></app-img-toggle-item>
                <app-img-toggle-item img="./assets/img/vehicle-types/bike-types/m7.png" text="Naked"
                    [formValue]="6"></app-img-toggle-item>
            </app-image-toggle>
        </div>

        <div class="df">
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Marca</mat-label>
                <input #brand matInput placeholder="Marca" formControlName="brand" [matAutocomplete]="brandAuto"
                    (focusout)="brandModelManager.refreshModelsBasedOnBrand(brand.value, true)"
                    (focus)="brandModelManager.filterBrands(brand.value)">
                <mat-autocomplete #brandAuto="matAutocomplete"
                    (optionSelected)="brandModelManager.refreshModelsBasedOnBrand($event, true)">
                    <mat-option *ngFor="let b of brandModelManager.filteredBrands"
                        [value]="b.name">{{b.name}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Modelo</mat-label>
                <input #model matInput placeholder="Modelo" formControlName="model" [matAutocomplete]="modelAuto"
                    (focus)="brandModelManager.filterModels(model.value)">
                <mat-autocomplete #modelAuto="matAutocomplete">
                    <mat-option *ngFor="let b of brandModelManager.filteredModels"
                        [value]="b.name">{{b.name}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        
        <div class="df">
            <app-simple-input #asi class="w50" [form]="form" [label]="'Chasis'" [requerido]="false"
                [placeHolder]="'El chasis debe ser de 7 o 17 caracteres'" [formCName]="'chassis'" [maxLength]="17"
                [forceUppercase]="true"  (onFocusOut)="checkChasis()"
                [errorFunction]="[validators.pattern('[A-Z0-9]{17}$|^[A-Z0-9]{7}'), 
                'El chasis debe contener 7 o 17 caracteres. (' + asi.control?.value?.length + '/17)']">
            </app-simple-input>
            <app-simple-input class="w50" label="Kilómetros" [requerido]="false" [placeHolder]="'Kilómetros'"
                [form]="form" [formCName]="'km'" [dotSeparation]="true"></app-simple-input>
        </div>

        <div class="df">
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Color</mat-label>
                <input type="text" placeholder="Seleccione un color" matInput formControlName="color"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                        <div class="df aic">
                            <div class="colorprewview" [style.background-color]="option.color"></div>
                            <div class="colorprewviewtext">{{option.name}}</div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="w50" appearance="outline">
                <mat-label>Cilindrada</mat-label>
                <input type="number" matInput placeholder="Cilindrada" formControlName="cc">
            </mat-form-field>
        </div>
        <div class="w33p">
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Tipo de motor</mat-label>
                <mat-select formControlName="engine_type" #et>
                    <mat-option [value]="0">Gasolina</mat-option>
                    <mat-option [value]="1">Eléctrico</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Nº serie batería </mat-label>
                <input matInput placeholder="Nº serie batería" formControlName="battery_1_no">
            </mat-form-field>
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Nº serie batería extra</mat-label>
                <input matInput placeholder="Nº serie batería extra" formControlName="battery_2_no">
            </mat-form-field>
        </div>
        <div class="w33p">
            <mat-form-field class="w33" appearance="outline">
                <mat-label>Matriculación</mat-label>
                <input placeholder="dd/mm/aaaa" #pickerInput matInput [matDatepicker]="picker" [max]="minDate" 
                (click)="picker.open()" formControlName="license_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w33" appearance="outline">
                <mat-label>Última ITV</mat-label>
                <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_1" [max]="minDate"
                 (click)="picker_1.open()" formControlName="last_itv">
                <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>
                <mat-datepicker #picker_1 [min]="minDate"></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w33" appearance="outline">
                <mat-label>Próxima ITV</mat-label>
                <input placeholder="dd/mm/aaaa" matInput [matDatepicker]="picker_2" [min]="minDate" 
                (click)="picker_2.open()" formControlName="next_itv">
                <mat-datepicker-toggle matSuffix [for]="picker_2"></mat-datepicker-toggle>
                <mat-datepicker #picker_2 ></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="df">
            <app-class-searcher #csearcher [ngStyle]="{'display': 'none'}" [masterClass]="client" class="w100" [width100]="true"
                [form_]="form" [formCname]="'clientInvoice_id'"
                searchPlaceHolder="Cliente a facturar por defecto" [specialRow]="'Nuevo cliente / empresa'"
                (onSpecialRow)="goCreateClient()" [extendedInfo]="false" [required]="false">
            </app-class-searcher>
        </div>
    </div>
</form>