<app-page-structure [masterClass]="'product'" pageTitle="PRODUCTOS" [autocompleteID]="'products'"
    searchLabel="Buscar producto" circleIcon="barcode" [addNewView]="v.createProduct"
    circleIconClass="onboarding-create-product" noDataCreateNew="Crear un nuevo producto" [data]="[]"
    [displayedHeader]=" ['Referencia', 'Nombre' , 'Precio unidad'  , 'Precio compra', 'Stock'   , 'Última actualización']"
    [displayedColumns]="['reference' , 'name'   , 'price'   , 'price_iva'   , 'stock'   , 'updated_at']"
    [specialText]="     [undefined   , undefined, 'money'   , 'money'       , 'decimal' , undefined]"
    [specialClass]="    [undefined, undefined, undefined, undefined, undefined, undefined]"
    [cellZize]="        ['big',    'big',   undefined,   undefined,     'small', undefined]" [card]="card"
    [preIcon]="[productIcon]" [filters]="filters" [lordIcon]="lordIcon" [middleElement]="middleElement" [filter]="f"
    [basicUserSubscription]="subS.isBasic" (onSelect)="afterSelectProduct($event)"
    (onSubscriptionAddFail)="subS.openTryProDialog()">
    <ng-template #lordIcon>
        <lord-icon src="https://cdn.lordicon.com/fjntnozg.json" trigger="in"
            colors="primary:#121331,secondary:{{warn()}},tertiary:#3a3347,quaternary:#b06836,quinary:#646e78,senary:#f7c7be"
            class="lord-small">
        </lord-icon>
    </ng-template>
    <ng-template #middleElement>
        <div *ngIf="features.dataUpload">
            <p class="entry" (click)="routerS.goTo(v.dataupload)">
                Entrada de almacén
            </p>
        </div>
    </ng-template>
    <ng-template #card>
        <app-card *ngIf="ps.showItem()">

            <button [matTooltip]="'Ir al producto'" class="infoButton" mat-flat-button color="warn"
                (click)="handleClick($event)" (contextmenu)="handleContextMenu($event,'P')"> 
                {{ps.selected!.name.length > 25 ? (ps.selected!.name | slice:0:25) + '...' : ps.selected!.name}}
                <mat-icon iconPositionEnd>arrow_forward</mat-icon>
            </button>

            <app-card-subtitle>Modificar existencias</app-card-subtitle>

            <app-add-remove *ngIf="!refreshReference" [minValue]="0" [initValue]="this.ps.selected!.stock"
                (onSave)="saveNewStock(ps.selected!, $event)"></app-add-remove>

            <app-card-subtitle>Última actualización</app-card-subtitle>
            <p>
                <mat-icon class="vam">calendar_month</mat-icon>
                {{ps.selected!.updated_at?.shortFormat}}
            </p>

            <button style="margin-top:10px;" mat-stroked-button
                (click)="handleClick($event,'F')" (contextmenu)="handleContextMenu($event,'F')">Facturar artículo</button>
        </app-card>
    </ng-template>
</app-page-structure>