import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmDialogService, CoreConfiguration, CustomFile, M_BaseUser, ProfilePictureService, RouterService, SessionService, SnackService, StorageService} from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { EnumSubscription } from 'src/app/core/enums/EnumSubscription';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { M_User } from 'src/app/core/models/M_User';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { UserServiceService } from './user-service.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteCompanyComponent } from './delete-company/delete-company.component';
import { Subject } from 'rxjs/internal/Subject';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  name : string = "";
  email : string = "";
  passChanged = false;
  u : M_User | undefined;
  es = EnumSubscription;
  feature = feature;
  onLogOut = new Subject<any>()
  public form: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder, private apiS : ApiService, private snackS : SnackService, 
              private sessionS : SessionService, public profilePicrureS : ProfilePictureService, public subS :SubscriptionService, public userS : UserServiceService,
              private d: MatDialog, private confirmD: ConfirmDialogService, public storageS : StorageService,public routerServie: RouterService) {
    this.form = this.formBuilder.group({
      pass1: [''],
      pass2: ['', [Validators.required]],
    });
   }

  ngOnInit(): void {
    this.apiS.profile().then(res =>{
      this.u = res;
    })
  }
  
  initPage() {

  }

  updatePass(pass : string){
    this.apiS.updatePass(pass).then(_res =>{
      this.passChanged = true;
      this.snackS.show("Contraseña modificada con éxito");
    })
  }

  logOut() {
    this.sessionS.logOut();
  }

  uploadImage(data : [cf : CustomFile, u : M_BaseUser]) {
    this.apiS.uploadImage(data[0], "profile").then(_res => { })
    this.profilePicrureS.onChangeProfilePicture(data);
  }
}
