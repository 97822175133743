import { Component, Input, OnInit, Optional } from '@angular/core';
import { getAccentColor, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { M_Client } from 'src/app/core/models/M_Client';
import { ParamsService } from 'src/app/core/services/params.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit {
  warn = getWarnColor;
  accent = getAccentColor;
  v = ViewPath;
  @Input() client: M_Client | undefined;
  @Input() small: boolean = false;
  @Input() lord: boolean = true;

  constructor(public paramsS: ParamsService) { }

  ngOnInit(): void { }

  hasValue(v: any) {
    return v != undefined;
  }

  goClient() {
    if (!this.client?.deleted){
      this.paramsS.go(ViewPath.clientDetails, this.client!.client_id, undefined, true)
    }
  }
}
