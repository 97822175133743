import { match, M_BaseUser, SearchFiltrable } from "@sinigual/angular-lib";
import { I_ClassSearcher } from "../interfaces/I_ClassSearcher";
import { endpoints } from "../api/Enpoints";

export class M_User extends M_BaseUser implements SearchFiltrable, I_ClassSearcher<M_User> {

   confirm_sent : boolean = false;
   is_admin : boolean = false;
   company_code: string | undefined;
   constructor(d: any) {
      super(d);
      this.confirm_sent = d.confirm_sent ? d.confirm_sent : false;
      this.is_admin = d.is_admin ? d.is_admin : false;
      this.company_code = d.company_code;
   }

   defaultSearchFilter(text: string): boolean {
      return match(text, this.name, this.email);
   }

   /** CLASS SEARCHER INTERFACE */
   get endpoint() { return endpoints.users }
   get cs_id() { return this.id; }
   get icon() { return "build"; }
   get typeof() {return M_User}
   createNew(d: any) { return new M_User(d)}
   getInputText(): string { return this.name; }
   getOptionText(): [string, string] { return ([this.name, this.email]) }
}