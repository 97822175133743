import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomDate, CustomFile, downloadBlob, getArrayOf, LoadingPanelService, M_CloudTicket, M_TicketNotificacion, NotificationInterface, parseOBJ, ReportedProblem } from '@sinigual/angular-lib';
import { M_Invoice } from '../models/M_Invoice';
import { M_Client } from '../models/M_Client';
import { M_Company } from '../models/M_Company';
import { M_CustomProduct } from '../models/M_CustomProduct';
import { M_Product } from '../models/M_Product';
import { M_Register } from '../models/M_Register';
import { M_User } from '../models/M_User';
import { M_Vehicle } from '../models/M_Vehicle';
import { endpoints } from './Enpoints';
import { M_Action } from '../models/M_Action';
import { R_Dashboard } from './responses/R_Dashboard';
import { M_ActiveReception } from '../models/M_ActiveReception';
import { M_Task } from '../models/M_Task';
import { M_Smpt } from '../models/M_Smpt';
import { M_CompanyWelcome } from '../models/M_CompanyWelcome';
import { action_type_budget } from '@sinigual/angular-lib';
import { action_types } from '@sinigual/angular-lib';
import { or_types } from '@sinigual/angular-lib';
import { fakeBigMama } from '../components/global-searcher/fakeBigMama';
import { M_Brand } from '../models/M_Brand';
import { M_Model } from '../models/M_Model';
import { M_LastMovements } from '../models/M_LastMovement';
import { M_TypePayment } from '../models/M_TypePayment';
import { resolve } from 'dns';
import { invoice_states } from '@sinigual/angular-lib/lib/custom-classes/invoice_states';
import { error } from 'console';
import { M_PayCometInvoice } from '../models/M_PayCometInvoice';
import { M_Subscription } from '../models/M_Subscription';
import { R_Zip } from '../models/R_Zip';
import { M_TemplateField, templateTypedoc } from '../models/M_TemplateField';
import { T_SaveDocumentation } from 'src/app/views/company/documentation-config/documentation-config.component';
import { EnumSubscription } from '../enums/EnumSubscription';
import { company_data } from '../components/preview-dialog/preview-dialog-component';
import { feature } from '../features-controller/FeaturesController';
import { M_ProductManager } from '../models/M_ProductManager';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private loadingS: LoadingPanelService) { }

  /**USER LOGIN */
  doLogin(body: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.login.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  profile() {
    return new Promise<M_User>(resolve => {
      this.http.get<any>(endpoints.profile.url).subscribe(
        data => {
          resolve(new M_User(data));
        }
      );
    })
  }

  dashboard() {
    return new Promise<R_Dashboard>(resolve => {
      this.http.get<any>(endpoints.dashboard.url).subscribe(
        data => {
          resolve(new R_Dashboard(data));
        }
      );
    })
  }

  forgotPaswword(email: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.forgotPassword.url, { email: email }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }


  resetPasswod(body: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.resetPassowrd.url, body).subscribe(
        data => {
          resolve(data);
        }
      )
    })
  }

  updatePass(password: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updatePass.url, { password: password }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
  getInvoiceId(invoice_id: number) {
    return new Promise<M_Invoice>(resolve => {
      this.http.post<any>(endpoints.getInvoice.url, { invoice_id: invoice_id }).subscribe(
        data => {
          resolve(new M_Invoice(data));
        }
      );
    })
  }

  updatePassword(formObj: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updatePassword.url, formObj).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  clients() {
    return new Promise<M_Client[]>(resolve => {
      this.http.get<any>(endpoints.clients.url).subscribe(
        data => {
          let data_: M_Client[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Client(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  createClient(body: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createClient.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
  clientsAdvances() {
    return new Promise<M_LastMovements[]>(resolve => {
      this.http.get<any>(endpoints.clientsAdvances.url).subscribe(
        data => {
          let data_: M_LastMovements[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_LastMovements(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }
  vehiclesType() {
    return new Promise<number>(resolve => {
      this.http.get<any>(endpoints.vehicleType.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updateClient(body: M_Client) {
    return new Promise<M_Client>(resolve => {
      this.http.post<any>(endpoints.editClient.url, body).subscribe(
        data => {
          resolve(new M_Client(data));
        }
      );
    })
  }

  /** Cehck */
  getClientById(id: number) {
    return new Promise<M_Client | undefined>(resolve => {
      this.http.post<any>(endpoints.getClientById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Client(data));
          }
          else {
            resolve(undefined)
          }
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }
  getVehicles() {
    return new Promise<M_Vehicle[]>(resolve => {
      this.http.get<any>(endpoints.vehicles.url).subscribe(
        data => {
          let data_: M_Vehicle[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Vehicle(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  getVehicleById(id: number) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.getVehicleById.url, { id: id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Vehicle(data));
          }
          else {
            resolve(undefined)
          }
        }
        ,
        _error => {
          resolve(undefined)
        }
      );
    })
  }

  createVehicle(v: M_Vehicle, c: M_Client) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createClient.url, { v, c }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  createVehicleWithClientId(v: M_Vehicle, c: M_Client) {
    v.client_id = c.client_id;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createVehicleWithClientId.url, v).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  editVehicle(body: M_Vehicle) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editVehicle.url, body).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  products() {
    return new Promise<M_Product[]>(resolve => {
      this.http.get<any>(endpoints.products.url).subscribe(
        data => {
          let data_: M_Product[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_Product(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  createProduct(p: M_Product) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createProduct.url, p).subscribe(
        data => {
          resolve(new M_Product(data));
        }
      );
    })
  }

  editProduct(p: M_Product, action_id?: number, draft_id?: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createProduct.url, {
        product: p,
        action_id: action_id,
        draft_id: draft_id
      }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  getProductById(id: number) {
    return new Promise<M_Product | undefined>(resolve => {
      this.http.post<any>(endpoints.getProductById.url, { "product_id": id }).subscribe(
        data => {
          if (data == "Forbidden") {
            resolve(undefined);
          }
          if (data && Object.keys(data).length != 0) {
            resolve(new M_Product(data));
          }
          else {
            resolve(undefined)
          }
        },
        _error => {
          resolve(undefined)
        }
      );
    })
  }
  postPayedInvoice(invoice_id: number) {
    return new Promise<M_Product>(resolve => {
      this.http.post<any>(endpoints.payedInvoice.url, { "invoice_id": invoice_id }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
  editStock(product: M_Product, stock: number) {

    let v = stock - product.stock;
    let action = stock >= 0 ? "add" : "remove";

    return new Promise<M_Product>(resolve => {

      if (v == 0) { resolve(product) }

      this.http.post<any>(endpoints.editStock.url, { "product_id": product.product_id, "quantity": Math.abs(v), "action": action }).subscribe(
        data => {
          product.stock += v;
          resolve(data);
        }
      );
    })
  }

  company(showLoading: boolean = true) {
    return new Promise<M_Company>(resolve => {
      endpoints.company.showLoading = showLoading;
      this.http.get<any>(endpoints.company.url).subscribe(
        data => {
          resolve(new M_Company(data));
        }
      );
    })
  }


  createCompany(c: M_Register) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createCompany.url, c).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  updateCompany(c: any) {
    return new Promise<M_TemplateField[]>(resolve => {
      this.http.post<any>(endpoints.updateCompany.url, c).subscribe(
        data => {
          resolve(getArrayOf(M_TemplateField, data.template_fields));
        }
      );
    })
  }
  deleteCompany() {
    return new Promise<boolean>(resolve => {
      this.http.get<boolean>(endpoints.deleteCompany.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
  welcomeCompany(companyWelcome: M_CompanyWelcome) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.welcomeCompany.url, companyWelcome).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
emailPromo(email: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.emailPromo.url, email).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }
  users() {
    return new Promise<M_User[]>(resolve => {
      this.http.get<any>(endpoints.users.url).subscribe(
        data => {
          let data_: M_User[] = [];
          for (let i = 0; i < data.length; i++) {
            data_.push(new M_User(data[i]))
          }
          resolve(data_);
        }
      );
    })
  }

  createUser(u: M_User) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createUser.url, u).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  editUser(u: M_User) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateUserName.url, {
        user_id: u.id,
        name: u.name
      }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  clientsForVehicles() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.clientsForVehicles.url).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  globalSearch(searched: string) {
    return new Promise<any>(resolve => {
      if (feature.bigMama) {
        this.http.post<any>(endpoints.globalSearch.url, { text: searched }).subscribe(
          data => { resolve(data); }
        );
      }
      else {
        /** Why clients? To make this async, and don't showt the loading panel */
        this.clients().then(res => resolve(fakeBigMama))
      }
    })
  }

  linkVechicle(vehicle_id: number, client_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.linkVehicle.url, { "vehicle_id": vehicle_id, "client_id": client_id }).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  linkVechicleWithNewClient(vehicle_id: number, c: M_Client) {
    let client = parseOBJ(c);
    client["vehicle_id"] = vehicle_id;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.createClient.url, client).subscribe(
        data => {
          resolve(data);
        }
      );
    })
  }

  bills() {
    return new Promise<M_Invoice[]>(resolve => {
      this.http.get<any>(endpoints.bills.url).subscribe(
        data => {
          let data_: M_Invoice[] = [];
          for (let i = 0; i < data.length; i++) {
            var bill = new M_Invoice(data[i]);
            if (!bill.isDraft) {
              data_.push(bill)
            }
          }
          resolve(data_);
        }
      );
    })
  }

  bill(invoice_id: number) {
    return new Promise<M_Invoice>(resolve => {
      this.http.post<any>(endpoints.bill.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(new M_Invoice(data));
      });
    })
  }

  confirmInvoice(invoice_id: number, total: number, date: CustomDate, expedient : string | undefined) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.confirmInvoice.url,
        {
          invoice_id: invoice_id,
          total: total,
          date: date.dataBaseFormat,
          expedient : expedient
        }).subscribe(data => {
          resolve(data);
        });
    })
  }

  createBill() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.createBill.url).subscribe(
        data => { resolve(data); });
    })
  }

  setPayedStatus(invoice_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.payedBill.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(data);
      }, _error => {
        resolve(undefined)
      });
    })
  }

  extraInvoice(invoice_id: number, comment: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.extraInvoice.url,
        {
          invoice_id: invoice_id,
          comment: comment
        }).subscribe(data => {
          resolve(data);
        });
    })
  }

  addClientBill(invoice_id: number, client_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.addClientBill.url, {
        invoice_id: invoice_id,
        client_id: client_id
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

  addProductBill(invoice_id: number, product: M_Product | M_CustomProduct) {
    return new Promise<any>(resolve => {
      let newObj = { ...product, invoice_id: invoice_id, custom: product instanceof M_Product ? '0' : '1', price: product.price };
      this.http.post<any>(endpoints.addProductBill.url, newObj).subscribe(data => {
        resolve(data);
      });
    })
  }

  updateProdBill(product: M_Product | M_CustomProduct, modifyStock: boolean = true) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateProdBill.url, product.getInvoiceCallObject(modifyStock)).subscribe(data => {
        resolve(data);
      });
    })
  }

  delProductBill(product_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.delProductBill.url, { prod_id: product_id }).subscribe(data => {
        resolve(data);
      });
    })
  }


  discardDraft(invoice_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.discardInvoice.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  deleteImage(id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.deleteImage.url, { image_id: id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  uploadImage(c: CustomFile | File, name: "docu_img" | "vehicle_img" | "dni_front" | "dni_back" | "signature" | "action_image" | "profile",
    ...data: ["client_id" | "vehicle_id" | "action_id", number][]) {
    let form = new FormData();
    let f = c instanceof File ? c : c.file!;
    form.append("image", f)
    form.append("name", name);

    data.forEach(keyVal => {
      form.append(keyVal[0], keyVal[1].toString());
    })

    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.uploadImage.url, form).subscribe(data => {
        resolve(data);
      });
    })
  }

  deleteById(item: M_User | M_Client | M_Vehicle | M_Product) {
    return new Promise<any>(resolve => {
      let data = item instanceof M_User ? { id: item.id!, type: "user" } :
        item instanceof M_Client ? { id: item.client_id!, type: "client" } :
          item instanceof M_Vehicle ? { id: item.vehicle_id!, type: "vehicle" } :
            item instanceof M_Product ? { id: item.product_id!, type: "product" } : undefined;
      if (data != undefined) {
        this.http.post<any>(endpoints.deleteById.url, data).subscribe(data => {
          resolve(data);
        });
      }
    })
  }

  /** ?? */
  downloadInvoices(invoices: M_Invoice[]) {
    return new Promise<Blob | undefined>(resolve => {
      this.http.post(endpoints.downloadInvoices.url, { invoices: invoices }, { observe: 'response', responseType: 'blob' }).subscribe(data => {
        try {
          resolve(data.body ? data.body : undefined);
        }
        catch (e: any) {
          resolve(undefined)
        }
      });
    })
  }

  checkDni(data: string) {
    return new Promise<M_Client | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "dni", data: data }).subscribe(data => {
        if (data.nif) {
          resolve(new M_Client(data));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  checkPlate(plate: string) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "license", data: plate }).subscribe(data => {
        if (data[0]?.license) {
          resolve(new M_Vehicle(data[0]));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  checkChasis(chasis: string) {
    return new Promise<M_Vehicle | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "chasis", data: chasis }).subscribe(data => {
        if (data[0]?.license) {
          resolve(new M_Vehicle(data[0]));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  checkReference(ref: string) {
    return new Promise<M_Product | undefined>(resolve => {
      this.http.post<any>(endpoints.checkExist.url, { type: "product", data: ref }).subscribe(data => {
        if (Array.isArray(data) && data[0]) {
          resolve(new M_Product(data[0]));
        }
        else {
          resolve(undefined);
        }
      });
    })
  }

  saveColors(primary_color: string, secondary_color: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.saveColors.url, {
        primary_color: primary_color,
        secondary_color: secondary_color
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

  /** Resolve --> Non file Fiedlds */
  saveFootersAndTemplateFields(data: T_SaveDocumentation) {
    return new Promise<M_TemplateField[]>(resolve => {
      this.http.post<any>(endpoints.saveFooters.url, data).subscribe(data => {
        let response = getArrayOf(M_TemplateField, data).filter(f => f.add_type != "signature" && f.add_type != "damages");
        resolve(response);
      });
    })
  }

  saveTemplateFields(action_id: number, fields: M_TemplateField[]) {
    return new Promise<M_TemplateField[]>(resolve => {
      this.http.post<any>(endpoints.saveTemplateFields.url, { template_fields: fields, adv_numdoc: action_id }).subscribe(data => {
        resolve(getArrayOf(M_TemplateField, data));
      })
    })
  }


  saveCompanyFiles(fields: M_TemplateField[]) {
    return new Promise<M_TemplateField[]>(resolve => { resolve(this.saveTemplateFiles_(fields, true)) })
  }

  saveTemplateFiles(id: number, fields: M_TemplateField[]) {
    return new Promise<M_TemplateField[]>(resolve => { resolve(this.saveTemplateFiles_(fields, false, id)) })
  }

  /** Resolve --> Only file fields. Same function for general company file save and transaction file save */
  private saveTemplateFiles_(fields: M_TemplateField[], isCompanyGeneral: boolean, action_id?: number) {

    const endpoint = isCompanyGeneral ? endpoints.saveCompanyFiles.url : endpoints.saveTemplateImage.url;
    let finalResponse: M_TemplateField[] = [];
    let templatesWithFiles = fields.filter(f => f.adv_value instanceof File);

    /** If there is no file to upload, resolve empty array immediately */
    if (templatesWithFiles.length === 0) {
      return Promise.resolve([]);
    }

    /** For each file, create a new promise */
    const filePromises = templatesWithFiles.map(templateWithFile => {
      return new Promise<M_TemplateField[]>(resolve => {

        /** Construct the form Data */
        const formData = new FormData();
        formData.append("add_content", templateWithFile.add_content);
        formData.append("add_type", templateWithFile.add_type);
        formData.append("add_contentnext", templateWithFile.add_contentnext);
        formData.append("add_typedoc", templateWithFile.add_typedoc);
        formData.append("design_id", templateWithFile.design_id.toString());
        formData.append("value_id", templateWithFile.value_id ? templateWithFile.value_id.toString() : '');
        if (action_id) {
          formData.append("adv_numdoc", action_id.toString());
        }
        formData.set("adv_value", templateWithFile.adv_value);



        /** Make the api call with the form data */
        this.http.post<any>(endpoint, formData).subscribe(data => {
          resolve(getArrayOf(M_TemplateField, data.template_fields));
        });

      });
    });

    /** Await all the promises, an then, resolve the funcion general promise with array of the all results */
    return Promise.all(filePromises)
      .then(responses => {
        responses.forEach(res => {
          finalResponse.push(...res);
        })
        //Return only the 'signature' or 'damages'
        return finalResponse.filter(f => f.add_type == "signature" || f.add_type == "damages");
      });
  }

  exportInvoices(invoice: any) {
    return new Promise<any>((resolve, reject) => {
      this.http.post(`${endpoints.exportInvoices.url}`, { invoice: invoice }, {
        responseType: 'blob',
      })
        .subscribe((data: Blob) => {
          var downloadName = "FACTURAS - " + new CustomDate().dayMonthYearFormat;
          downloadBlob(data, downloadName);
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }

  brands(type: number) {
    return new Promise<M_Brand[]>(resolve => {
      this.http.post<any>(endpoints.brands.url, { type: type }).subscribe(data => {
        resolve(getArrayOf(M_Brand, data));
      });
    })
  }

  brandModel(id_brand: number) {
    return new Promise<M_Model[]>(resolve => {
      this.http.post<any>(endpoints.brandModels.url, { id_brand: id_brand }).subscribe(data => {
        resolve(getArrayOf(M_Model, data));
      });
    })
  }




  /**VALIDATE EMAIL 
   * Apunta a "/api/auth/validateEmail"
   * Passa el hash de validació que s'ha rebut a través del correu
   * body = {
   * 
   *  hash : "hashdeejemplo"
   * 
   * }
  */
  validateEmail(hash: string, email: string) {
    return new Promise<"error" | "success_new" | "success_change">(resolve => {
      this.http.post<any>(endpoints.validateEmail.url, { hash: hash, email: email }).subscribe(data => {
        resolve(data);
      });
    })
  }

  actionTypes() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.actionTypes.url).subscribe(
        data => { resolve(data); });
    })
  }

  actions(type: action_types) {
    return new Promise<M_Action[]>(resolve => {
      this.http.post<any>(endpoints.actions.url, { type: type.num }).subscribe(data => {
        let action: M_Action[] = [];
        for (let i = 0; i < data.length; i++) {
          if (type.num == action_type_budget.num) {
            data[i]["isBudget"] = true;
          }
          action.push(new M_Action(data[i]));
        }
        resolve(action);
      });
    })
  }

  /*
  |--------------------------------------------------------------------------
    | Create OR
    | input:
    |   client_id
    |   vehicle_id
    |   schedule if null = today
    |   delivey if null = today + 2
    |   km
    |   notes
    |   tasks
    |   assigned_to if null = same user
    |--------------------------------------------------------------------------
    */
  createOR(action: M_ActiveReception) {
    return new Promise<{ "or_id": number, "token": string, "action_id": number }>(resolve => {
      this.http.post<any>(endpoints.createOR.url, action).subscribe(data => {
        if (data) {
          resolve(data);
        }
      });
    })
  }

  addClientVehicleAction(action_id: number, client_id: number, vehicle_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.addClientVehicleAction.url,
        { action_id: action_id, client_id: client_id, vehicle_id: vehicle_id }).subscribe(data => {
          resolve(data);
        });
    })
  }

  getAction(action_id: number) {
    return new Promise<M_Action | undefined>(resolve => {
      this.http.post<any>(endpoints.getAction.url, { action_id: action_id }).subscribe(data => {
        if (data == "Forbidden") {
          resolve(data);
        }
        resolve(new M_Action(data));
      },
        (_error) => {
          resolve(undefined)
        });
    })
  }

  addEditGroup(action_id: number, group_id: number | undefined, title: string, groupType: or_types) {
    return new Promise<any>(resolve => {
      let obj = {};
      if (group_id == undefined) { obj = { action_id: action_id, title: title, type: groupType.num, } }
      else { obj = { action_id: action_id, group_id: group_id, title: title, type: groupType.num } }
      this.http.post<any>(endpoints.addEditGroup.url, obj).subscribe(data => {
        resolve(data);
      }, _error => {
        resolve(-1)
      });
    })
  }


  addTask(group_id: number, product: M_CustomProduct | M_Product, quantity: number, disc: number) {
    return new Promise<any>(resolve => {
      let v = {};
      if (product instanceof M_Product) {
        v = {
          group_id: group_id,
          custom: 0,
          product_id: product.product_id,
          quantity: quantity,
          discount: disc
        }
      }
      else {
        v = {
          group_id: group_id,
          custom: 1,
          name: product.name,
          reference: quantity,
          buy_price: product.discount,
          price: product.price
        }
      }
      this.http.post<any>(endpoints.addTask.url, v).subscribe(data => {
        resolve(data);
      });
    })
  }

  assignAction(action_id: number, user_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.assignAction.url,
        { action_id: action_id, user_id: user_id }).subscribe(data => {
          resolve(data);
        });
    })
  }

  editDelivery(action_id: number, date: CustomDate) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editDelivery.url,
        { action_id: action_id, date: date.dataBaseFormat }).subscribe(data => {
          resolve(data);
        });
    })
  }

  updateSchedule(action_id: number, date: CustomDate) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateSchedule.url,
        { action_id: action_id, date: date.dataBaseFormat }).subscribe(data => {
          resolve(data);
        });
    })
  }

  addEditTasks(t: M_Task[]) {
    console.log("Add edit task")
    return new Promise<number[]>(resolve => {
      this.http.post<any>(endpoints.addEditTasks.url, { tasks: t.map(t => t.parse()) }).subscribe(data => {
        if (data.length != t.length) { }
        else {
          t.forEach((task, index) => {
            task.task_id = data[index];
          })
        }
        resolve(data);
      });
    })
  }

  schedules(from: CustomDate, to: CustomDate, showLoading: boolean) {
    endpoints.schedules.showLoading = showLoading;
    return new Promise<M_Action[]>(resolve => {
      this.http.post<any>(endpoints.schedules.url,
        { start_date: from.dataBaseFormat, end_date: to.dataBaseFormat }).subscribe(data => {
          let action: M_Action[] = [];
          for (let i = 0; i < data.length; i++) {
            action.push(new M_Action(data[i]));
          }
          resolve(action);
        });
    })
  }

  reSendInvoice(token: string) {
    this.loadingS.show();
    var endpointURL = endpoints.invoicePDF.url + token + "/2";
    return new Promise<any>(resolve => {
      this.http.get<any>(endpointURL).subscribe(data => {
        resolve(data);
      });
    })
  }

  reSendBudget(token: string) {
    this.loadingS.show();
    var endpointURL = endpoints.budgetPDF.url + token + "/2";
    return new Promise<any>(resolve => {
      this.http.get<any>(endpointURL).subscribe(data => {
        resolve(data);
      });
    })
  }

  reSendConfirmation(user_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.resendConfirmation.url, { user_id: user_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  pickUpMail(user_id: number, name: string, email: string | undefined, company_id: number, vehicle: any) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.pickUpMail.url, { user_id: user_id, name: name, email: email, company_id: company_id, vehicle: vehicle }).subscribe(data => {
        resolve(data);
      });
    })
  }

  requestPDF(company: M_Company, reference: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.requestPDF.url, { company: company, reference: reference }).subscribe(data => {
        resolve(data);
      });
    })
  }

  sendPresentation() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.presentationMail.url).subscribe(data => {
        resolve(data);
      });
    })
  }

  sendSMTPTestEmail() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.testEmail.url).subscribe(data => {
        resolve(data);
      });
    })
  }

  rmGroup(group_id: number) {
    return new Promise<M_Action[]>(resolve => {
      this.http.post<any>(endpoints.rmGroup.url, { group_id: group_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  rmTask(task_id: number | undefined) {
    if (task_id) {
      return new Promise<M_Action[]>(resolve => {
        this.http.post<any>(endpoints.rmTask.url, { task_id: task_id }).subscribe(data => {
          resolve(data);
        });
      })
    }
    else {
      return true;
    }
  }

  rmAction(action_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.rmAction.url, { action_id: action_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  invoiceOR(group_ids: number[], client: M_Client | undefined, comments: string, last_movement: M_LastMovements[], date: CustomDate, franchise?: number, expedient?: string | null) {
    return new Promise<{ "invoice_id": number, "invoice_name": string, "token": string }[]>(resolve => {
      var obj = {
        group_ids: group_ids,
        user_id: client?.client_id,
        comments: comments,
        last_movement: last_movement,
        date: date.dataBaseFormat,
        franchise: franchise,
        expedient: expedient
      }
      this.http.post<any>(endpoints.invoiceOR.url, obj).subscribe(data => {
        resolve(data);
      });
    })
  }

  sortTask(tasks_ids: number[]) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.sortTask.url, { tasks_ids: tasks_ids }).subscribe(data => {
        resolve(data);
      },
        _error => {
          resolve(undefined)
        });
    })
  }

  changeTask(task_id: number, group_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.changeTask.url, { task_id: task_id, group_id: group_id }).subscribe(data => {
        resolve(data);
      },
        _error => {
          resolve(undefined)
        });
    })
  }

  editKm(action_id: number, km: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editKm.url, { action_id: action_id, km: km }).subscribe(data => {
        resolve(data);
      });
    })
  }

  editNotes(action_id: number, notes: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editNotes.url, { action_id: action_id, notes: notes }).subscribe(data => {
        resolve(data);
      });
    })
  }

  editTitle(action_id: number, title: string) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editTitle.url, { action_id: action_id, title: title }).subscribe(data => {
        resolve(data);
      });
    })
  }

  createBudget(client_id: number, vehicle_id: number, title: string) {
    return new Promise<M_Action>(resolve => {
      this.http.post<any>(endpoints.createBudget.url, { client_id: client_id, vehicle_id: vehicle_id, title: title }).subscribe(data => {
        resolve(new M_Action(data));
      });
    })
  }

  closeBudget(action_id: number) {
    return new Promise<M_Action>(resolve => {
      this.http.post<string>(endpoints.closeBudget.url, { action_id: action_id }).subscribe(data => {
        resolve(new M_Action(data));
      });
    })
  }

  hasDraft() {
    return new Promise<any>(resolve => {
      this.http.get<any>(endpoints.hasDraft.url).subscribe(data => {
        resolve(data == 1 ? true : false);
      });
    })
  }

  closeGroup(group_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.closeGroup.url, { group_id: group_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  openGroup(group_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.openGroup.url, { group_id: group_id }).subscribe(data => {
        resolve(data);
      });
    })
  }

  reorderActions(action_ids: number[], showLoading: boolean = true) {
    endpoints.reorderActions.showLoading = showLoading;
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.reorderActions.url, { action_ids: action_ids }).subscribe(data => {
        resolve(data);
      },
        _error => {
          resolve(-1);
        });
    })
  }

  editGeneralOrInfo(action_id: number, km: number | undefined, assigned_to: number | undefined, schedule: CustomDate, delivery?: CustomDate, notes?: string, fuel?: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editAction.url, {
        action_id: action_id,
        km: km,
        assigned_to: assigned_to ? assigned_to : null,
        schedule: schedule.dataBaseFormat,
        delivery: delivery?.dataBaseFormat,
        notes: notes,
        fuel: fuel
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

  /** Load a a pdf URL of a object with no id */
  loadOnFlyPDFURL(type: templateTypedoc, object: any) {
    return new Promise<Blob | undefined>(resolve => {
      if (object == undefined) { resolve(undefined) }
      var endpoint: string | undefined = "";
      switch (type) {
        case "RA":
          endpoint = endpoints.previewRA.url;
          break;
        default:
          endpoint = undefined;
      }

      if (!endpoint) {
        resolve(undefined);
      }

      if (endpoint) {
        this.http.post(endpoint, object, { responseType: 'blob' }).subscribe(
          (response: Blob) => {
            resolve(response);
          },
          (error) => {
            resolve(undefined);
          }
        );
      }
    })
  }

  /** Load a real pdf file from database */
  loadRealPDFURL(type: templateTypedoc, token: string | undefined) {
    return new Promise<Blob | undefined>(resolve => {
      if (token == undefined) { resolve(undefined) }
      var endpoint: string | undefined = "";
      switch (type) {
        case "I":
          endpoint = endpoints.invoicePDF.url + token + "/1";
          break;
        case "FL":
          endpoint = endpoints.invoicePDF.url + token + "/2";
          break;
        case "A":
          endpoint = endpoints.invoicePDF.url + token + "/1";
          break;
        case "OR":
          endpoint = endpoints.invoicePDF.url + token + "/1";
          break;
        case "RA":
          endpoint = endpoints.raPDF.url + token;
          break;
        case "B":
          endpoint = endpoints.budgetPDF.url + token + "/1";
          break;
        case "AN":
          endpoint = endpoints.createAdvancePdf.url + token + "/1";
          break;
        case "AL":
          endpoint = endpoints.albaranPDF.url + token + "/1";
          break;
        default:
          endpoint = undefined;
      }

      if (!endpoint) {
        resolve(undefined);
      }

      if (endpoint) {
        fetch(endpoint).then(res => {
          try {
            res.blob().then(blob => {
              resolve(blob);
            });
          }
          catch (e: any) {
            resolve(undefined)
          }
        })
      }
    })
  }

  /** Generate in real time the template with the params data 
   * Endpoint  : /api/pdfExample
  */
  generatCompanyTemplate(type: templateTypedoc, company_data: company_data) {
    return new Promise<Blob | undefined>(resolve => {
      this.http.post(endpoints.pdfExample.url, {
        type: type,
        primary: company_data.p.value,
        secondary: company_data.s.value,
        footer: company_data.footer
      }, { responseType: 'blob' }).subscribe(data => {
        try {
          const blob = new Blob([data], { type: 'application/pdf' });
          resolve(blob);
        }
        catch (e: any) {
          resolve(undefined)
        }
      });
    })
  }

  saveSMTP(body: M_Smpt) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.smtp.url, body).subscribe(data => {
        resolve(data);
      });
    })
  }

  checkCompanyInfo() {
    return new Promise<boolean>(resolve => {
      this.http.get<any>(endpoints.checkCompanyInfo.url).subscribe(
        data => {
          resolve(data == 1 ? true : false);
        }
      );
    })
  }

  /** Budget to OR, Or to Budget, OR to OR
   * 
   * OR case : 
   * { "or_id": number, "token": string, "action_id": number }
   * 
   * Budget case : 
   * number
   * 
   */
  transferAction(action_id: number, to: "or" | "budget", data: { client?: M_Client, ra?: M_ActiveReception }) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.copyAction.url, {
        action_id: action_id,
        client: data.client, // Only have value on budgets
        ra: data.ra, // Only have value on OR
        to_budget: to == "budget" ? 1 : 0,
        to_or: to == "or" ? 1 : 0,
      }).subscribe(data => {
        resolve(data);
      });
    })
  }

  getCloudTickets() {
    return new Promise<M_CloudTicket[]>(resolve => {
      this.http.get<any>(endpoints.cloudtickets.url).subscribe(
        res => {
          let data_: M_CloudTicket[] = [];
          for (let i = 0; i < res.length; i++) {
            data_.push(new M_CloudTicket(res[i]))
          }
          resolve(data_);
        })
    })
  }


  createCloudTicket(rp: ReportedProblem) {
    return new Promise<M_CloudTicket>(resolve => {
      this.http.post<any>(endpoints.createTicket.url, rp.multipartformdata).subscribe(data => {
        resolve(new M_CloudTicket(data))
      })
    })
  }

  addComment(id: number, comment: string, cf?: CustomFile) {
    return new Promise<any>(resolve => {
      const formData = new FormData();
      formData.append("id", id.toString());
      formData.append("comment", comment);
      if (cf && cf.file) {
        formData.append('file', cf.file)
      }
      this.http.post<any>(endpoints.addcomment.url, formData).subscribe(_data => {
        resolve(true)
      })
    })
  }

  abonar(invoice_id: number) {
    return new Promise<M_Invoice>(resolve => {
      this.http.post<M_Invoice>(endpoints.abono.url, { invoice_id: invoice_id }).subscribe(data => {
        resolve(data);
      });
    })
  }



  /**
 * Solicita las notificaciones del usuario
 * @returns Todas las notificaciones del usuario
 */
  getNotifications() {
    return new Promise<NotificationInterface[]>(resolve => {
      this.http.post<NotificationInterface[]>(endpoints.getNotifications.url, {}).subscribe(
        data => {
          var notifications: NotificationInterface[] = [];
          for (let i = 0; i < data.length; i++) {
            var ct = new M_CloudTicket(data[i]);
            ct.events.forEach(event => {
              notifications.push(new M_TicketNotificacion(ct, event));
            });
          }
          notifications.sort((a, b) => a.time.value > b.time.value ? -1 : 1);
          resolve(notifications);
        })
    })
  }

  /**
  * Establece una notificación como leída
  * @param id Id de la notificación
  */
  setNotificationReaded(not: NotificationInterface) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.updateNotifications.url, { 'notify_id': not.id, type: not.type }).subscribe(
        data => { resolve(data) })
    })
  }

  /**Marca todas las notificaciones del usuario como leídas */
  readAllNotifications() {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.readAllNotifications.url, {}).subscribe(data => { resolve(data) })
    })
  }



  payCometSubscribe(code?: string) {
    return new Promise<string | undefined>(resolve => {
      this.http.post<{ url: string }>(endpoints.payComet.url, { call: "firstPurchase", code: code }).subscribe(data => {
        if (data.url) {
          resolve(data.url)
        }
        else {
          resolve(undefined);
        }
      })
    })
  }

  getLocationByZip(postalCode: string) {
    return new Promise<R_Zip[] | []>(resolve => {
      this.http.get<any>(endpoints.getLocationByZip.url + postalCode).subscribe(
        data => {
          if (typeof data == "object" && Object.keys(data).length > 0) {
            if (Array.isArray(data) && data.length > 0) {
              // Mapear cada elemento del array a un objeto R_Zip
              const resultArray = data.map(item => new R_Zip(item));
              resolve(resultArray);
            }
          }
          else {
            resolve([])
          }
        },
        error => {
          resolve([])
        })
    })
  }

  getFieldsByCCAA(ccaid: number, general: boolean) {
    /** /CTAddFields_Show/{ccaa}/{general} */
    return new Promise<M_TemplateField[] | undefined>(resolve => {
      this.http.get<any>(endpoints.getFieldsByCCAA.url + ccaid + "/" + (general ? "1" : "2")).subscribe(
        data => {
          resolve(getArrayOf(M_TemplateField, data));
        })
    })
  }

  getFieldsFromDoc(typeDoc: templateTypedoc) {
    return new Promise<M_TemplateField[] | undefined>(resolve => {
      this.http.get<any>(endpoints.getFieldsFromDoc.url + typeDoc).subscribe(
        data => {
          resolve(getArrayOf(M_TemplateField, data));
        })
    })
  }

  checkUserSubscription() {
    return new Promise<M_Subscription>(resolve => {
      this.http.get<{ subscription: M_Subscription }>(endpoints.checkSubscription.url).subscribe(data => {
        resolve(new M_Subscription(data.subscription));
      })
    })
  }

  /** Retrurns the paycomet url to pay for the new users */
  payCometAddUsers(users: number) {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { users: users, call: "addUser" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Remove paycomed payed users */
  payCometRemoveUsers(users: number) {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { users: users, call: "addUser" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Cancel the company subscription */
  cancelPayCometSubscription() {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { call: "cancel" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Cancel the company subscription */
  reActivatePayCometSubscription() {
    return new Promise<M_Subscription | undefined>(resolve => {
      this.http.post<{ subscription: M_Subscription | undefined }>(endpoints.payComet.url, { call: "activate" }).subscribe(
        data => {
          if (data.subscription) {
            resolve(new M_Subscription(data.subscription));
          }
          resolve(undefined);
        },
        error => {
          resolve(undefined)
        })
    })
  }

  /** Get all the PayCometInvoices */
  payCometInvoices() {
    return new Promise<M_PayCometInvoice[]>(resolve => {
      this.http.get<any>(endpoints.payCometInvoices.url).subscribe(data => {
        resolve(getArrayOf(M_PayCometInvoice, data));
      })
    })
  }


  changeStateInvoice(id_invoice: number, state: invoice_states) {
    let name = state.isPartial ? 'partial' : state.isPending ? 'created' : 'payed';
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.invoiceState.url, { 'id_invoice': id_invoice, state: name }).subscribe(
        data => { resolve(data) })
    })


  }

  getPaymentType() {
    return new Promise<M_TypePayment[]>(resolve => {
      this.http.get<any>(endpoints.showTypePayment.url).subscribe(
        data => { resolve(getArrayOf(M_TypePayment, data)) })
    })
  }

  createLastPayment(body: any, advance: M_LastMovements | undefined) {
    body.dynamicDate = new CustomDate(body.dynamicDate).dataBaseFormat;
    return new Promise<M_LastMovements[]>(resolve => {
      this.http.post<any>(endpoints.CreateLastPayment.url, { movement: body, advance: advance }).subscribe(
        data => { resolve(getArrayOf(M_LastMovements, data)) })
    })
  }

  createAdvanceClient(body: any) {
    return new Promise<M_LastMovements>(resolve => {
      this.http.post<any>(endpoints.CreateAdvancesClient.url, body).subscribe(
        data => { resolve(new M_LastMovements(data)) })
    })
  }

  deleteAdvanceClient(id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.DeleteAdvancesClient.url, { id: id }).subscribe(
        data => { resolve(data) })
    })
  }

  EditAdvanceClient(body: M_LastMovements) {
    return new Promise<M_LastMovements>(resolve => {
      this.http.post<any>(endpoints.EditAdvancesClient.url, body).subscribe(
        data => { resolve(new M_LastMovements(data)) })
    })
  }

  deleteLastPayment(last_movement: M_LastMovements) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.DeleteMovementInvoice.url, last_movement).subscribe(
        data => {
          last_movement.deleted = false;
          resolve(data);
        })
    });
  }

  deleteTypePayment(type_payment_id: number) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.DeleteTypePayment.url, { type_payment_id: type_payment_id }).subscribe(
        data => { resolve(data) })
    });
  }

  paymentByCompany(company_id: number) {
    return new Promise<M_PayCometInvoice[]>(resolve => {
      this.http.post(endpoints.paymentByCompany.url, { company_id: company_id }).subscribe(
        data => {
          resolve(getArrayOf(M_PayCometInvoice, data))

        })
    })
  }

  changeORDelivery(action_id: number, delivery: CustomDate) {
    return new Promise<any>(resolve => {
      this.http.post<any>(endpoints.editAction.url, { action_id: action_id, delivery: delivery.dataBaseFormat }).subscribe(
        data => { resolve(data) })
    });
  }

  /** Flotas */
  getClientClosedGroups(client: M_Client) {
    return new Promise<M_Action[]>(resolve => {
      this.http.post<M_Action[]>(endpoints.getActionGroupsClosed.url, { client_id: client.client_id }).subscribe(
        data => {
          let ors: M_Action[] = data.filter(d => d.groups.length != 0);
          resolve(getArrayOf(M_Action, ors))
        })
    });
  }

  invoiceFlotas(client: M_Client) {
    return new Promise<{ invoice_id: number, token: string }>(resolve => {
      this.http.post<any>(endpoints.invoiceOR_ActionGroupsClosed.url, { client_id: client.client_id }).subscribe(
        data => { resolve(data) })
    });
  }

  lastInvoiceDate() {
    return new Promise<CustomDate | undefined>(resolve => {
      this.http.get<any>(endpoints.lastInvoiceDate.url).subscribe(
        data => {
          if (data.date) {
            resolve(new CustomDate(data.date))
          }
          else {
            resolve(undefined)
          }
        }
      )
    });
  }

  downloadEinvoiceXML(token: string) {
    return new Promise<Blob | undefined>(resolve => {
      if (token == undefined) { resolve(undefined) }
      var endpoint = endpoints.eInvoicePDF.url + token;
      if (endpoint) {
        fetch(endpoint).then(res => {
          try {
            res.blob().then(blob => {
              resolve(blob);
            });
          }
          catch (e: any) {
            resolve(undefined)
          }
        })
      }
    })
  }
  createClientAdmincenter(data: any): Promise<any> {
    return new Promise<any>(resolve => {
       this.http.post<any>(endpoints.clientAdmincenters.url, {data}).subscribe(
         response => {
           resolve(response);
         },
      );
    });
  }
  

}
