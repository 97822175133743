import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CalendarService, ClassSearcherComponent, CustomDate, RouterService, SessionService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { EditOrComponent } from 'src/app/views/or-create-edit/edit-or/edit-or.component';
import { endpoints } from '../../api/Enpoints';
import { M_User } from '../../models/M_User';
import { M_Vehicle } from '../../models/M_Vehicle';
import { MASTER_CLIENT, MASTER_USER, MASTER_VECHILE_MINIFY } from '../../constants/masters';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { M_Client } from '../../models/M_Client';
import { ApiService } from '../../api/api.service';
import { M_Company } from '../../models/M_Company';
import { M_Action } from '../../models/M_Action';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-or-form',
  templateUrl: './or-form.component.html',
  styleUrls: ['./or-form.component.css']
})
export class OrFormComponent implements OnInit {

  client = MASTER_CLIENT;
  vehicle_minify = MASTER_VECHILE_MINIFY;
  user = MASTER_USER;
  @Input() isEdit: boolean = false;
  @Input() loaded: boolean = false;
  form!: UntypedFormGroup;
  e = endpoints;
  v = ViewPath;
  @ViewChild("vehicleSearcher") vehicleSearcher?: ClassSearcherComponent<M_Vehicle>;
  @ViewChild("clientInvoice") clientInvoice?: ClassSearcherComponent<M_Client>;
  @ViewChild("workerSearcher") workerSearcher?: ClassSearcherComponent<M_User>;


  /** Workload calendar */
  company: M_Company | undefined;
  workload: M_Action[] = [];
  loadedWorkload = false;
  loadingWorkload = false;
  kmLastReview: string | undefined;


  constructor(private fb: UntypedFormBuilder, public routerS: RouterService, public cs: CalendarService,
    @Optional() private editORComponent: EditOrComponent, private d: MatDialog, private sessionS: SessionService, private apiS: ApiService) {
    this.form = this.fb.group({
      schedule: [undefined],
      delivery: [undefined],
      km: ['', [Validators.required, Validators.max(999999999)]],
      notes: ['', []],
      comments: [[], []],
      assigned_to: [[], []],
      fuel: [1],
    });

    this.form.get('km')?.valueChanges.subscribe(val => {
      this.editORComponent?.updateKm(val);

    })

    this.form.get('delivery')?.valueChanges.subscribe(val => {
      if (val != undefined) {
        let value = new CustomDate(val);
        this.editORComponent?.onDateChange({ value: value }, "delivery");
      }
      else {
        this.editORComponent?.onDateChange(val, "delivery");
      }
    })

    this.form.get('schedule')?.valueChanges.subscribe(val => {
      if (val) {
        let schedule = new CustomDate(val);
        if (this.editORComponent) {
          this.editORComponent.onDateChange({ value: schedule }, "schedule");
        }

        /** Disable deivery if schedule > delivery */
        var d = this.form.get('delivery')?.value!;
        if (d) {
          var shceduledate = new CustomDate(d);
          if (schedule.value > shceduledate.value) {
            this.form.patchValue({ 'delivery': undefined }, { emitEvent: false });
          }
        }
      }
    })
  }

  ngOnInit(): void { 
    if (!this.isEdit){
      this.getWorkload();
    }
  }

  get kmError(){
    return this.form.get('km')?.hasError('max') ? "Máximo 9 números" : ""
  }

  get clientInvoicePlaceholder(): string {
    return this.clientInvoice?.selected?.name || 'Cliente a facturar';
  }

  /** Workload calendar on schedule input */
  getWorkload() {
    if (this.loadedWorkload || this.loadingWorkload) { return; }
    let formValue = this.form.get('schedule')?.value || (!this.isEdit ? new CustomDate() : null);
    if (!formValue) { return };

    const d = new CustomDate(formValue);
    const d1 = new CustomDate(d).minusMonths(1);
    const d2 = new CustomDate(d).plusMonths(1);

    const a = this.apiS.schedules(d1, d2, false);
    const b = this.apiS.company(false);
    this.loadedWorkload = true;
    

    forkJoin([a, b]).subscribe(res => {
      this.workload = res[0];
      this.company = res[1];
      this.loadedWorkload = true;
      this.loadingWorkload = false;
    })
  }

  getDaysClass(d: Date) {
    if (!this.loadedWorkload || !this.company || this.loadingWorkload) { return ""; }
    const date = new CustomDate(d);
    const toalOrOnThisDay = this.workload.filter(or => {
      or.schedule.isEquals(date)
      return or.schedule?.isEquals(date) && !or.status.invoiced
    }).length;
    return this.cs.getDayColorByOrLength(toalOrOnThisDay, this.company.getPlaces());
  }

  get minValueDelivery() {
    return new CustomDate(this.form.get('schedule')?.value).value;
  }

  fillFormWithVehicle(n: number) {
    if (!this.isEdit && this.vehicleSearcher) {
      this.vehicleSearcher.setMasterById(n);
    }
  }

  assignMyself() {
    if (!this.isEdit) {
      this.workerSearcher?.allData.forEach(d => {
        if (this.sessionS.isMe(d.id)) {
          this.workerSearcher?.setMasterObject(d);
        }
      })
    }
  }

  assignUser(u: M_User | undefined) {
    if (this.editORComponent && this.editORComponent.action?.id) {
      this.editORComponent.action.assigned_to = u != undefined ? u.id : undefined;
    }

  }

  fuelChange(event: MatSelectChange) {
    if (this.editORComponent && this.editORComponent.action?.id) {
      this.form.patchValue({ fuel: event.value });
      this.editORComponent.action.fuel = event.value;
    }
  }
}
