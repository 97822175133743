import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomFile, FileUploadComponent, ParameterStateComponent, RouterService, getPrimaryColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { getColorByVehicle } from 'src/app/core/custom-classes/Colors';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.css']
})
export class VehicleDetailsComponent extends ParameterStateComponent {

  primary = getPrimaryColor;
  getColorByVehicle = getColorByVehicle;
  loaded: boolean = false;
  env = environment;
  ve: M_Vehicle | undefined;
  v = ViewPath;
  @ViewChild('documentation') documentation!: FileUploadComponent;
  @ViewChild('vehicleImages') vehicleImages!: FileUploadComponent;
  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService,
    public params: ParamsService, private chdRef: ChangeDetectorRef,
    public subS: SubscriptionService) {
    super(routerS, route, ["vehicle"])
  }

  override onState(state: any) {
    if (state instanceof M_Vehicle) {
      this.ve = state;
      this.chdRef.detectChanges();
      this.loaded = true;
    }
  }

  override onParam(_k: string, v: any) {
    this.apiS.getVehicleById(v).then(res => {
      if(res == undefined){
        this.ve  = undefined;
      }
      if (res instanceof M_Vehicle) {
        this.ve = res;
        this.fillImages(this.ve);
      }
      this.loaded = true;
      this.chdRef.detectChanges();
    })
  }

  onImgError(e: any) {
    e.srcElement.style.display = 'none'
  }

  fillImages(v: M_Vehicle) {
    this.documentation.setImagesFromDatabase(v.documentation);
    this.vehicleImages.setImagesFromDatabase(v.vehicleImages);
  }

  removeImage(c: CustomFile) {
    if (c.database_id) {
      this.apiS.deleteImage(c.database_id);
    }
  }

  uploadImages(c: CustomFile, key: "vehicle_img" | "docu_img") {
    this.apiS.uploadImage(c, key, ["client_id", this.ve!.client!.client_id!], ["vehicle_id", this.ve!.vehicle_id]).then(res => {
      if (res.image_id) {
        c.database_id = res.image_id;
      }
    })
  }

  hasDocuImages() {
    return this.documentation?.hasFiles();
  }

  hasVehicleImages() {
    return this.vehicleImages?.hasFiles();
  }

}