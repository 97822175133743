import { Component, Inject, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDate, ORInterface, SnackService } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';

@Component({
  selector: 'app-change-delivery-or-dialog',
  templateUrl: './change-delivery-or-dialog.component.html',
  styleUrls: ['./change-delivery-or-dialog.component.css']
})
export class ChangeDeliveryOrDialogComponent {

  selected: Date | null

  @ViewChild(MatCalendar, { static: false }) calendar!: MatCalendar<Date>;


  constructor(public dialogRef: MatDialogRef<ChangeDeliveryOrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ORInterface, private apiS: ApiService, private snackS: SnackService) {
    /** If not delivery date, close the dialog */
    if (!this.data.delivery) { this.dialogRef.close(); }
    this.selected = this.data.delivery!.value;
  }

  dateClass = (d: Date) => {
    if (!this.data.delivery || !this.data.schedule) { return ''; }
    const date = new CustomDate(d);
    return date.isEquals(this.data.schedule) ? 'calendar_schedule' : '';
  }

  maxScheduleAndSunday: (date: Date | null) => boolean =
  (date: Date | null) => {
    if (!date) {
      return false;
    }
    const day = date.getDay();
    const cd : CustomDate = new CustomDate(this.data.schedule);
    const param = new CustomDate(date);
    return (param.value >= cd.value || param.isEquals(cd)) && day != 0;
  };

  changeOrDelivaery() {
    let newDate = new CustomDate(this.selected!);
    this.apiS.changeORDelivery(this.data.id, newDate).then(res => {
      this.snackS.show("Fecha de entrega modificada")
      this.data.delivery = newDate;
      this.dialogRef.close();
    })
  }

  get datesStatus() : "equal" | "less" | "bigger" {
    return this.equalDates ? "equal" : this.deliveryLess ? "less" : 'bigger'
  }
  
  get equalDates() {
    if (!this.selected || !this.data.schedule) { return false; }
    return new CustomDate(this.selected).isEquals(this.data.schedule)
  }
  get deliveryLess() {
    if (!this.selected || !this.data.schedule || this.equalDates) { return false; }
    return new CustomDate(this.selected).value < this.data.schedule.value
  }
}
