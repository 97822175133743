import { Injectable } from '@angular/core';
import { RouterService, Views } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  constructor(private routerS : RouterService) {}

  go(v : Views, param : string | number, param_name? : string, newTab? : boolean) {
    let paramName =  param_name ? param_name : this.getStateNameByView(v);
    let p = {
      [paramName] : param
    }
    //if (state) {
      //this.routerS.goWithStateAndQueryParams(v, state, p)
    //}
    //else{
      this.routerS.goWithQueryParams(v, p, newTab ? newTab : false);
    //}
  }

  getStateNameByView(v : Views) {
    let paramName = "";
    switch(v){
      case ViewPath.clientes:
      case ViewPath.createClient:
      case ViewPath.clientDetails:
        paramName = "client";
      break;
      case ViewPath.vehiculos:
      case ViewPath.createvehicle:
      case ViewPath.vehicleDetails:
      case ViewPath.linkvehicle:
        paramName = "vehicle";
      break;
      case ViewPath.products:
      case ViewPath.productDetails:
        case ViewPath.createProduct:
        paramName = "product";
      break;
      case ViewPath.facturas:
        paramName = "bill"
        break;
      case ViewPath.editOr:
        paramName = "or";
      break;
      case ViewPath.createEditBudget:
        paramName = "budget";
      break;
    }

    return paramName;
  }
}
