import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTicketDialog, M_TicketNotificacion, NotificationInterface, OnboardingComponent, RouterService, SessionService } from '@sinigual/angular-lib';
import { forkJoin } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { UserServiceService } from 'src/app/views/profile/user-service.service';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../api/api.service';
import { M_User } from '../../models/M_User';
import { MenuComponent } from '../menu/menu.component';
import * as LogRocket from 'logrocket';
import { SubscriptionService } from '../../services/subscription.service';
import { feature } from '../../features-controller/FeaturesController';
import { DicountMonthlyComponent } from './dicount-monthly/dicount-monthly.component';
import { M_Company } from '../../models/M_Company';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
}
)
export class ToolbarComponent implements OnInit {
  f = feature;
  v = ViewPath;
  u: M_User | undefined;
  company : M_Company | undefined;
  q: number = 0;
  @Input() mc!: MenuComponent;
  @Input() onboarding!: OnboardingComponent;
  @Output() onclick: EventEmitter<any> = new EventEmitter<any>();
  constructor(public subS: SubscriptionService, public routerS: RouterService, private sessionS: SessionService, public apiS: ApiService, private userS: UserServiceService, private d: MatDialog,private GA : GoogleAnalyticsService) { }
  ngOnInit(): void {
    this.subS.refreshUserSubscription();
    const a = this.apiS.profile();
    const b = this.apiS.checkCompanyInfo();
    forkJoin([a, b]).subscribe(res => {
      //Setting user
      this.u = res[0];
      if (feature.logRocket) {
        LogRocket.identify(this.u.id!.toString(), {
          name: this.u.name,
          email: this.u.email,
        });
      }
      this.sessionS.setId(this.u.id!);
      //Setting if the user is missing company info
      this.userS.setMissingCompany(!res[1]);
    })
  }
  logOut() {
    this.sessionS.logOut();
  }

  goProfile() {
    this.routerS.goTo(this.v.profile);
  }
  openDiscount(){
   
    if(this.u){
      this.GA.event('1_mes_gratis_clic', "mes_gratis", (this.u.name + " ha hecho clic en mes gratis."));
      this.d.open(DicountMonthlyComponent,{width:"700px",data:{
        title:"¡Aprovecha esta oferta exclusiva!",
        body:"Comparte el siguiente código promocional con otra empresa y obtengan un mes gratis los dos.",
        value:this.u,
        bottom:"No pierdas esta oportunidad y ayuda a otra empresa a descubrir nuestra excelente plataforma.",
        extra:"¡Ganar es fácil para ambos!"
      }});
    }
    
  }
  openIssueDialog() {
    this.d.open(CreateTicketDialog, { data: { view: ViewPath.issues, showGoIssues : true }, autoFocus: false });
  }

  notificationAction(not: NotificationInterface) {
    if (not instanceof M_TicketNotificacion) {
      this.routerS.goWithQueryParams(ViewPath.issues, { ct_id: not.ct.id, ct_proj: not.ct.project });
    }
  }

}
