import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService, CalendarService, CustomDate, FileUploadComponent, CustomFile, or_status_invoiced, ConfirmDialogService, ResponsiveService, getPrimaryColor } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { endpoints } from 'src/app/core/api/Enpoints';
import { BeforeDeleteGroupComponent } from 'src/app/core/components/drag/before-delete-group/before-delete-group.component';
import { OrFormComponent } from 'src/app/core/components/or-form/or-form.component';
import { M_Action } from 'src/app/core/models/M_Action';
import { M_GroupTask } from 'src/app/core/models/M_GroupTask';
import { M_User } from 'src/app/core/models/M_User';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { ActionCreateEditParentComponent } from '../or-create-edit-parent/or-create-edit-parent.component';
import { InvoiceDialogComponent } from './invoice-dialog/invoice-dialog.component';
import { PreviewService } from 'src/app/core/services/preview.service';
import { ViewPath } from 'src/app/app-routing.module';
import { ExitSaveChanges } from 'src/app/core/interfaces/ExitSaveChanges';
import { DragComponent } from 'src/app/core/components/drag/drag.component';
import { TrasnferActionComponent, transferData } from 'src/app/core/components/trasnfer-action/trasnfer-action.component';
import { OrNotesAndImagesComponent } from '../or-notes-and-images/or-notes-and-images.component';

@Component({
  selector: 'app-edit-or',
  templateUrl: './edit-or.component.html',
  styleUrls: ['./edit-or.component.css', '../create-edit-or-styeles.css']
})
export class EditOrComponent extends ActionCreateEditParentComponent implements OnInit, ExitSaveChanges {
  primary = getPrimaryColor;
  @ViewChild(OrFormComponent, { static: false }) formComponent?: OrFormComponent;
  @ViewChild(OrNotesAndImagesComponent) notesAndImages!: OrNotesAndImagesComponent;
  @ViewChild(DragComponent) dragComponent?: DragComponent;
  e = endpoints;
  firmaEdited = false;
  allInvoiced = false;
  unsavedChanges = false;


  /** Dont modify */
  forceExit = false;

  constructor(apiS: ApiService, routerS: RouterService, route: ActivatedRoute, public chdRef: ChangeDetectorRef, public responsiveS: ResponsiveService,
    public cs: CalendarService, d: MatDialog, public router: Router, private confirmDialogS: ConfirmDialogService, public subS: SubscriptionService, 
    public previewS: PreviewService) {
    super(apiS, routerS, route, ["or"], d, confirmDialogS);


  }


  ngOnInit(): void { }

  hasSomeInterno(){
    return this.dragComponent?.action?.groups.some(g => g.type.cargo);
  }

  saveChangesBeforeExit(): void {
    this.saveScreen(this.dragComponent);
  }

  showSaveExitDialog() {

    if (!this.subS.hasProPermissions) {
      return false;
    }

    let somechanges = false;
    let dragHasChanges = false; //Children group chanes
    let editOrFormHasChanges = this.unsavedChanges; //This form changes
    if (this.dragComponent) {
      dragHasChanges = this.dragComponent.hasChanges();
    }
    somechanges = dragHasChanges || editOrFormHasChanges;
    return !this.action ? false : somechanges && !this.action.deleted;
  }

  override fillViewWithAction(a: M_Action) {
    if (this.formComponent) {
      this.formComponent.form.patchValue(a, { emitEvent: false });
      if (a.assigned_to) { this.formComponent.workerSearcher?.setMasterById(a.assigned_to); }
      if (a.schedule) { this.formComponent.form.patchValue({ "schedule": a.schedule.datePickerFormat }, { emitEvent: false }); }
      if (a.delivery) { this.formComponent.form.patchValue({ "delivery": a.delivery.datePickerFormat }, { emitEvent: false }); }
      if (a.hasImages()) { this.notesAndImages.fileupload.setImagesFromDatabase(a.images) }
      this.chdRef.detectChanges();

      /** Unsaved changes on general info */
      this.formComponent.form.valueChanges.subscribe(v => {
        if (!a.allInvoiced()) {
          this.unsavedChanges = true;
        }
      })

      this.formComponent.workerSearcher?.onSelect.subscribe(v => {
        if (!a.allInvoiced()) {
          this.unsavedChanges = true;
        }
      })
      // Load calendar
      this.formComponent.getWorkload();
      this.notesAndImages.noteCompoenent.setValue(a.notes ? a.notes : "");
    }

    if (a.allInvoiced()) {
      this.disableScreen();
    }

  }

  get minValueDelivery() {
    return new CustomDate(this.formComponent?.form.get('schedule')?.value).value;
  }

  removeImage(cf: CustomFile) {
    if (cf.database_id) {
      this.apiS.deleteImage(cf.database_id);
    }
  }

  onDateChange(e: any, date_type: "schedule" | "delivery") {
    if (this.action) {
      if (date_type == "delivery") {
        if (e == undefined) {
          this.action.delivery = e;
        }
        else {
          let target = new CustomDate(e.value);
          this.action.delivery = target;
        }
      }
      else {
        if (e == undefined) {
          this.action.schedule = e;
        }
        else {
          let target = new CustomDate(e.value);
          this.action.schedule = target;
        }
      }
    }
  }

  onWokrerChange(w: M_User) {
    if (this.action) {
      this.action!.assigned_to = w.id;
    }
  }

  editGeneralInfo() {
    if (this.action) {
      this.action.notes
      this.apiS.editGeneralOrInfo(
        this.action.id,
        this.action?.km,
        this.action.assigned_to,
        this.action.schedule,
        this.action.delivery,
        this.formComponent?.form.get('notes')?.value,
        this.action.fuel).then(res => {
          this.unsavedChanges = false;
        })
    }
  }

  trasnferTo(d: DragComponent, to: "or" | "budget") {
    if (!this.action?.isDone()) {
      d.saveAll().then(res => {
        this.openTransferTo(to);
      })
    }
    else {
      this.openTransferTo(to);
    }
  }

  openTransferTo(to: "or" | "budget") {
    this.d.open<TrasnferActionComponent, transferData, boolean>
      (TrasnferActionComponent,
        {
          autoFocus: false,
          disableClose: true,
          data: {
            action: this.action!,
            from: "or",
            to: to
          }
        })
  }

  openInvoiceDialog(preSelected?: M_GroupTask) {

    let invoiceDefault = this.action!.vehicle?.clientInvoice?.client_id || this.action!.client?.client_id;
    let sameClient = invoiceDefault == this.action!.client?.client_id;

    this.d.open(InvoiceDialogComponent,
      {
        data: {
          groups: this.action?.groups.filter(group => group.isClosed()),
          preSelected: preSelected,
          action: this.action,
        }, autoFocus: false
      })
      .afterClosed().subscribe(res => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            var gt = res[i];
            if (gt instanceof M_GroupTask) {
              gt.changeStatus(or_status_invoiced);
              this.chdRef.detectChanges();
            }
          }
          this.allInvoicedDialog();
        }

      })
  }

  openDialog(g?: M_GroupTask) {
    this.openInvoiceDialog(g);
  }

  /** If the rest of groups are invoiced, show an alert */
  checkOthersInvoiced(g: M_GroupTask) {
    this.confirmDialogS.show({
      title: "Eliminar intervención",
      body: "¿Está seguro de que quiere eliminar la intervención?",
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        if (this.isTheLastGroupNoInvoiced()) {
          this.d.open(BeforeDeleteGroupComponent).afterClosed().subscribe(res => {
            if (res == true) {
              this.removeGroup(g).then(res => {
                this.allInvoicedDialog();
              });
            }
          })
        }
        else {
          this.removeGroup(g);
        }
      }
    })
  }

  isTheLastGroupNoInvoiced() {
    if (this.action) {
      return this.action.groups.length - this.action.groups.filter(g => g.isInvoiced()).length == 1 && this.action.groups.length != 1;
    }
    return false;
  }

  removeGroup(g: M_GroupTask) {
    return this.apiS.rmGroup(g.id).then(_res => {
      this.action?.groups?.removeElement(g);
      this.action?.refreshStatus();
      this.action?.refreshType();
    })
  }

  allInvoicedDialog() {
    if (this.action?.allInvoiced()) {
      this.action.refreshStatus();
      this.disableScreen();
      this.saveScreen();
      this.confirmDialogS.show({
        title: "¡Orden de reparación finalizada!",
        body: "Has facturado todos las intervenciones de la orden de reparación",
        confirmTxt: "OK",
        cancelText: "Ir listado OR"
      }).afterClosed().subscribe(res => {
        if (res == false) {
          this.routerS.goTo(this.v.or)
        }
      })
    }
  }

  disableScreen() {
    this.allInvoiced = true;
    this.formComponent?.form.disable({ emitEvent: false });
    if (this.formComponent?.workerSearcher) {
      this.formComponent.workerSearcher.canRemove = false;
      this.formComponent.workerSearcher.setDisabled();
    }
  }

  saveScreen(drag?: DragComponent) {

    if (this.unsavedChanges) {
      this.editGeneralInfo();
    }

    if (drag && drag.hasChanges()) {
      drag.saveAll()
    }

  }

  updateTitle(v: string) {
    if (v != this.action!.title) {
      this.apiS.editTitle(this.action!.id, v).then(_res => {
        this.action!.title = v;
      });
    }
  }

  updateKm(v: string) {
    if (this.action && Number(v) != this.action!.km) {
      this.action!.km = Number(v);
    }
  }

  goOrs() {
    this.routerS.goTo(ViewPath.or);
  }

}
