import { Component, Input } from '@angular/core';
import { M_Client } from '../../models/M_Client';
import { ClassSearcherComponent } from '@sinigual/angular-lib';

@Component({
  selector: 'app-client-searcher-header',
  templateUrl: './client-searcher-header.component.html',
  styleUrls: ['./client-searcher-header.component.css']
})
export class ClientSearcherHeaderComponent {
  @Input() classSearcher!: ClassSearcherComponent<M_Client>;
  @Input() displayDiscount:boolean= true;
}
