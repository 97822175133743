import { Component, Inject, Input, OnInit } from '@angular/core';
import { M_Vehicle } from '../../models/M_Vehicle';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vehicle-row-container',
  templateUrl: './vehicle-row-container.component.html',
  styleUrls: ['./vehicle-row-container.component.css']
})
export class VehicleRowContainerComponent implements OnInit {

  /* Show the button if ther is more than 3 vehicles. If there is 3 vehicles, the button doesn't appear */
  maxVehicles = 3;
  isDialog: boolean = false;
  @Input() vehicles: M_Vehicle[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: M_Vehicle[], private d: MatDialog, public dRef: MatDialogRef<VehicleRowContainerComponent>) {
    if (Array.isArray(data)) {
      this.vehicles = data;
      this.isDialog = true;
    }
  }

  ngOnInit(): void { }

  get showVehicles() {
    if (!this.isDialog) {
      return this.vehicles.slice(0, this.maxVehicles).filter(vehicle => vehicle !== undefined);
    }
    else {
      return this.vehicles;
    }
  }

  get showMore() {
    return !this.isDialog && this.vehicles.length > this.maxVehicles;
  }

  openDialog() {
    this.d.open(VehicleRowContainerComponent, { data: this.vehicles, autoFocus: false })
  }

  closeDialog() {
    this.dRef.close();
  }
  

}
