import { getPrimaryColor } from "@sinigual/angular-lib";
import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexStroke, ApexDataLabels, ApexMarkers, ApexYAxis, ApexGrid, ApexLegend, ApexTitleSubtitle, ApexFill } from "ng-apexcharts";
import { R_Dashboard } from "src/app/core/api/responses/R_Dashboard";

export type PrevisionChartOtions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  fill: ApexFill;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

export function chartGenerationPrevision(d: R_Dashboard): Partial<PrevisionChartOtions> {
  const previsionChart: Partial<PrevisionChartOtions> = {
    series: [
      {
        name: "OR",
        data: d.previsionDays.map(d => d.ors)

      },
      /*{
        name: "Citas",
        data: [12, 11, 14, 18, 17, 13, 13]
      }*/
    ],
    chart: {
      zoom: {
        enabled: false,
      },
      fontFamily: 'Inter',
      height: 300,
      type: "area",
      dropShadow: {
        enabled: false,
        color: "var(--r2)",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2
      },
      toolbar: {
        show: false
      }
    },
    colors: ["var(--r)", "var(--r)"],
    fill: {
      opacity: [0.85, 0.25, 1],
      colors: ["#ffa9ad"] //r2
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: "straight"
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: d.previsionDays.map(d => d.day.numMonthFormat),
      labels: {
        style: {
          colors: d.previsionDays.map(d => d.day.value.getDay() == 0 ? "var(--t2)"  : ""),
        }
      }
    },
    yaxis: {
      /*title: {
        text: "Cantidad",
      },*/
      axisTicks: {
        show: false
      },
      tickAmount: 5,
      min: 0,
      max: round5(Math.max(...d.previsionDays.map(p => p.ors), 5))
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  };

  return previsionChart;
}

/** Round up to the next multiple of 5 */
function round5(x: number) {
  return Math.ceil(x / 5) * 5;
}