<div *ngFor="let a of movements; let last = last" [class]="'activity activity-0 onboarding-invoice-payment-finish-result' ">
    <div class="df">
        <div class="df fdc aic">
            <div class="circle ">
                <mat-icon *ngIf="a.type_payment.isBizum" [svgIcon]="'bizum'" class="c_w bizum_white"></mat-icon>
                <mat-icon *ngIf="!a.type_payment.isBizum" class="c_w">{{a.type_payment.icon}}</mat-icon>
            </div>
            <div class="circle-line" [ngClass]="{'last':isTotalPay && last}"></div>
        </div>
        <div class="text-section">
            <p class="title">
                <span class="fw500" money [val]="a.import"></span>
                <span class="c_t2"> | </span>
                <span class="c_t2 fss">{{a.type_payment.payment}}
                    <mat-icon *ngIf="canDelete" class="vam delete_style" (click)="onDeleteMovement.emit(a)">delete</mat-icon>
                </span>
            </p>
            <p class="subtitle">
                <span class="c_p fss" *ngIf="a.isAdvance">Anticipo</span>
                <span  class="c_g2 fss"  *ngIf="a.isAdvance"> | </span>
                <span class="c_g2 fss">{{a.created_at.dayMonthYearFormat}}</span>
            </p>
        </div>
    </div>
    <div class="df" *ngIf="last && showLastCircle">
        <div class="df fdc aic div-last">
            <div class="circle-last"></div>
            <div class="circle-line" [ngClass]="{'last': last}"></div>
        </div>
    </div>
</div>