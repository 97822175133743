import { Country } from "@angular-material-extensions/select-country";

export const max_buy_price = 999999;
export const max_price = 999999;
export const min_price = -999999;
export const max_facturable_product = 999999999999;

export const HARDCODED_TAX = 21;
export const CODE_PROMOTIONAL = "30GRATIS";

export const COUNTRY_ESP: Country = {
    alpha2Code: "ES",
    alpha3Code: "ESP",
    callingCode: "+34",
    name: "España",
    numericCode: "724"
}


/** https://www.sigaus.es/factura-del-taller */
export const SIGAUS_PRICE_LITER = 0.05


/** https://www.signus.es/tarifa-ecovalor/ */
export interface Signus {
    id: number,
    price: number
    name: string
}

export const SIGNUS_N1: Signus = { id: 1, name: "N1", price: 0.73 };
export const SIGNUS_N2: Signus = { id: 2, name: "N2", price: 1.64 };
export const SIGNUS_N3: Signus = { id: 3, name: "N3", price: 5.88 };
export const SIGNUS_N4: Signus = { id: 4, name: "N4", price: 11.76 };
export const SIGNUS_N5: Signus = { id: 5, name: "N5", price: 14.33 };
export const SIGNUS_N6: Signus = { id: 6, name: "N6", price: 19.66 };
export const SIGNUS_N7: Signus = { id: 7, name: "N7", price: 23.88 };
export const SIGNUS_N8: Signus = { id: 8, name: "N8", price: 52.94 };

export const SIGNUS_S1: Signus = { id: 9, name: "S1", price: 12.59 };
export const SIGNUS_S2: Signus = { id: 10, name: "S2", price: 25.33 };
export const SIGNUS_S3: Signus = { id: 11, name: "S3", price: 37.82 };
export const SIGNUS_S4: Signus = { id: 12, name: "S4", price: 51.92 };
export const SIGNUS_S5: Signus = { id: 13, name: "S5", price: 66.82 };
export const SIGNUS_S6: Signus = { id: 14, name: "S6", price: 81.96 };
export const SIGNUS_S7: Signus = { id: 15, name: "S7", price: 101.82 };
export const SIGNUS_S8: Signus = { id: 16, name: "S8", price: 124.81 };
export const SIGNUS_S9: Signus = { id: 17, name: "S9", price: 153.06 };
export const SIGNUS_S10: Signus = { id: 18, name: "S10", price: 186.37 };
export const SIGNUS_S11: Signus = { id: 19, name: "S11", price: 233.67 };
export const SIGNUS_S12: Signus = { id: 20, name: "S12", price: 513.88 };
export const SIGNUS_S13: Signus = { id: 21, name: "S13", price: 1136.80 };
export const SIGNUS_S14: Signus = { id: 22, name: "S14", price: 2026.04 };

export const SIGNUS_ARRAY = [

    SIGNUS_N1,
    SIGNUS_N2,
    SIGNUS_N3,
    SIGNUS_N4,
    SIGNUS_N5,
    SIGNUS_N6,
    SIGNUS_N7,
    SIGNUS_N8,

    SIGNUS_S1,
    SIGNUS_S2,
    SIGNUS_S3,
    SIGNUS_S4,
    SIGNUS_S5,
    SIGNUS_S6,
    SIGNUS_S7,
    SIGNUS_S8,
    SIGNUS_S9,
    SIGNUS_S10,
    SIGNUS_S11,
    SIGNUS_S12,
    SIGNUS_S13,
    SIGNUS_S14,
]

export function getSignusById(id: number): Signus {
    if (id > 0 && id <= SIGNUS_ARRAY.length-1){
        return SIGNUS_ARRAY[id - 1];
    }
    return SIGNUS_N1;
}