import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MemoryParamsService, ParameterStateComponent, RouterService, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { DocumentationConfigComponent } from './documentation-config/documentation-config.component';
import { CompanyGeneralInfoComponent } from './company-general-info/company-general-info.component';
import { SmtpComponent } from './smtp/smtp.component';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/core/api/api.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent extends ParameterStateComponent implements OnInit {
  primary = getPrimaryColor;
  warn = getWarnColor;
  loaded = false;
  selectedTab = 0;
  @ViewChild(CompanyGeneralInfoComponent) generalInfoTab!: CompanyGeneralInfoComponent;
  @ViewChild(DocumentationConfigComponent) documentationTab!: DocumentationConfigComponent;
  @ViewChild(SmtpComponent) smtpTab!: SmtpComponent;

  constructor(routerS: RouterService, route: ActivatedRoute, private mps: MemoryParamsService, private apiS: ApiService) {
    super(routerS, route, ['tab']);
  }

  /** If the 'tab' param exist, select corresponding tab  */
  override onParam(param: string, value: string): void {
    if (param == 'tab' && value.getNumber()) {
      this.selectedTab = value.getNumber();
    }
  }

  /** Initialzie all tabs */
  ngOnInit(): void {
    this.apiS.company().then(res => {
      //GENERAL TAB
      this.generalInfoTab.initTab(res);     
      //DOCUMENTATION TAB
      this.documentationTab.initTab({
        primary : res.primary_color,
        secondary : res.secondary_color,
        templates : res.template_fields,
        footers : res.getAllFootersByOrder()
      })
      //SMTP TAB
      this.smtpTab.initTab(res.smtp);
      this.loaded = true;

      //(The users tab initializes itself)

    })
  }

  /** Necessary to update the selected tab */
  tabChanged(event: MatTabChangeEvent) {
    this.selectedTab = event.index;
    this.mps.add(['tab', event.index.toString()]);
    if (event.index == 2) {
      this.documentationTab.tabGroup.realignInkBar();
    }
  }
}
