import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouterService, SnackService } from '@sinigual/angular-lib';
import { ApiService } from '../../api/api.service';
import { M_Client } from '../../models/M_Client';
import { M_Product } from '../../models/M_Product';
import { M_User } from '../../models/M_User';
import { M_Vehicle } from '../../models/M_Vehicle';
import { M_Invoice } from '../../models/M_Invoice';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  title = "Eliminar"
  subtitle = "¿Seguro que quiere eliminar "
  subtitleBold = ""
  isQuestion = true;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public routerS: RouterService,
    private apiS: ApiService, private snackS: SnackService) {
  }

  ngOnInit(): void {
    let o = this.data;
    if (o == undefined) {
      throw Error("Undefined data on DeleteDialogComponent")
    }
    else {
      if (o instanceof M_Client) {
        this.subtitle += "al cliente "
        this.subtitleBold = o.getName();
      }
      else if (o instanceof M_Vehicle) {
        this.subtitle += "el vehículo ";
        this.subtitleBold = o.getName();
      }
      else if (o instanceof M_Product) {
        this.subtitle += "el producto ";
        this.subtitleBold = o.name;
      }
      else if (o instanceof M_User) {
        this.subtitle += "al usuario ";
        this.subtitleBold = o.name;
      }
      else if (o instanceof M_Invoice) {
        this.title = "Marcar como pendiente"
        this.subtitle = "Se eliminarán los cobros efectuados."
        this.isQuestion = false;
      }
    }
  }

  show(data: any) {
    return this.dialog.open(DeleteDialogComponent, { data, autoFocus: false, panelClass: "core-error-dialog" });
  }

  deleteObj() {
    let o = this.data;
    if (o instanceof M_Client) {
      this.apiS.deleteById(o).then(_res => {
        this.dialogRef.close(true);
        this.snackS.show("Cliente eliminado con éxito")
      })
    }
    else if (o instanceof M_Vehicle) {
      this.apiS.deleteById(o).then(_res => {
        this.dialogRef.close(true);
        this.snackS.show("Vehículo eliminado con éxito")
      })
    }
    else if (o instanceof M_Product) {
      this.apiS.deleteById(o).then(_res => {
        this.dialogRef.close(true);
        this.snackS.show("Producto eliminado con éxito")
      })
    }
    else if (o instanceof M_User) {
      this.apiS.deleteById(o).then(_res => {
        this.dialogRef.close(true);
        this.snackS.show("Usuario eliminado con éxito")
      })
    }
    else if (o instanceof M_Invoice) {
      this.dialogRef.close(true);
    }
  }

}
