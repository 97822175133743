import { CustomDate, I_ClassSearcher, match } from "@sinigual/angular-lib"

export class M_Model implements I_ClassSearcher<M_Model>{
    id: number
    id_brand: number;
    name: string;
    created_at?: CustomDate;
    updated_at?: CustomDate;
    
    constructor(d: any) {
        this.id = d.id;
        this.id_brand = d.id_brand;
        this.name = d.name;
        this.updated_at = d.udpated_at? new CustomDate(d.udpated_at) : undefined;
        this.created_at = d.created_at? new CustomDate(d.created_at) : undefined;
    }

    createNew(d: any): M_Model {
        return new M_Model(d);
     }
     getInputText(): string {
        return this.name;
     }
     getOptionText(): [string, undefined] {
         return [this.name, undefined]
     }
     defaultSearchFilter(v: string): boolean {
         return match(v, this.name);
     }
 
     get cs_id() { return this.id; }
     get icon() { return "bike_scooter"; }
     get typeof() {return M_Model}
}