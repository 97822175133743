import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CustomDate, Filter, DayFilter, SliderFilter, EnumFilter, FilterOption, FiltersComponent, TagFilter, or_types, invoice_type, RouterService, invoice_type_or, invoice_type_recambios, PageStructureComponent, downloadBlob, ConfirmDialogService, invoice_type_abono, TextFilter, SnackService, FormService, invoice_states_payed, invoice_states, invoice_states_partial, invoice_states_pending } from '@sinigual/angular-lib';
import { forkJoin } from 'rxjs';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { M_Invoice } from 'src/app/core/models/M_Invoice';
import { ParamsService } from 'src/app/core/services/params.service';
import { PreviewService } from 'src/app/core/services/preview.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { M_LastMovements } from 'src/app/core/models/M_LastMovement';
import { M_TypePayment } from 'src/app/core/models/M_TypePayment';
import { DialogCheckoutControlComponent } from './dialog-checkout-control/dialog-checkout-control.component';
import { PayAllInvoiceDialogComponent } from './pay-check-control/pay-all-invoice-dialog.component';
import { InvoicePayementFormComponent } from 'src/app/core/components/invoice-payement-form/invoice-payement-form.component';
import { TypePaymentService } from 'src/app/core/services/type-payment-service';
import { feature } from 'src/app/core/features-controller/FeaturesController';

export enum BillPageFiltesrEnum {
  INVOICE_TYPE = 0,
  OR_TYPE = 1,
  INVOICE_TYPE_PAYMENT = 2,
  INVOICE_STATE = 3,
  INVOICE_CREATED_AT = 4,
  INVOICE_PAYED_AT = 5,
}

@Component({
  selector: 'app-bills',
  templateUrl: './bills.component.html',
  styleUrls: ['./bills.component.css']
})
export class BillsComponent implements OnInit {
  MAX_DOWNLOAD_NUMBER = 500;
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Invoice>;
  @ViewChild(FiltersComponent) filters?: FiltersComponent;
  @ViewChildren(InvoicePayementFormComponent) formChildren?: QueryList<InvoicePayementFormComponent>;

  v = ViewPath;
  f = filter;
  checkbox: boolean = false;
  invoicedToday = 0;
  buttonDisabled: boolean = false;
  bills: M_Invoice[] = [];
  total: number | undefined;
  importValue: string = '';
  importFormReset = false;
  hasDraft: boolean = false;
  isCircleLoading = true;
  cicleIcon: "receipt" | "receipt-empty" = "receipt";
  maxInvoicedValue = 100;
  showForm: boolean = false;
  sliderFilter = new SliderFilter("Total de la factura", this.maxInvoicedValue);
  payment_name: FilterOption[] = [];
  invoice_state_payed = invoice_states_payed;
  invoice_state_partial = invoice_states_partial;
  invoice_state_pending = invoice_states_pending;

  filtersToShow: Filter[] = [
    new DayFilter("Fecha de creación").setId(BillPageFiltesrEnum.INVOICE_CREATED_AT),
    new DayFilter("Fecha de cobro").setId(BillPageFiltesrEnum.INVOICE_PAYED_AT), 
    this.sliderFilter,
    new TagFilter("Estado de la factura", invoice_states, new FilterOption("Pendiente"), new FilterOption("Parcial"), new FilterOption("Cobrada")).setId(BillPageFiltesrEnum.INVOICE_STATE),
    new TagFilter("Tipo de factura", invoice_type, new FilterOption("OR", "build"), new FilterOption("Recambios", "receipt"), new FilterOption("Abono", "currency_exchange")).setId(BillPageFiltesrEnum.INVOICE_TYPE),
    new TagFilter("Tipo de OR", or_types, new FilterOption("Normal"), new FilterOption("Interno", undefined, !feature.cargoInterno), new FilterOption("Garantia", undefined, !feature.garantia), new FilterOption("Siniestro"), new FilterOption("Sin tipo")).setId(BillPageFiltesrEnum.OR_TYPE)
  ]
  constructor(public apiS: ApiService, public paramsS: ParamsService, private d: MatDialog, private chdRef: ChangeDetectorRef,
    private previewS: PreviewService, private routerS: RouterService, public subS: SubscriptionService,
    private confirmDialog: ConfirmDialogService, private typePaymentS: TypePaymentService) {
  }

  ngOnInit(): void {
    let a = this.apiS.bills();
    let b = this.apiS.hasDraft();
    let c = this.typePaymentS.getTypePayments();
    forkJoin([a, b, c]).subscribe(response => {
      this.bills = response[0];
      this.calculateMaxAndInvoicedToday(this.bills);
      this.ps.initTable(this.bills);
      this.cicleIcon = response[1] == true ? "receipt-empty" : "receipt";
      this.isCircleLoading = false;
      this.chdRef.detectChanges();
      this.newFilterPayment();
    })
  }
  async exportInvoices(invoices: any) {
    this.apiS.exportInvoices(invoices)
  }

  visibilty() {
    this.showForm = !this.showForm;
  }

  calculateMaxAndInvoicedToday(invoices: M_Invoice[]) {
    var today = new CustomDate();
    invoices.forEach(i => {

      if (i.total > this.maxInvoicedValue) {
        this.maxInvoicedValue = i.total;
      }

      if (i.created_at?.isEquals(today)) {
        this.invoicedToday += i.total;
      }
    })
    this.sliderFilter.changeMax(this.maxInvoicedValue);
  }
  resetPaymentForm(){
    this.formChildren?.forEach(e=>{
      e.resetForm();
    });
  }
  invoiceIcon(b: M_Invoice) {
    return b.icon;
  }

  reSendInvoice() {  
    return this.apiS.reSendInvoice(this.ps.selected!.token!)
  }
  payedInvoice() {
    this.apiS.postPayedInvoice(this.ps.selected!.id!).then(res => {
      this.ps.selected!.state = invoice_states_pending;
    });
  }
  
  donwloadInvoices(invoices: any[]) {
    var dialogdata = {
      title: invoices.length == 1 ?
        "Descargar factura" :
        "Descargar facturas",
      body: invoices.length == 1 ?
        "¿Está seguro de que quiere descargar la factura?" :
        "¿Está seguro de que quiere descargar las " + invoices.length + " facturas?"
    }

    this.confirmDialog.show(dialogdata).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.downloadInvoices(invoices).then(res => {
          if (res) {
            var downloadName = "FACTURAS - " + new CustomDate().dayMonthYearFormat;
            downloadBlob(res, downloadName);
          }
        });
      }
    })
  }

  disabledDownload(ps: PageStructureComponent<M_Invoice>) {
    return this.disableMax(ps.dataOnScreen) || this.disableMin(ps.dataOnScreen);
  }

  disableMax(data: any[]) { return data.length > this.MAX_DOWNLOAD_NUMBER }
  disableMin(data: any[]) { return data.length == 0; }

  getClientName(bill: M_Invoice) {
    return bill.client?.getName();
  }

  getClientId(bill: M_Invoice) {
    return bill.client?.company_scope_id;
  }

  getTotal(bill: M_Invoice) {
    let finalValue = bill.isAbono ? -bill.total  : bill.total;
    return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(finalValue);
 }

  getType(bill: M_Invoice) {
    var type = bill.type_invoice?.name;
    var tOr = bill.type_or?.name
    return type + (tOr ? (' (' + tOr + ")") : '')
  }

  getStatustName(bill: M_Invoice) {
    return bill.state.isPayed ? "Pagada" : "Pendiente"
  }

  previsualize(bill: M_Invoice) {
    this.previewS.showPreview("I", bill.token, undefined, bill.company_scope_id);
  }

  makeAbonoInvoice(b: M_Invoice) {
    this.routerS.goWithQueryParams(ViewPath.abono, { invoice: b.id });
  }

  setPayedStauts(b: M_Invoice) {
    this.confirmDialog.show(
      {
        title: b.isAbono ? "Marcar abono como pagado" : "Marcar factura como cobrada",
        body: b.isAbono ? "¿Seguro que quieres marcar el abono como pagado?" : " ¿Seguro que quieres marcar la factura como cobrada?"
      })
      .afterClosed().subscribe(res => {
        if (res) {
          this.apiS.setPayedStatus(b.id!).then(_res => {
            b.state = invoice_states_payed;
            this.chdRef.detectChanges();
          })
        }
      })
  }

  stateChange(event: MatSelectChange) {
    this.apiS.changeStateInvoice(this.ps.selected!.id!, event.value).then(res => {
      this.ps.selected!.state = event.value;
    });
  }

  openPayCheckControl() {
    this.d.open(PayAllInvoiceDialogComponent, { panelClass: 'customDialog',data: { invoice: this.ps.selected, last_movements: this.ps.selected?.last_movement, pending: true, typePayment: this.typePaymentS.typePaymentArray } }).afterClosed().subscribe(res => {
      /** Some advance has done */
      if (res instanceof M_LastMovements) {
        this.ps.data.forEach(d => {
          if (d instanceof M_Invoice && d.client && d.client.client_id == res.client_id) {
            let movement = d.client.last_movement.find(lm => lm.id == res.id);
            if (movement) { movement.deleted = true; }
          }
        })
      }
      this.newFilterPayment();
    });

  }
  pendingChip(status: invoice_states) {
    if (status == this.ps.selected!.state) { return; }

    this.apiS.changeStateInvoice(this.ps.selected!.id!, status).then(res => {
      this.ps.selected!.state = invoice_states_pending;
      this.ps.selected!.last_movement = [];
    });
  }
  partialChip(status: invoice_states) {

    if (status == this.ps.selected!.state) {
      return
    }
    this.apiS.changeStateInvoice(this.ps.selected!.id!, status).then(res => {
      this.ps.selected!.state = invoice_states_partial;
    });
  }
  isSelected() {    
    return this.ps.selected!.state;
  }
  deleteLastPayment(last_movement: M_LastMovements) {
    this.confirmDialog.show({
      title: "¿Seguro que quieres eliminar el cobro?",
      body: "Se eliminara el cobro de la factura",
      confirmTxt: "Eliminar",
      showCancel: true,
      type: "danger"
    }).afterClosed().subscribe(res => {
      if (res) {
        this.apiS.deleteLastPayment(last_movement).then(res => {
          this.ps.selected!.last_movement.removeElement(last_movement);
          this.ps.data.forEach(d => {
            if (d instanceof M_Invoice && d.client && d.client.client_id == res.client_id) {
              let movement = d.client.last_movement.find(lm => lm.id == res.id);
              if (movement) { movement.deleted = false; }
            }
          })
        })
        if (this.ps.selected?.last_movement.length == 1) {
          this.pendingChip(invoice_states_pending);
        }
        this.partialChip(invoice_states_partial);
        this.formChildren?.forEach(element => {
          element.resetForm();
        });
      }
    });

  }


  deleteTypePayment(type_payment: M_TypePayment) {
    this.typePaymentS.deleteTypePayment(type_payment).then(res => {
      if (res) { this.newFilterPayment(); }
    })
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*([,.]\d{0,2})?$/;
    const inputValue = event.target.value + String.fromCharCode(event.charCode);

    // Si el valor no cumple con la expresión regular o está vacío, deshabilita el botón
    if (!reg.test(inputValue) || inputValue.trim() === '') {
      event.preventDefault();

    }
  }

  openDialog() {
    this.d.open(DialogCheckoutControlComponent, { data: { invoices: this.ps.data, type_payments: this.typePaymentS.typePaymentArray } }).afterClosed().subscribe(res => {

    })
  }

  newFilterPayment() {
    const paymentOptions: FilterOption[] = [];

    this.typePaymentS.typePaymentArray.forEach(x => {

      if (x.payment == 'Bizum') {
        paymentOptions.push(new FilterOption('Bizum', 'bizum'));
      }
      else if (x.payment == 'Tarjeta') {
        paymentOptions.push(new FilterOption('Tarjeta', 'credit_card'));
      }
      else if (x.payment == 'Efectivo') {
        paymentOptions.push(new FilterOption('Efectivo', 'payments'));
      }
      else if (x.payment == 'Transferencia') {
        paymentOptions.push(new FilterOption('Transferencia', 'sync_alt'));
      }
      else {

        paymentOptions.push(new FilterOption(x.payment, 'monetization_on'));
      }
    });

    if (this.filtersToShow[this.filtersToShow.length - 1].label == "Tipo de Cobro") {

      this.filtersToShow.splice(this.filtersToShow.length - 1);
      const filter_tag_filter = new TagFilter("Tipo de Cobro", invoice_type, ...paymentOptions).setId(BillPageFiltesrEnum.INVOICE_TYPE_PAYMENT);
      this.filtersToShow.push(filter_tag_filter);
    } else {
      const filter_tag_filter = new TagFilter("Tipo de Cobro", invoice_type, ...paymentOptions).setId(BillPageFiltesrEnum.INVOICE_TYPE_PAYMENT);
      this.filtersToShow.push(filter_tag_filter);
    }
  }
  handleClick(event: MouseEvent,invoice:M_Invoice) {
    if (event.ctrlKey || event.metaKey) { // Verifica si la tecla Control o Command está presionada
      event.preventDefault(); // Prevenir el comportamiento por defecto
      this.openInNewTab();
    } else {
      this.navigate(invoice);
    }
  }
  handleContextMenu(event: MouseEvent,) {
    event.preventDefault(); // Prevenir el menú contextual por defecto
    this.openInNewTab();
  }
  openInNewTab() {
    const url = "abono?invoice="+this.ps.selected!.id;
    window.open(url, '_blank');
  }
  navigate(invoice:M_Invoice){
    this.routerS.goWithQueryParams(ViewPath.abono, { invoice: invoice.id });
  }
}

