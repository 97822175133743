
<div class="pd_adv_pay">
<app-card-title>Movimientos del anticipo</app-card-title>

<table mat-table [dataSource]="dataSrc" *ngIf="dataSrc.data.length; else noData">
    <ng-container matColumnDef="Ornumber">
        <th mat-header-cell *matHeaderCellDef> {{responsiveS.w > 514 ? 'Acción ' : 'OR'}} </th>
        <td mat-cell *matCellDef="let element">
            <div class="df jcc aic">

                    <mat-icon class="mr5 scaled-icon vam icon_circle_entry" *ngIf="element.type == 1">arrow_circle_right</mat-icon>
                    <mat-icon class="mr5 scaled-icon vam icon_circle_exit" *ngIf="element.type == 0">arrow_circle_left</mat-icon>
                    <mat-icon class="mr5 scaled-icon vam icon_circle_exit" *ngIf="element.type == 2">keyboard_return</mat-icon>
            </div>

        </td>
    </ng-container>

    <ng-container matColumnDef="status" *ngIf="responsiveS.w > 514">
        <th mat-header-cell *matHeaderCellDef>Importe</th>
        <td mat-cell *matCellDef="let element">
            <span class="df jcc" money [val]="element.import"></span>
        </td>
    </ng-container>

    <ng-container matColumnDef="delivery">
        <th mat-header-cell *matHeaderCellDef> Fecha creación </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at.shortFormat}}</td>
    </ng-container>
     <ng-container matColumnDef="factura">
        <th mat-header-cell *matHeaderCellDef>Factura</th>
        <td mat-cell *matCellDef="let element" (click)="goInvoices(element)"> 
            
            <span class="c_b" *ngIf="element.invoices?.title"> Nº {{element.invoices?.title}}</span>
            <span class="c_t2" *ngIf="!element.invoices?.title">Sin Datos</span>
        
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>

<ng-template #noData>
    <p class="c_t2" style="padding: 0px 10px 0px 10px">No hay movimientos</p>
</ng-template>
</div>