import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { cifValidator, CustomDate, DniInputComponent, ResponsiveService, RouterService, softNifValidator } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from '../../api/api.service';
import { M_Client } from '../../models/M_Client';
import { AlreadyExistsService } from '../../services/already-exists.service';
import { validadorPorcentaje } from '../../validators/percentage';
import { Country } from '@angular-material-extensions/select-country';
import { COUNTRY_ESP } from '../../constants/constants';
import { M_AdminCenters } from '../../models/M_AdminCenters';
import { strict } from 'assert';
import { CreateClientComponent } from 'src/app/views/create-client/create-client.component';
import { R_Zip } from '../../models/R_Zip';
import { MatDialog } from '@angular/material/dialog';
import { M_Vehicle } from '../../models/M_Vehicle';
import { DialogConfirmPlateComponent } from '../vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { MatSelectChange } from '@angular/material/select';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-clientform',
  templateUrl: './clientform.component.html',
  styleUrls: ['./clientform.component.css']
})
export class ClientformComponent {
  
  validators = Validators;
  public form: UntypedFormGroup;
  lastSurname = "";
  lastDob: CustomDate | undefined;
  finalsearh ="";
  selectedPayment: any;
  /*FormularioFactura Electronica*/
  data: any
  e = environment;
  personalizadoSelected = false;
  iconName = 'add'
  loadingZip : boolean = false;
  arrayZips: R_Zip[] | undefined = [];
  /*Input y Outputs*/
  @Input() edit = false;
  @ViewChild(DniInputComponent) dniComponent!: DniInputComponent;
  @Output() onClientExists: EventEmitter<M_Client> = new EventEmitter();
  initialInputValue : string ="";
  isEditing : boolean = false;
  Iscreate : boolean = false;
  onSave:boolean = false;
  v = ViewPath;
  today = new Date()
  displayedColumns: string[] = ['post', 'code', 'name', 'deleted'];

  constructor(private formBuilder: UntypedFormBuilder, private existsS: AlreadyExistsService, private apiS: ApiService, private routerS: RouterService,
    public chdRef: ChangeDetectorRef,
    private d: MatDialog,
    public responsiveS: ResponsiveService) {
    this.form = this.formBuilder.group({
      nif: ['', Validators.required],
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      country: [COUNTRY_ESP, [Validators.required]],
      tax_free: [false, [Validators.required]],
      dob: [null, []],
      address: ['', []],
      address2: ['', []],
      zip: ['', []],
      city: ['', []],
      province: ['', []],
      phone: ['', []],
      phone2: ['', []],
      email: ['', []],
      countable_num: ['', []],
      observations: ['', []],
      email_always: [false, []],
      profile: [false, []],
      marketing: [false, []],
      newsletter: [false, []],
      is_company: [false, []],
      discount: ['', [validadorPorcentaje()]],
      company_id: ["", []],
      client_id: ["", []],
      cli_is_invoice_e: [false, [Validators.required]],
      clients_admincenters: this.formBuilder.array([])
    });
    this.form.get('zip')?.valueChanges.subscribe(v => {
      this.onZipChange(v);
      this.chdRef.detectChanges();
    })
    this.onChangeCompanyCheckbox();
  }

  get subForm() {
    return this.form.get('clients_admincenters') as FormArray<FormGroup>;
  }

  getClientAdminCenter(i: number) {
    return this.subForm.controls[i];
  }

  ngAfterViewInit() {
    this.updateNifValidators();
  }

  changeIcon() {
    this.iconName = 'check'; 
 }

  pushNewAdminCenter(admincenter: M_AdminCenters | undefined) {

    let fg = new FormGroup({
      'id': new FormControl(admincenter?.id),
      'client_id': new FormControl(admincenter?.client_id),
      'company_id': new FormControl(admincenter?.company_id),
      'post': new FormControl(admincenter?.post),
      'code': new FormControl(admincenter?.code),
      'name2': new FormControl(admincenter?.name),
      'deleted': new FormControl(admincenter?.deleted),
    });

    this.subForm.push(fg)
  }
  onZipChange(searched: string) {

    this.finalsearh = searched;
    let zipControl = this.form.get('zip')!;

    if (!searched) {
      this.markZipAsInvalid(zipControl);
    }
    else if (searched && !this.loadingZip) {
      this.loadingZip = true;
      this.apiS.getLocationByZip(searched).then(res => {

        this.arrayZips = res;
        if (this.arrayZips?.length == 0) {
          this.markZipAsInvalid(zipControl);
        }

        if (this.arrayZips && this.arrayZips.length === 1) {
          // Si solo hay un registro, selecciona automáticamente ese registro
          let selectedZip = this.arrayZips[0];

          // Realiza las operaciones necesarias para marcar el ZIP como válido
          this.markZipAsValid(zipControl, selectedZip);

        } else {
          let selectedZip = this.arrayZips?.find(zip => zip.id == this.form.get('city')?.value);
          console.log("esto que es",selectedZip);
          if (selectedZip) {

            this.markZipAsValid(zipControl, selectedZip)
          }
        }

        this.loadingZip = false;

        /** Make another call if necessary */
        if (searched != this.finalsearh) {
          this.onZipChange(this.finalsearh);
        }
      });
    }
  }

  markZipAsValid(zip: AbstractControl, model: R_Zip) {
    zip.setErrors(null);
    console.log("city",model);
    this.form.patchValue({ 'city': model.id })
    this.form.patchValue({ 'province': model.provincia })
    this.form.patchValue({ 'ccaaObject': model.ccaa.ccaaname })
    this.form.patchValue({ 'ccaa': model.ccaa.id })
    this.chdRef.detectChanges();
  }
  markZipAsInvalid(zip: AbstractControl) {
    zip.markAsDirty();
    zip.markAsTouched();
    zip.setErrors({ notfound: true });
    this.form.patchValue({ 'city': undefined })
    this.form.patchValue({ 'province': undefined })
    this.form.patchValue({ 'ccaaObject': undefined })
    this.form.patchValue({ 'ccaa': undefined })
  }
  enableEditing() {
    this.isEditing = true;
    this.form.get('nif')?.enable();
    this.initialInputValue = this.form.get('nif')?.value || ''; // Guardar el valor inicial
    if(this.edit == true){
      this.d.open(DialogConfirmPlateComponent,{data:{
        title: "¡Atención Edición de NIF!",
        message: "¿Estas seguro que quieres editar el NIF?",
      }}).afterClosed().subscribe(res=>{
        if(res != true){
          this.form.get('nif')?.disable();
          
        }else{
          this.edit = true;
        }
      }
      );
    }
  }
  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;
    
    this.selectedPayment = this.arrayZips?.find(
      (zip) => zip.id === selectedValue
    );
    
    let zipControl = this.form.get('zip')!;
    
    if (selectedValue) {
      this.markZipAsValid(zipControl, this.selectedPayment);
    }

    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    } else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }
    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    }
    else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }
    this.form.get('city')?.updateValueAndValidity();
  }
  onBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    console.log('El foco ha salido del input:', inputElement.value);
    let nif = this.form.get('nif')!;
    let fieldName = !this.isSpanish ? 'identificador' : this.isCompany ? "CIF" : "DNI"
    if (nif.value === this.initialInputValue) {
      console.log('El valor del input no ha cambiado.');
      // Aquí puedes agregar la lógica que necesites para manejar el cambio
      
    } else {
      this.apiS.checkDni(nif.value).then(client => {
        if (client instanceof M_Client) {
          var vechicleCreate = this.routerS.is(ViewPath.createvehicle);
          this.existsS.show({
            title: "¡Atención!",
            message: "Ya existe un cliente con el " + fieldName + "",
            message2: "Recordamos que no se pueden crear dos clientes con el mismo " + fieldName + ".",
            value: nif.value,
            view: !vechicleCreate ? this.v.clientDetails : undefined,
            param_id: !vechicleCreate ? client.client_id : undefined,
            accept_text: !vechicleCreate ? "Ver cliente" : "Seleccionar cliente"
          }).afterClosed().subscribe(res => {
           
            if (res == true) {
              this.onSave = false;
              this.onClientExists.emit(client);
            }
          })
        }
      })
      
      console.log('El valor del input ha cambiado:', nif.value);
    }
  }
  removeAdminCenter(id: number) {
    const client_id = this.form.get('client_id')?.value;
    this.apiS.getClientById(client_id).then(res => {
       let company_id = res?.company_id;
       this.data = {
         id: id,
         action: 'delete',
         client_id: client_id,
         company_id,
         post: 0,
         code: "",
         name: "",
         deleted: 0
       };
       const index = this.subForm.controls.findIndex(control => control.get('id')?.value === id);
       this.subForm.removeAt(index);
       
       this.apiS.createClientAdmincenter(this.data).then(res => {
         return("deleted")
       }).catch(error => {
         console.error("Fallo en delete", error)
       });
    })
  }

  createClientAdmincenter(admincenter: any) {
    
    const client_id = this.form.get('client_id')?.value;
    this.apiS.getClientById(client_id).then(res => {
      let company_id = res?.company_id;
      this.data = {
        action: 'create',
        client_id: client_id,
        company_id,
        post: admincenter.post,
        code: admincenter.code,
        name: admincenter.name2,
        deleted: 0
      };

    }).then(() => {
      if (this.data) {
        this.apiS.createClientAdmincenter(this.data).then(response => {
          console.log('ClientAdminCenter created:', response);
        }).catch(error => {
          console.error('Error creating ClientAdminCenter:', error);
        });
      }
    });
  }

  changeClientAdmincenter(admincenter: any) {
    
    const client_id = this.form.get('client_id')?.value;
    this.apiS.getClientById(client_id).then(res => {
      let company_id = res?.company_id;

      this.data = {
        action: 'update',
        client_id: client_id,
        company_id,
        id: admincenter.id,
        post: admincenter.post,
        code: admincenter.code,
        name: admincenter.name2,
        deleted: 0
      };
    }).then(() => {
      if (this.data) {
        this.apiS.createClientAdmincenter(this.data).then(response => {
          console.log('ClientAdminCenter created:', response);
        }).catch(error => {
          console.error('Error creating ClientAdminCenter:', error);
        });
      }
    });
  }

  get showDateHint() {
    let dobControl = this.form.get('dob')!;
    return dobControl.value == null && dobControl.touched && dobControl.dirty;
  }

  get getIdentifierPlaceholder() {
    if (this.isSpanish) {
      return this.isCompany ? "Introduce el CIF / NIF" : "Introduce el DNI / NIF"
    }
    else {
      return "Identificador"
    }
  }

  get getIdentifierError() {
    return "Vaya vaya"
  }

  ngOnChanges() {
    if (this.edit) {
      this.form.get('nif')?.disable();
    }
  }

  onCountrySelected(c: Country) {
    this.form.get('tax_free')?.setValue(!this.isSpanish);
    this.updateNifValidators();
  }

  eInvoiceSelected() {
    this.form.get('cli_is_invoice_e')?.setValue(this.form.get('cli_is_invoice_e'));
    
  }

  checkDNI() {
    let nif = this.form.get('nif')!;
    let fieldName = !this.isSpanish ? 'identificador' : this.isCompany ? "CIF" : "DNI"
    if (nif.valid) {
      this.apiS.checkDni(nif.value).then(client => {
        if (client instanceof M_Client) {
          var vechicleCreate = this.routerS.is(ViewPath.createvehicle);
          this.existsS.show({
            title: "¡Atención!",
            message: "Ya existe un cliente con el " + fieldName + "",
            message2: "Recordamos que no se pueden crear dos clientes con el mismo " + fieldName + ".",
            value: nif.value,
            view: !vechicleCreate ? this.v.clientDetails : undefined,
            param_id: !vechicleCreate ? client.client_id : undefined,
            accept_text: !vechicleCreate ? "Ver cliente" : "Seleccionar cliente"
          }).afterClosed().subscribe(res => {
            if (res == true) {
              this.onClientExists.emit(client);
            }
          })
        }
      })
    }
  }


  updateNifValidators() {
    let dniInput = this.form.get('nif')!;
    dniInput?.clearValidators();

    if (this.isSpanish) {
      if (this.isCompany) {
        dniInput.addValidators([this.nifOrCifValidator(), Validators.required])
      }
      else {
        dniInput.addValidators([softNifValidator(), Validators.required])
      }
    }
    else {
      dniInput.addValidators([Validators.required]);
    }

    dniInput.updateValueAndValidity();
  }

  onChangeCompanyCheckbox() {
    this.form.get('is_company')!.valueChanges.subscribe(val => {
      /** If client is 'client_company' */
      let surname = this.form.get('surname')!;
      let dob = this.form.get('dob')!;

      if (val == true) {
        this.lastSurname = surname.value;
        surname.reset();
        surname.disable();
        if (dob.value) {
          this.lastDob = new CustomDate(dob.value);
        }
        dob.reset();
        dob.disable();

      }
      else {
        surname.enable();
        surname.patchValue(this.lastSurname);
        dob.enable();
        dob.patchValue(this.lastDob?.datePickerFormat);
      }
    })
  }

  get isCompany(): boolean {
    return this.form.get('is_company')?.value;
  }

  get isSpanish() {
    return this.form.get('country')?.value?.numericCode == COUNTRY_ESP.numericCode;
  }


  nifOrCifValidator(): ValidatorFn {
    return softNifValidator() || cifValidator();
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*([,.]\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }
}
