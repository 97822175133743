import { ChangeDetectorRef, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { ActivatedRoute } from '@angular/router';
import { FilterOption, MoveFilters, ParameterStateComponent, RouterService, getAccentColor, getPrimaryColor, getWarnColor } from '@sinigual/angular-lib';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Product, M_Product_Invoice, M_Product_Move } from 'src/app/core/models/M_Product';
import { ParamsService } from 'src/app/core/services/params.service';
import { ProductinvoiceComponent } from './productinvoice/productinvoice.component';
import { ProductmoveComponent } from './productmove/productmove.component';
import { ViewPath } from 'src/app/app-routing.module';
import { ProductCategory } from 'src/app/core/enums/ProductCategory';
import { feature } from 'src/app/core/features-controller/FeaturesController';

export interface MovementProduct {
  anim(): void
}


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent extends ParameterStateComponent {
  primary = getPrimaryColor;
  accent = getAccentColor;
  warn = getWarnColor;

  p: M_Product | undefined;
  moveFilters = new MoveFilters("Filtrar movimientos");
  selectedOption: FilterOption | undefined;
  loaded: boolean = false;
  v = ViewPath;
  @ViewChildren(ProductinvoiceComponent) pic?: QueryList<ProductinvoiceComponent>;
  @ViewChildren(ProductmoveComponent) pmc?: QueryList<ProductmoveComponent>;
  @ViewChild(MatButtonToggleGroup) toggle?: MatButtonToggleGroup;
  allMoves: (M_Product_Invoice | M_Product_Move)[] = [];
  pc = ProductCategory;
  features = feature;

  constructor(routerS: RouterService, route: ActivatedRoute, private apiS: ApiService, public params: ParamsService, private chdRef : ChangeDetectorRef) {
    super(routerS, route, ["product"]);
  }

  ngAfterViewInit(): void {

  }

  goDataImpor(){
    this.routerS.goTo(ViewPath.dataupload);
  }

  onToggleChange() {
    this.pic?.forEach(p => { p.anim() })
    this.pmc?.forEach(pmc => { pmc.anim() })
  }


  override onParam(_k: string, v: any) {
    this.apiS.getProductById(v).then(res => {
      if (res == undefined) {
        this.p = undefined;
      }
      if (res instanceof M_Product) {
        this.p = res;
        this.createAndOrderMoves();
      }
      this.loaded = true;
    })
  }

  createAndOrderMoves() {
    this.allMoves.push(...this.p!.product_invoice);
    this.allMoves.push(...this.p!.product_move);
    this.allMoves.sort(
      (a, b) => b.created_at.value.getTime() - a.created_at.value.getTime(),
    );
  }

  isInvoiceMove(m: any): m is M_Product_Invoice {
    return m instanceof M_Product_Invoice;
  }

  isAddOrRemove(m: any): m is M_Product_Move {
    return m instanceof M_Product_Move;
  }

  isEtranceMove(m: any) {
    return m instanceof M_Product_Move && m.isAdd();
  }

  isRemoveMove(m: any) {
    return m instanceof M_Product_Move && m.isRemove();
  }

  get filteredMovements() {
    let moves_ = []
    for (let i = 0; i < this.allMoves.length; i++) {
      if (this.onFilters(this.allMoves[i])) {
        moves_.push(this.allMoves[i]);
      }
    }

    return moves_;

  }

  onFilters(m: M_Product_Invoice | M_Product_Move) {
    let v = this.toggle?.value;
    if (v == 0) {
      return true;
    }
    else if (v == 1) {
      return this.isInvoiceMove(m);
    }
    else if (v == 2) {
      return this.isEtranceMove(m);
    }
    else if (v == 3) {
      return this.isRemoveMove(m);
    }
    else {
      return true;
    }
  }

  onSelectOption(e: any) {
    this.selectedOption = new FilterOption(e.value.value.name, e.value.value.icon);
    this.appearAnimation();
  }

  async appearAnimation() {
    if (this.pic) {
      for (let i = 0; i < this.pic.length; i++) {
        await this.delay(i * 100).then(_res => {
          this.pic!.get(i)?.anim();
        }
        );
      }
    }
  }

  saveNewStock(p: M_Product, stock: number) {
    this.apiS.editStock(p, stock).then(_res => {
      this.chdRef.detectChanges();
    })
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
