import { Component } from '@angular/core';
import { or_types } from '@sinigual/angular-lib';
import { M_RAGroup } from 'src/app/core/models/M_RAGroup';


@Component({
  selector: 'app-ra-groups',
  templateUrl: './ra-groups.component.html',
  styleUrls: ['./ra-groups.component.css']
})
export class RaGroupsComponent {
  groups: M_RAGroup[] = [new M_RAGroup("")];
  index = 1;

  addGroup() {
    this.groups.push(new M_RAGroup(""));
  }

  removeGroup(g: M_RAGroup){
    this.groups.removeElement(g);
  }

  onTypeChange(tc: M_RAGroup, val: number) {
    tc.or_type = new or_types(val);
  }

}
