<div class="advanced-details-container" *ngIf="ve != undefined || (ve == undefined && !loaded); else notfound">
    <app-go-back class="back" [text]="'Listado vehículos'" [v]="v.vehiculos" [show]="loaded"></app-go-back>
    <div class="vehicle">
        <app-card [contentLoaded]="loaded" [cornerMatMenu]="delete_v">
            <div *ngIf="ve != undefined">
                <div class="df fdc aic jcc">

                    <p class="fsxl fw200">{{ve!.license!}}</p>
                    <p class="fsm fw300" style="letter-spacing: 2px" *ngIf="ve.getName() != ve!.license">
                        {{ve.getName()}}</p>

                    <button mat-button [mat-menu-trigger-for]="menu" color="accent">Ver más</button>
                    <mat-menu #menu="matMenu" class="card-row-menu">
                        <app-card-row-content *ngIf="ve != undefined" [data]="
                            [['Kilómetros', ve.km],
                            ['Serie', ve.getSerieName],
                            ['Chasis', ve.chassis],
                            ['Color', ve.color],
                            ['Cilindrada', ve.cc],
                            ['Tipo de motor', ve.engineType],
                            ['Matriculación', ve.license_date?.humanFormat]]">
                        </app-card-row-content>
                    </mat-menu>

                    <lord-icon *ngIf="ve.isBike" src="https://cdn.lordicon.com/nrmtiuty.json" trigger="loop"
                        delay="9999"
                        [attr.colors]="'primary:#121331,secondary:#3a3347,tertiary:'+getColorByVehicle(ve)+',quaternary:#ebe6ef'"
                        class="lord-big">
                    </lord-icon>

                    <lord-icon *ngIf="ve.isCar" src="https://cdn.lordicon.com/olaxuwzv.json" trigger="loop" delay="9999"
                        [attr.colors]="'primary:#121331,secondary:#ebe6ef,tertiary:'+getColorByVehicle(ve)+',quaternary:#3a3347,quinary:#ffffff'"
                        class="lord-big">
                    </lord-icon>


                    <button mat-flat-button color="warn" (click)="params.go(v.createvehicle, ve!.vehicle_id!)"
                        class="one-line">
                        <mat-icon>edit</mat-icon>
                        Editar
                    </button>

                </div>
            </div>
            <ng-template #delete_v>
                <app-delete-button class="leftItem" [item]="ve!"></app-delete-button>
            </ng-template>
        </app-card>
    </div>
    <div class="Details">
        <app-card [contentLoaded]="loaded">
            <div *ngIf="ve != undefined">
                <app-card-subtitle [first]="true">Última intervención</app-card-subtitle>
                <app-last-intervention-or [or]="ve.lastOr"></app-last-intervention-or>
                <mat-divider></mat-divider>
                <div *ngIf="ve != undefined && ve.client != undefined">
                    <app-card-subtitle class="c_r mt5" [first]="true" [noMarginBottom]="true" [cursorPointer]="true"
                        (click)="params.go(v.clientDetails, ve!.client!.client_id)">
                        <div class="df aic" style="gap:10px; margin-top: 5px; margin-left: -8px;">
                            <app-client-row [item]="ve.client"></app-client-row>
                            <!--<app-whatsapp [phone]="ve.client.phone"></app-whatsapp>!-->
                            <button [matTooltip]="'Cambiar propietario'" mat-button color="warn"
                                (click)="params.go(v.linkvehicle, ve.vehicle_id)">
                                <mat-icon class="c_r">sync_alt</mat-icon>
                            </button>
                        </div>
                    </app-card-subtitle>
                    <app-card-row-content [noPaddingLeft]="true" [data]="[
                        ['Nif', ve.client!.nif],
                        ['Teléfono', ve.client!.phone],
                        ['Email', ve.client!.email]]">
                    </app-card-row-content>
                    <p></p>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="documentos">
                <div>
                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Documentación
                        <button mat-icon-button (click)="documentation.clickInputTrigger()" color="p3">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </app-card-subtitle>
                    <p *ngIf="!hasDocuImages()" class="c_t2">Aún no has subido imágenes</p>
                    <lib-file-upload #documentation class="hidde_add" [uploadtype]="'multiple'" [maxImagesLength]="5"
                        [maxSize]="6" (stateChanged)="uploadImages($event, 'docu_img')"
                        (onRemoveImage)="removeImage($event)">
                    </lib-file-upload>
                </div>
                <div>
                    <app-card-subtitle [first]="true" [noMarginBottom]="true" class="df aic">Imágenes vehículo
                        <button mat-icon-button (click)="vehicleImages.clickInputTrigger()" color="p3">
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </app-card-subtitle>
                    <p *ngIf="!hasVehicleImages()" class="c_t2">Aún no has subido imágenes</p>
                    <lib-file-upload #vehicleImages class="hidde_add" [phoneMultipleUpload]="true"
                        [uploadtype]="'multiple'" [maxImagesLength]="5" [maxSize]="6"
                        (stateChanged)="uploadImages($event, 'vehicle_img')" (onRemoveImage)="removeImage($event)">
                    </lib-file-upload>
                </div>
            </div>

            <div *ngIf="ve != undefined" class="brand-container">
                <img class="imgBrand" [src]="ve.getBrandImageUrl()" (error)="onImgError($event)">
            </div>
        </app-card>
    </div>
    <div class="or">
        <app-card [contentLoaded]="loaded" [noData]="ve && !ve.actions.length">
            <app-card-title>Historial reparaciones</app-card-title>
            <app-action-history *ngIf="ve && ve.actions.length" [clientSide]="false"
                [actions]="ve.actions" [invoices]="ve!.invoices" [isVehicle]="true"></app-action-history>
        </app-card>
    </div>
</div>

<ng-template #notfound>
    <app-model-not-found [title]="'No se ha encontrado el vehículo'" [goText]="'Ir al listado de vehículos'"
        [view]="v.vehiculos">
    </app-model-not-found>
</ng-template>