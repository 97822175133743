import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmData, ConfirmDialogService } from '@sinigual/angular-lib';
import { StandardDialogComponent } from 'src/app/core/components/standard-dialog/standard-dialog.component';

@Component({
  selector: 'app-frontend-design',
  templateUrl: './frontend-design.component.html',
  styleUrls: ['./frontend-design.component.css']
})
export class FrontendDesignComponent {

  constructor(public d: MatDialog, public confirmD: ConfirmDialogService) { }

  getColor(color: string, index: number) {
    return "background-color :" + "var(--" + color + (index == 4 ? '' : index) + ");"
  }

  getVar(color: string, index: number) {
    return "var(--" + color + (index == 4 ? '' : index) + ")"
  }

  standarDialog() {
    this.d.open(StandardDialogComponent)
  }

  noCardDialog() {
    this.d.open(StandardDialogComponent,{panelClass : "no-card-style"})
  }

  confirmDialog(type: "confirm" | "warn" | "info" | "danger") {
    let cdd: ConfirmData = {
      title: "Título",
      body: "Cuerpo del diálogo",
      type: type
    }
    this.confirmD.show(cdd);
  }

}
