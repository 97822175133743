export enum Calls {
    
    globalSearch =          "/api/search",
    
    login =                 "/api/auth/login",
    validateEmail =         "/api/auth/confirmEmail",

    /** Images  */
    uploadImage =           "/api/images",
    deleteImage =           "/api/deleteImage",


    /** Delete */
    deleteId =              "/api/deleteId",


    /** Dashboard */
    dashboard =             "/api/dashboard",

    /**Logged user */
    profile =               "/api/user",
    updatePass =            "/api/updatePass",
    updatePassword =        "/api/auth/updatePassword",
    resendConfirmation =    "/api/reSendConfirm", 
    pickUpMail =            "/api/pickUpMail",
    requestPDF =            "/api/requestPDF",
    forgotPassword =        "/api/auth/password/forgot",
    resetPassword =         "/api/auth/password/resetPassword",
    getInvoice =            "/api/getInvoice",

    /*Clients*/
    clients =               "/api/clients",
    clientAdmincenters =    "/api/clientAdmincenters",
    clientsminify =         "/api/clients/1",
    createClient =          "/api/createUpdateClient",
    editClient =            "/api/createUpdateClient",
    getClientById =         "/api/client",


    /** Check plate-dni */
    checkPlateDni =         "/api/checkExist",

    /*Vehicles*/
    clientsAdvances =           "/api/clientsAdvances",
    linkVehicle =           "/api/vehicleClient",
    createVehicle =         "/api/createUpdateVehicle",
    editVehicle =           "/api/createUpdateVehicle",
    vehicles =              "/api/vehicles",
    vehicleType =           "/api/vehiclesType",
    vehiclesminify =        "/api/vehicles/1",
    getVehicletById =       "/api/vehicle",
    clientsForVehicles =    "/api/clientsForVehicles",

    /*Products*/
    products =              "/api/products",
    createProduct =         "/api/createUpdateProduct",
    getProductById =        "/api/product",
    editStock =             "/api/editStock",

    /** Facturas */
    bills =                 "/api/invoices",
    bill =                  "/api/invoice",
    createBill =            "/api/createInvoice",
    templatePreview =       "/getPreviews/",
    payedBill =             "/api/payedInvoice",
    extraInvoice =          "/api/extraInvoice",
    addClientBill =         "/api/addClientInvoice",
    addProductBill =        "/api/addProdInvoice",
    updateProdBill =        "/api/updateProdInvoice",
    delProductBill =        "/api/delProdInvoice",
    confirmInvoice =        "/api/confirmInvoice",
    discardInvoice =        "/api/discardInvoice",
    downloadInvoices =      "/api/downloadInvoices",
    hasDraft =              "/api/hasDraft",
    exportInvoices =        "/exportinvoices",
    lastInvoiceDate =       "/api/lastInvoice",
    payedInvoice =          "/api/payedInvoice",

    /** Empresa */
    company =               "/api/company",
    createCompany =         "/api/auth/createCompany",
    updateCompany =         "/api/updateCompany",
    deleteCompany =         "/api/deleteCompany",
    welcomeCompany =        "/api/updateCompanyNamePlaces",
    emailPromo =            "/api/sendPromo",
    saveColors =            "/api/updateColors",
    saveFooters =           "/api/updateFooters",
    saveCompanyFiles =      "/api/saveTemplateFiles",
    smtp =                  "/api/smtp",
    testEmail =             "/api/testEmail",
    presentationMail =      "/api/presentationMail",
    pdfExample =            "/api/pdfExample",
    checkCompanyInfo =      "/api/checkIfInvoice",
    getLocationByZip =      "/api/zip/",
    getFieldsByCCAA =       "/api/CTAddFields_Show/",


    /** Usuarios */
    users  =                "/api/users",
    createUser =            "/api/createUser",
    updateUserName =        "/api/updateUserName",

    /** OR */
    actionTypes =           "/api/actionTypes",
    actions =               "/api/actions",
    createOR =              "/api/createOR",
    addClientVehicleAction= "/api/addClientVehicleAction",
    getAction =             "/api/getAction",
    addEditGroup =          "/api/addEditGroup",
    addTask =               "/api/addTask",
    editDelivery =          "/api/editDelivery",
    assignAction =          "/api/assignAction",
    updateSchedule =        "/api/updateSchedule",
    addEditTasks =          "/api/addEditTasks",
    schedules =             "/api/schedules",
    rmGroup =               "/api/rmGroup",
    rmTask =                "/api/rmTask",  
    rmAction =              "/api/rmAction",
    invoiceOR =             "/api/invoiceOR",
    sortTask =              "/api/sortTask",
    changeTask =            "/api/changeTask",
    editKm =                "/api/editKm",
    editNotes =             "/api/editNotes",
    editTitle =             "/api/editTitle",
    closeGroup =            "/api/closeGroup",
    openGroup =             "/api/openGroup",
    reorderActions =        "/api/reorderActions",
    editAction =            "/api/editAction",
    stateInvoice =          "/api/stateInvoice",
    showTypePayment =       "/api/show/TypePayment",
    CreateLastPayment =     "/api/create/movementInvoice",
    CreateAdvancesClient =     "/api/create/advancesClient",
    DeleteAdvancesClient =     "/api/delete/advancesClient",
    EditAdvancesClient =     "/api/update/advancesClient",
    DeleteMovementInvoice = "/api/delete/movementInvoice",
    DeleteTypePayment =     "/api/delete/TypePayment",
    
    /** Presupuesto */
    createBudget =          "/api/createBudget",
    closeBudget =           "/api/closeBudget",
    copyAction =            "/api/copyAction",

    /** Data import */
    importStorage =         "/api/importStorage",
    importClients =         "/api/importClients",
    importTarifas =         "/api/importTarifas",

    /** Notifications */
    getNotifications =          "/api/show/notification",
    updateNotifications =       "/api/show/update/notification",
    readAllNotifications =      "/api/show/update/all/notification",

    /** Cloud tickets */
    cloudtickets =              "/api/cloudtickets",
    createTicket =              "/api/createTicket",
    addcomment =                "/api/addcomment",

    /** Frontend error report */
    reportError =           "/api/insertLogs",


    /** Abono */
    abono =                 "/api/createPayOut",

    /** Templates */
    invoice =               "/getInvoice/",
    eInvoice =              "/downloadInvoiceE/",
    budgetPDF =             "/budgetPDF/",
    ra =                    "/getOrPDF/",
    createAdvancePdf =      "/createAdvancePdf/",
    albaranPDF =            "/getAlbaranPdf/",

    /** No id Objects URL */
    previewRA =            "/api/preview/RA",

    /** Brand and models */
    brand =                    "/api/brands",
    brand_models =             "/api/brand_models",
    
    /** Paycomet */
    payComet =             "/api/payComet",
    payCometInvoices =     "/api/checkInvoices",
    generatePaycometUrl =  "/api/CreateSubscriptionUrl",
    checkSubscription  =    "/api/checkSubscription",
    paymentByCompany  =     "/api/paymentByCompany",
 
    /** Templates */
    getFieldsFromDoc  =            "/api/CTAddFields_Show_transac/",
    saveTemplateFields =           "/api/CTAddFields_Insert_Transac",
    saveTemplateImage =            "/api/CTAddFields_Images_Transac",

    /** Flotas */
    getActionGroupsClosed =           "/api/getActionGroupsClosed",
    invoiceOR_ActionGroupsClosed =    "/api/invoiceOR_ActionGroupsClosed"

} 