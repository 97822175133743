import { M_CustomProduct } from "./M_CustomProduct";
import { M_Product } from "./M_Product";

export type invoiceCallProduct = {
    /** Invoice line ID */
    prod_id: number;
    quantity?: "noaction" | undefined;
    custom?: number | undefined;
    discount: number | null;
    /** Custom product */
    name?: string;
    reference?: string;
    price?: number;
    buy_price?: number;
    tax?: number;
}


export class M_ProductManager {
    name: string;
    line_id: number | undefined;
    quantity: number;
    buy_price: number;
    price: number;
    discount: number | null;
    tax: number;
    aboned: boolean = false;

    constructor(d: any) {
        this.line_id = d.line_id ? d.line_id : undefined;
        this.name = d.name;
        this.quantity = d.quantity;
        this.buy_price = d.buy_price;
        this.price = d.price ? d.price : 0;
        var discount = d.discount;
        if (typeof discount == "number") {
            this.discount = discount;
        }
        else {
            this.discount = null;
        }
        this.tax = d.tax;
    }

    setQuantity(q: number) {
        this.quantity = q;
    }

    setLineId(id: number) {
        this.line_id = id;
    }

    getTotal(extradfields = false, isInterno = false): number {
        if (this.quantity) {
            if (isInterno) {
                let isTime = this instanceof M_CustomProduct && this.type == "time";
                if (isTime) {
                    return this.quantity * this.price;
                }
                else {
                    return this.quantity * this.buy_price;
                }
            }
            let discount = this.getDiscountedTotal();
            if (discount != undefined) {
                return (this.quantity * discount);
            }
            return this.quantity * this.price;
        }
        return 0;
    }

    getTotalIVA() {
        if (this.quantity && this.tax) {
            let total = this.getTotal();
            return total * (this.tax / 100);
        }
        return 0;
    }

    private getDiscountedTotal(): undefined | number {
        if (this.discount == 0 || this.discount == null || this.discount == undefined) { return undefined }
        return Number(this.getDiscounted(this.discount));
    }

    /**
     * Get the discounted value on
     * @param val amount of discount
     * @param mutiply quantity of the product
     * @returns 
     */
    getDiscounted(val: number, mutiply?: number) {
        if (val >= 0 && val <= 100) {
            const discountPercentage = val / 100;
            const discountedPrice = this.price * (1 - discountPercentage);
            if (mutiply !== undefined) {
                const totalPrice = discountedPrice * mutiply;
                return totalPrice.castDecimals(2);
            } else {
                return discountedPrice.castDecimals(2);
            }
        }
        return undefined;
    }

    getInvoiceCallObject(modifyStock: boolean): invoiceCallProduct {
        throw Error("Implemented on childs")
    }
}