<ng-template #registerBtn>
    <button mat-flat-button color="warn" class="bc_p c_w register-button"
        (click)="routerS.goTo(v.register)">Registrarse</button>
</ng-template>

<mat-toolbar>
    <div class="df jcsb aic w100">
        <div class="play-demo">
            <button mat-button (click)="openVideo()"><mat-icon>play_arrow</mat-icon> Ver demo</button>
        </div>
        <img class="logoToolbar cp" src="./assets/img/logos/eina_logo_black.png">
        <div>
            <button mat-button (click)="routerS.goTo(v.login)" style="margin-right: 5px;">Iniciar sessión</button>
            <ng-container [ngTemplateOutlet]="registerBtn"></ng-container>
        </div>
    </div>

</mat-toolbar>

<div class="section bc_r0">
    <div class="grow">
        <div class="grow-text">
            <app-view-title class="grow-title">Crece con Eina</app-view-title>
            <p class="fsm">Somos la plataforma n.º1 en gestión de talleres. Pon en marcha, construye y haz crecer tu
                negocio.
                Suscríbete ahora.</p>
        </div>
        <div class="tac">
            <img class="landing-laptop" src="./assets/img/landing/eina-landing.png">
        </div>
        <ng-container [ngTemplateOutlet]="registerBtn"></ng-container>
        <p class="trial-14-days focus-in">¡Con <span class="days-underline">14 días</span> de prueba GRATUITOS!</p>

    </div>
</div>


<div class="section">
    <div>
        <p class="pre-title">SUBSCRIPCIÓN</p>
        <app-pricing [isLandingPage]="true"></app-pricing>
    </div>
</div>

<div class="section bc_b0">
    <div>
        <p class="pre-title">OPINIONES</p>
        <app-trusted-users [isLandingPage]="true"></app-trusted-users>
    </div>
</div>