<app-card [noStyle]="true" (click)="switchSelected($event, false)" [matTooltipPosition]="'above'" [matTooltip]="disabled ? disabledMessage : ''">
    <div  [ngClass]="{'selectedColor' : selected, 'cp' : true && !disabled, 'task-card' : true, 'disabled-card' : disabled}">
        <div class="group-container">
            <div>
                <app-card-subtitle [noMarginBottom]="true" [first]="true">
                    <mat-checkbox [disabled]="disabled" (click)="switchSelected($event, true)"></mat-checkbox>
                    <app-or-type-label [wrapOn]="1" class="small-label" [showText]="false" [tipo]="[g.type]"></app-or-type-label>
                    {{g.title}} 
                    <span class="fw500 c_t2 fss " money [val]="g.getTotalBreakdown(invoiceClient).total"></span>
                </app-card-subtitle>
            </div>
        </div>
    </div>
</app-card>