<div *ngIf="subS.showBanner">
    <div [class]="'banner ' + ('alert-color-' + this.color)" *ngIf="subS.status?.type == enumSub.TRIAL">
        <p *ngIf="subS.status?.remaining_days == 1; else remainingFree">¡ÚLTIMO DÍA DE PRUEBA!</p>
        <ng-template #remainingFree>
            <p *ngIf="subS.status?.remaining_days;">{{subS.status?.remaining_days}} DÍAS RESTANTES</p>
        </ng-template>
        <button  mat-flat-button (click)="routerS.goTo(v.subscription)">Subscribirse</button>
    </div>


    <div [class]="'banner ' + ('alert-color-' + this.color)" *ngIf="subS.status?.type == enumSub.BASIC">
        <p *ngIf="!subS.someTimeHasPro">PERÍODO DE PRUEBA FINALIZADO</p>
        <p *ngIf="subS.someTimeHasPro">SUBSCRIPCIÓN FINALIZADA</p>
        <button mat-flat-button (click)="routerS.goTo(v.subscription)">Subscribirse</button>
    </div>

    <div [class]="'banner ' + ('alert-color-' + this.color)" *ngIf="subS.status?.type == enumSub.PRO">
        <p *ngIf="subS.status?.remaining_days == 1; else remainingPro">¡ÚLTIMO DÍA DE SUBSCRIPCIÓN!</p>
        <ng-template #remainingPro>
            <p *ngIf="subS.status?.remaining_days;">{{subS.status?.remaining_days}} DÍAS RESTANTES</p>
        </ng-template>
        <button  mat-flat-button (click)="routerS.goTo(v.subscription)">Reactivar</button>
    </div>
    <div [class]="'bottom-banner ' + ('sub-alert-color-' + this.color)"></div>

</div>