import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FirmaComponent, FirmaData } from '@sinigual/angular-lib';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {
  resizeSignatureSize: number = 530;

  @Input() firmaBase64: string | undefined;
  @Output() onSign: EventEmitter<File> = new EventEmitter();
  firmaFile: File | undefined;
  constructor(private d: MatDialog, private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  openSignatureDialog(firmaData? : FirmaData) {
    let config = new MatDialogConfig();
    config = {
      width: "100%",
      maxWidth: this.getMaxWidth(),
      panelClass: 'full-screen-modal',
    };

    const dialogRef = this.d.open(FirmaComponent, { data: firmaData});

    dialogRef.afterClosed().subscribe(result => {
      if (result[0] != undefined) {
        this.firmaBase64 = result[0];
      }
      if (result[1] != undefined) {
        this.firmaFile = result[1];
        this.onSign.emit(result[1]);
      }
    });
  }

  getMaxWidth(): string {
    //En el telefono 100%, en ordenador 80% pantalla
    return screen.width < this.resizeSignatureSize ? "100%" : "500px";
  }

}
