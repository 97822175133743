<app-page-structure #ps [masterClass]="'issues'" pageTitle="INCIDENCIAS" [autocompleteID]="'issues'"
  searchLabel="Buscar incidencia" searchOnboardingClass="onboarding-search-issue" circleIcon="issues_add"
  circleIconClass="onboarding-create-issue" noDataCreateNew="Crear nueva inciencia" [data]="[]"
  [customPageContent]="content" [displayedHeader]=" ['ID','Título', 'Descripción']"
  [displayedColumns]="['technicalname', 'title', 'description']" [specialText]="[undefined, undefined, undefined]"
  [specialClass]="[undefined, undefined, undefined]" [lordIcon]="lordIcon" (onclickAddNew)="createTicket()"
  (onSearch)="searchTicket($event)">
  <ng-template #lordIcon>
    <script src="https://cdn.lordicon.com/fudrjiwc.js"></script>
    <lord-icon src="https://cdn.lordicon.com/bgcgwhfr.json" trigger="in"
      colors="primary:#121331,secondary:#faddd1,tertiary:#242424,quaternary:{{accent()}},quinary:{{accent()}},senary:#646e78"
      class="lord-small">
    </lord-icon>
  </ng-template>
  <ng-template #content>
    <div *ngIf="loaded; else loading">
      <app-cloud-ticket (onAddComment)="onAddComment($event)" *ngFor="let ticket of filteredTickets"
        [cloudTicket]="ticket"></app-cloud-ticket>
    </div>

    <div class="card-table-tr" *ngIf="tickets.length ==0 && loaded">
      <script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
      <lord-icon src="https://cdn.lordicon.com/bgcgwhfr.json" trigger="in"
        colors="primary:#121331,secondary:#faddd1,tertiary:#242424,quaternary:{{accent()}},quinary:{{accent()}},senary:#646e78"
        class="lord-big">
      </lord-icon>

      <div class="df fdc tac mb20">
        <p class="nmb fsl fw500" style="margin-top: 20px; margin-right: 10px;">¡OOOPS!</p>
        <p class="nmb fsm" style="margin-top: 20px;">Parece ser que aún no tienes incidencias creadas</p>
        <p (click)="createTicket()" class="nmb fsm cp c_r" style="margin-top: 20px;">
          Crear una nueva incidencia
        </p>
      </div>
    </div>

    <ng-template #loading>
      <div class="loading-skeleton">
        <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
        <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
        <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
        <app-skeleton [fullW]="true" [height]="150"></app-skeleton>
      </div>
    </ng-template>
  </ng-template>
</app-page-structure>