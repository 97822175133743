import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ClassSearcherComponent, ResponsiveService, SearchService } from '@sinigual/angular-lib';
import { ApiService } from '../../api/api.service';
import { endpoints } from '../../api/Enpoints';
import { M_Client } from '../../models/M_Client';
import { ClientformComponent } from '../clientform/clientform.component';
import { MASTER_CLIENT_MINIFIY } from '../../constants/masters';

@Component({
  selector: 'app-vehicle-owner-search-create',
  templateUrl: './vehicle-owner-search-create.component.html',
  styleUrls: ['./vehicle-owner-search-create.component.css']
})
export class VehicleOwnerSearchCreateComponent implements OnInit {
  
  client = MASTER_CLIENT_MINIFIY;  
  e = endpoints;

  cSelected: M_Client | undefined;
  cAll: M_Client[] | undefined;
  cSearched: M_Client[] | undefined;
  @ViewChild("ch1") ch1?: MatCheckbox;
  @ViewChild("ch2") ch2?: MatCheckbox;
  @ViewChild(ClientformComponent) clientForm!: ClientformComponent;
  @ViewChild(ClassSearcherComponent) searchForm!: ClassSearcherComponent<M_Client>;
  @Input() title?: string;
  /**En el caso de estar en la pantalla de linkar un vehiculo con un usuario nuevo */
  @Input() isLinkVehicle?: boolean;
  @Input() currentOwner_id?: number;
  @Input() contentLoaded?: boolean = true;

  constructor(private searchS: SearchService, private apiS: ApiService, public responsiveS : ResponsiveService) {}

  ngOnInit(): void {
    this.setUpClients();
  }

  setUpClients() {
    this.apiS.clientsForVehicles().then(resp => {
      let aux: M_Client[] = []
      for (let i = 0; i < resp.length; i++) {
        aux.push(new M_Client(resp[i]));
      }
      this.cAll = aux;
    })
  }


  searchCheckBox(e: MatCheckboxChange) {
    if (e.checked) {
      this.ch2!.checked = false;
    }
    else {
      if (!this.ch2?.checked) {
        this.ch2!.checked = true;
      }
    }
  }

  createCheckBox(e: MatCheckboxChange) {
    if (e.checked) {
      this.ch1!.checked = false;
    }
    else {
      if (!this.ch1?.checked) {
        this.ch1!.checked = true;
      }
    }
  }

  searchClient(searched: string) {
    let aux: M_Client[] = []
    for (let i = 0; i < this.cAll!.length; i++) {
      let c = this.cAll![i];
      if (this.searchS.match(searched, c.name)) {
        if (this.isLinkVehicle && this.currentOwner_id != c.client_id) {
          aux.push(c);
        }
        else if (!this.isLinkVehicle) {
          aux.push(c);
        }
      }
    }
    this.cSearched = aux;
  }

  selectClient(c: M_Client) {
    if (this.cSelected == undefined) {
      this.cSelected = c;
    }
    else if (this.cSelected == c) {
      this.cSelected = undefined;
    }
    else {
      this.cSelected = c;
    }
  }

  disableCurrentOwner(v: M_Client) {
    return v.client_id == this.currentOwner_id;
  }

  onSelect(val: any) {
    if (val) {
      this.cSelected = new M_Client(val);
    }
    else {
      this.cSelected = undefined;
    }
  }
}

