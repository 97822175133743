import { Injectable } from '@angular/core';
import { ResponsiveService } from '@sinigual/angular-lib';

export const WRAP_PRODUCTS_SCREEN_WIDTH = 1200;

@Injectable({
  providedIn: 'root'
})

export class ProductLineTableService {
  constructor(private responsiveS : ResponsiveService){}
  get phoneVersion(){
    return this.responsiveS.w <= WRAP_PRODUCTS_SCREEN_WIDTH;
  }
}
