import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { CustomTime } from '@sinigual/angular-lib';
import { AddTimeComponent } from 'src/app/core/components/add-time/add-time.component';
import { getQuantityFromTime, hourToTime } from 'src/app/core/constants/time-picker-constants';
import { M_CustomProduct } from 'src/app/core/models/M_CustomProduct';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { AddProductComponent } from '../../create-bill/add-product/add-product.component';
import { AddCommentComponent } from 'src/app/core/components/add-comment/add-comment.component';

@Component({
  selector: 'app-add-remove',
  templateUrl: './add-remove.component.html',
  styleUrls: ['./add-remove.component.css'],
  animations: [
    trigger('openCloseSide', [state(
      'open', style({ opacity: 1, pointerEvents: "all" }),),
    state(
      'closed', style({ opacity: 0, marginLeft: -30, pointerEvents: "none" }),
    ),
    transition('open => closed', [animate('0.2s')]),
    transition('closed => open', [animate('0.2s')]),
    ])
  ]
})
export class AddRemoveComponent implements OnInit {

  @ViewChild('tooltip') tooltip!: MatTooltip;

  @Output() onSave: EventEmitter<number> = new EventEmitter();
  @Output() onChange: EventEmitter<"add" | "remove" | "custom"> = new EventEmitter();
  @Output() onInputFocusOut: EventEmitter<any> = new EventEmitter();
  @Output() onModification: EventEmitter<number> = new EventEmitter();

  @Input() showSaveIcon = true;
  @Input() initValue: number = 0;
  @Input() form!: UntypedFormGroup;
  @Input() formCName!: string;
  @Input() label?: string;
  @Input() isTime?: boolean = false;
  @Input() minValue: number | undefined = 0;
  @Input() showLabel?: boolean = true;
  @Input() hint?: string | number | undefined;
  @Input() hintclass?: string | undefined;
  @Input() canModify: boolean = true;
  @Input() apparence: "outline" | "fill" = 'outline';
  @Input() required: boolean = true;
  @Input() addRemoveOutside: boolean = true;
  @Input() formClass: string = '';

  showSave = false;
  tooltipVisible = false;

  constructor(private chdRef: ChangeDetectorRef, private fb: UntypedFormBuilder, private d: MatDialog, public subS: SubscriptionService, @Optional() private adc: AddProductComponent) { }

  ngOnInit(): void {
    if (!this.form && !this.formCName) {
      this.form = this.fb.group({
        stock: [0, this.required ? [] : []]
      });
      this.formCName = "stock";
    }

    if (!this.canModify) {
      this.form.get(this.formCName)?.disable();
    }
  }

  ngAfterViewInit() {
    this.formControl.patchValue(this.initValue)
  }

  add(event: Event | undefined) {
    if (event) { event.stopPropagation(); }
    this.formControl.patchValue(this.formControl.value + 1);
    this.showSave = true;
    this.onChange.emit("add")
    this.onModification.emit(this.getValue())
  }

  remove(event: Event | undefined) {
    if (event) { event.stopPropagation(); }
    if (!this.disableMinus) {
      this.formControl.patchValue(this.formControl.value - 1);
      this.showSave = true;
      this.onChange.emit("remove")
      this.onModification.emit(this.getValue())
    }
  }

  get disableMinus() {
    let val = this.formControl.value;
    return val != undefined && this.minValue != undefined && val <= this.minValue;
  }

  getValue() {
    return this.formControl.value;
  }

  save() {
    if (this.showSave) {
      this.showSave = false;
      this.onSave.emit(this.getValue())
    }
  }

  setNewTotal(e: any) {
    this.formControl.patchValue(Number(e.target.value));
    this.showSave = true;
  }

  setValue(v: number) {
    this.formControl.patchValue(v);
    this.showSave = true;
  }


  emitFocusEvent() {
    if (!this.canModify) {
      let introducedVal = this.getValue();
      if (this.minValue != undefined) {
        introducedVal = introducedVal < this.minValue ? this.minValue : introducedVal;
      }
      this.formControl.patchValue(introducedVal);
      this.onInputFocusOut.emit()
    }
  }

  detectLimit(e: any) {
    if (e.target.value > 999) {
      this.tooltipVisible = true;
      this.chdRef.detectChanges();
      this.tooltip.show();
      let val: string = e.target.value.toString().slice(0, -1);
      this.formControl.patchValue(Number(val));
    }
    else {
      this.onChange.emit("custom");
      this.tooltipVisible = false;
    }

    if (Number(e.target.value)) {
      this.showSave = true;
    }
    this.onModification.emit(this.getValue())
  }

  /*
  preventMinusStock(apc : AddProductComponent){
    let stck = apc.getProductStock();
    if (stck < 0) {
      this.formControl.patchValue(this.formControl.value - Math.abs(stck));
    }
  }*/

  get formControl() {
    return this.form.get(this.formCName)!
  }

  hourToTime(): string {
    return hourToTime(this.getValue());
  }

  timeToHour(time: string) {
    this.setValue(getQuantityFromTime(new CustomTime(time)));
  }

  onChangeTime(e: any) {
    if (e.target.value) {
      this.timeToHour(e.target.value)
      this.onModification.emit(this.getValue())
    }
  }

  openTimeDialog() {

    if (!this.canModify) { return; }

    let ct = new CustomTime(this.hourToTime());
    let currentPriceHour = undefined;
    let productTime;
    if (this.adc && this.adc.p && this.adc.p.price && this.adc.p instanceof M_CustomProduct) {
      currentPriceHour = this.adc.p?.price;
      productTime = this.adc.p;
    }
  
    this.d.open(AddTimeComponent, { data: { ct: ct, showPriceHour: true, priceHour: currentPriceHour, p: this.adc.p}, autoFocus: true }).afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {

        /** Sync frontent with the new price */
        if (this.adc && this.adc.p && this.adc.p instanceof M_CustomProduct) {
          this.adc.p.copyCoreAttributes(res);
          this.setValue(res.quantity);
        }

        if (this.adc?.taskComponent) {
          this.adc.taskComponent.saveTask()
        }

      }
    })
  }
  openCommentDialog() {

    if (!this.canModify) { return; }
    let currentComment;
    if (this.adc && this.adc.p && this.adc.p instanceof M_CustomProduct) {
      currentComment = this.adc.p?.comment;
    }

    this.d.open(AddCommentComponent, { data: { ct: this.adc.p,comment: currentComment }, autoFocus: false }).afterClosed().subscribe(res => {
      if (res instanceof M_CustomProduct) {

        /** Sync frontent with the new price */
        if (this.adc && this.adc.p && this.adc.p instanceof M_CustomProduct) {
          this.adc.p.copyCoreAttributes(res);
          this.setValue(res.quantity);
        }

        if (this.adc?.taskComponent) {
          this.adc.taskComponent.saveTask()
        }

      }
    })
  }

}
