import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularLibModule, CoreConfiguration, CustomErrorHandler } from '@sinigual/angular-lib';
import { environment } from 'src/environments/environment';
import { endpoints } from './core/api/Enpoints';
import { AppRoutingModule, ViewPath } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FAB_DEFAULT_OPTIONS, MAT_FAB_DEFAULT_OPTIONS_FACTORY, MatButtonModule } from '@angular/material/button';
import { EntryPointComponent } from './views/entry-point/entry-point.component';
import { ToolbarComponent } from './core/components/toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { BigMamaComponent } from './core/components/global-searcher/big-mama.component';
import { MenuComponent } from './core/components/menu/menu.component';
import { AppContentsComponent } from './core/components/app-contents/app-contents.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MenuItemComponent } from './core/components/menu/menu-item/menu-item.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ClientsComponent } from './views/clients/clients.component';
import { VehiclesComponent } from './views/vehicles/vehicles.component';
import { ProductsComponent } from './views/products/products.component';
import { BillsComponent } from './views/bills/bills.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TestComponent } from './core/components/test/test.component';
import { ClientDetailsComponent } from './views/client-details/client-details.component';
import { AddRemoveComponent } from './views/products/add-remove/add-remove.component';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { VehicleDetailsComponent } from './views/vehicle-details/vehicle-details.component';
import { LinkVehicleComponent } from './views/link-vehicle/link-vehicle.component';
import { VehicleContainerComponent } from './views/link-vehicle/vehicle-container/vehicle-container.component';
import { CreateVehicleComponent } from './views/create-vehicle/create-vehicle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateClientComponent } from './views/create-client/create-client.component';
import { CreateProductComponent } from './views/create-product/create-product.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateBillComponent } from './views/create-bill/create-bill.component';
import { AddProductComponent } from './views/create-bill/add-product/add-product.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DatePipe, registerLocaleData } from '@angular/common';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MAT_TAB, MAT_TABS_CONFIG, MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';

import es from '@angular/common/locales/es';
import { ProfileComponent } from './views/profile/profile.component';
import { DocumentationColorPickerComponent } from './views/company/documentation-config/documentation-color-picker/documentation-color-picker.component';
import { MatDividerModule } from '@angular/material/divider';
import { DocumentationContentComponent } from './views/company/documentation-config/documentation-content/documentation-content.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ClientformComponent } from './core/components/clientform/clientform.component';
import { VehicleformComponent } from './core/components/vehicleform/vehicleform.component';
import { UserformComponent } from './core/components/userform/userform.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { VehicleOwnerSearchCreateComponent } from './core/components/vehicle-owner-search-create/vehicle-owner-search-create.component';
import { CardRowContentComponent } from './core/components/card-row-content/card-row-content.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CreateOr } from './views/or-create-edit/create-or/create-or.component';
import { DragComponent } from './core/components/drag/drag.component';
import { DragGroupComponent } from './core/components/drag/drag-group/drag-group.component';
import { DragTaskComponent } from './core/components/drag/drag-task/drag-task.component';
import { MatSelectModule } from '@angular/material/select';
import { CreateCustomProductComponent } from './views/create-bill/add-product/create-custom-product/create-custom-product.component';
import { ImageToggleComponent } from './core/components/image-toggle/image-toggle.component';
import { ImgToggleItemComponent } from './core/components/image-toggle/img-toggle-item/img-toggle-item.component';
import { ClientVehicleRowComponent } from './core/components/vehicle-row/item-row.component';
import { IvachangerComponent } from './views/create-bill/ivachanger/ivachanger.component';
import { CustomDateAdapter } from './core/adapter/CustomAdapter';
import { CreateRecoverPasswordComponent } from './views/create-recover-password/create-recover-password.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DeleteDialogComponent } from './core/components/delete-dialog/delete-dialog.component';
import { DeleteButtonComponent } from './core/components/delete-button/delete-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClientInfoComponent } from './core/components/class-searcher/client-info/client-info.component';
import { CifComponent } from './core/components/cif/cif.component';
import { AlreadyExistsService } from './core/services/already-exists.service';
import { ProductinvoiceComponent } from './views/product-details/productinvoice/productinvoice.component';
import { ProductmoveComponent } from './views/product-details/productmove/productmove.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { VehicleInfoComponent } from './core/components/class-searcher/vehicle-info/vehicle-info.component';
import { TermsAndConditionsComponent } from './views/register/terms-and-conditions/terms-and-conditions.component';
import { OrComponent } from './views/or/or.component';
import { BudgetComponent } from './views/budget/budget.component';
import { CreateEditBudgetComponent } from './views/create-budget/create-edit-budget.component';
import { EditOrComponent } from './views/or-create-edit/edit-or/edit-or.component';
import { SignatureComponent } from './core/components/signature/signature.component';
import { ActionCreateEditParentComponent } from './views/or-create-edit/or-create-edit-parent/or-create-edit-parent.component';
import { InvoiceDialogComponent } from './views/or-create-edit/edit-or/invoice-dialog/invoice-dialog.component';
import { GroupCardInvoiceGroupComponent } from './views/or-create-edit/edit-or/invoice-dialog/group-card-invoice-group/group-card-invoice-group.component';
import { MatChipsModule } from '@angular/material/chips';
import { ModifyObservationsComponent } from './views/client-details/modify-observations/modify-observations.component';
import { UserEmailAlwaysComponent } from './core/components/user-email-always/user-email-always.component';
import { ClientConsentsComponent } from './core/components/client-consents/client-consents.component';
import { DiscountDialogComponent } from './views/create-bill/add-product/discount-dialog/discount-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { OrFormComponent } from './core/components/or-form/or-form.component';
import { MatTreeModule } from '@angular/material/tree';
import { BeforeDeleteGroupComponent } from './core/components/drag/before-delete-group/before-delete-group.component';
import { DataUploadComponent } from './core/components/data-upload/data-upload.component';
import { DataUploadItemComponent } from './core/components/data-upload-item/data-upload-item.component';
import { DataUploadDialogComponent } from './core/components/data-upload-item/data-upload-dialog/data-upload-dialog.component';
import { TabbedCommentsComponent } from './views/or-create-edit/create-or/tabbed-comments/tabbed-comments.component';
import { TabbedCommentsPreviewComponent } from './views/or-create-edit/create-or/tabbed-comments/tabbed-comments-preview/tabbed-comments-preview.component';
import { ActionHistoryComponent } from './core/components/or-history/action-history.component';
import { ConfirmExitDialogComponent } from './core/components/confirm-exit-dialog/confirm-exit-dialog.component';
import { LanguageSelectorComponent } from './core/components/language-selector/language-selector.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PluralPipe } from './plural.pipe';
import { WelcomeComponent } from './core/components/welcome/welcome.component';
import { DragTutorialComponent } from './views/carga-taller/drag-tutorial/drag-tutorial.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { SubscriptionDaysLeftComponent } from './core/components/subscription-days-left/subscription-days-left.component';
import { SubscriptionOptionComponent } from './views/subscription/subscription-option/subscription-option.component';
import { TrustedUserComponent } from './views/subscription/trusted-user/trusted-user.component';
import { TextWithLinkComponent } from './core/components/text-with-link/text-with-link.component';
import { ContactUsTextComponent } from './core/components/contact-us-text/contact-us-text.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { TrialbuttonDirective } from './core/directives/trialbutton.directive';
import { FooterComponent } from './core/components/footer/footer.component';
import { IssuesViewComponent } from './views/issues-view/issues-view.component';
import { TryProDialogComponent } from './core/components/try-pro-dialog/try-pro-dialog.component';
import { CargaTallerParentComponent } from './views/carga-taller-parent/carga-taller-parent.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DotComponent } from './views/bills/dot/dot.component';
import { AbonoInvoiceComponent } from './views/abono-invoice/abono-invoice.component';
import { SelectAllOfTypeComponent } from './views/or-create-edit/edit-or/invoice-dialog/select-all-of-type/select-all-of-type.component';
import { VehicleRowContainerComponent } from './core/components/vehicle-row-container/vehicle-row-container.component';
import { ClientVehicleInDialogComponent } from './core/components/client-vehicle-in-dialog/client-vehicle-in-dialog.component';
import { ClientVechileInfoDialogComponent } from './core/components/client-vehicle-in-dialog/client-vechile-info-dialog/client-vechile-info-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { ClientSearcherHeaderComponent } from './core/components/client-searcher-header/client-searcher-header.component';
import { AddTimeComponent } from './core/components/add-time/add-time.component';
import { PricingComponent } from './views/subscription/pricing/pricing.component';
import { TrustedUsersComponent } from './views/subscription/trusted-users/trusted-users.component';
import { OrTypeSelectorComponent } from './core/components/or-type-selector/or-type-selector.component';
import { LastActivityComponent } from './views/dashboard/last-activity/last-activity.component';
import { WhatsNewComponent } from './views/dashboard/whats-new/whats-new.component';
import { DashboardOrTableComponent } from './views/dashboard/dashboard-or-table/dashboard-or-table.component';
import { CustomLegendComponent } from './views/dashboard/custom-legend/custom-legend.component';
import { DialogWhatsNewsComponent } from './views/dashboard/whats-new/dialog-whats-news/dialog-whats-news.component';
import { DeactivateGuard } from './core/guards/deactivate-guard-';
import { DialogCheckoutControlComponent } from './views/bills/dialog-checkout-control/dialog-checkout-control.component';
import { CancelationAndReturnPolicyComponent } from './core/components/cancelation-and-return-policy/cancelation-and-return-policy.component';
import { PayCometResultComponent } from './views/subscription/pay-comet-result/pay-comet-result.component';
import { CancelSubscriptionComponent } from './views/subscription/cancel-subscription/cancel-subscription.component';
import { PayCometInvoicesComponent } from './views/subscription/pay-comet-invoices/pay-comet-invoices.component';
import { SubscriptionStatusComponent } from './views/subscription/subscription-status/subscription-status.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TemplateFieldsFormComponent } from './core/components/template-fields-form/template-fields-form.component';
import { FloatDirective } from './core/directives/float.directive';
import { IntegerDirective } from './core/directives/integer.directive';
import { CompanyComponent } from './views/company/company.component';
import { CompanyGeneralInfoComponent } from './views/company/company-general-info/company-general-info.component';
import { DocumentationConfigComponent } from './views/company/documentation-config/documentation-config.component';
import { SmtpComponent } from './views/company/smtp/smtp.component';
import { UsersComponent } from './views/company/users/users.component';
import { CreateUserComponent } from './views/company/users/create-user/create-user.component';
import { TemplateFieldsDialog } from './core/components/template-fields-dialog/template-fields-dialog.component';
import { PreviewDialog } from './core/components/preview-dialog/preview-dialog-component';
import { PdfComponent } from './core/components/pdf/pdf.component';
import { MatInputModule } from '@angular/material/input';
import { DialogAdvancePayComponent } from './views/client-details/dialog-advance-pay/dialog-advance-pay.component';
import { AdvancesArrayComponent } from './core/components/advances-array/advances-array.component';
import { ShowAdvanceClientComponent } from './core/components/show-advance-client/show-advance-client.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DialogHistoryAdvancesComponent } from './core/components/advances-array/dialog-history-advances/dialog-history-advances.component';
import { StandardDialogComponent } from './core/components/standard-dialog/standard-dialog.component';
import { InvoiceFlotaOrDialogComponent } from './views/client-details/invoice-float-or-dialog/invoice-flota-or-dialog.component';
import { DecimalInput } from './core/directives/decimal-input';
import { PayAllInvoiceDialogComponent } from './views/bills/pay-check-control/pay-all-invoice-dialog.component';
import { DialogCashTotalReturnComponent } from './views/bills/dialog-cash-total-return/dialog-cash-total-return.component';
import { ChangeDeliveryOrDialogComponent } from './views/carga-taller-parent/change-delivery-or-dialog/change-delivery-or-dialog.component';
import { TrasnferActionComponent } from './core/components/trasnfer-action/trasnfer-action.component';
import { CreateInvoiceDialogComponent } from './views/create-bill/create-invoice-dialog/create-invoice-dialog.component';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { ProTipsButtonComponent } from './core/components/pro-tips/pro-tips-button/pro-tips-button.component';
import { ProTipsDialogComponent } from './core/components/pro-tips/pro-tips-dialog/pro-tips-dialog.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { InvoiceMovementsComponent } from './core/components/invoice-movements/invoice-movements.component';
import { InvoicePayementFormComponent } from './core/components/invoice-payement-form/invoice-payement-form.component';
import { InvoicedSiniestroDialogComponent } from './views/or-create-edit/edit-or/invoice-dialog/invoiced-siniestro-dialog/invoiced-siniestro-dialog.component';
import { FrontendDesignComponent } from './views/frontend-design/frontend-design.component';
import { DeleteCompanyComponent } from './views/profile/delete-company/delete-company.component';
import { ActionTotalsComponent } from './core/components/action-totals/action-totals.component';
import { RaGroupsComponent } from './views/or-create-edit/create-or/ra-groups/ra-groups.component';
import { InvoicePayFormComponent } from './core/components/invoice-pay-form/invoice-pay-form.component';
import { InvoiceTotalsComponent } from './core/components/invoice-totals/invoice-totals.component';
import { ProductsLineHeaderComponent } from './core/components/products-line-header/products-line-header.component';
import { ProductLineTitleComponent } from './views/create-bill/add-product/product-line-title/product-line-title.component';
import { MissingCompanyInfoComponent } from './core/components/missing-company-info/missing-company-info.component';
import { ModelNotFoundComponent } from './core/components/model-not-found/model-not-found.component';
import { errorCodes } from './core/constants/errorCodes';
import { DocumentationPdfMenuComponent } from './core/components/documentation-pdf-menu/documentation-pdf-menu.component';
import { AdvancesClientDetailsDialogComponent } from './views/client-details/advances-client-details-dialog/advances-client-details-dialog.component';
import { LastInterventionOrComponent } from './core/components/last-intervention-or/last-intervention-or.component';
import { ClientDetailsVehicleTableComponent } from './views/client-details/client-details-vehicle-table/client-details-vehicle-table.component';
import { PendingPaymentDialogComponent } from './views/client-details/pending-payment-dialog/pending-payment-dialog.component';
import { WifiCheckerComponent } from './core/components/wifi-checker/wifi-checker.component';
import { NoteComponent } from './core/components/note/note.component';
import { OrNotesAndImagesComponent } from './views/or-create-edit/or-notes-and-images/or-notes-and-images.component';
import { LandingComponent } from './landing/landing.component';
import { DemoVideoComponent } from './landing/demo-video/demo-video.component';
import { InvoiceDayCheckerDirective } from './core/directives/invoice-day-checker.directive';
import { AddCommentComponent } from './core/components/add-comment/add-comment.component';
import { DialogConfirmPlateComponent } from './core/components/vehicleform/dialog-confirm-plate/dialog-confirm-plate.component';
import { DicountMonthlyComponent } from './core/components/toolbar/dicount-monthly/dicount-monthly.component';
import { SendMailCodePromotionalComponent } from './core/components/toolbar/send-mail-code-promotional/send-mail-code-promotional.component';


export const MY_STRING = new InjectionToken<string[]>('myString');

let coreConfiguration = new CoreConfiguration(
  environment,
  ViewPath,
  endpoints,
  ViewPath.landing,
  ViewPath.pageNotFound,
  ViewPath.dashboard,
  false,
  "weberp",
  endpoints.reportError,
  undefined,
  errorCodes
)
registerLocaleData(es);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    EntryPointComponent,
    ToolbarComponent,
    BigMamaComponent,
    MenuComponent,
    AppContentsComponent,
    MenuItemComponent,
    DashboardComponent,
    ClientsComponent,
    VehiclesComponent,
    ProductsComponent,
    BillsComponent,
    CompanyComponent,
    PageNotFoundComponent,
    TestComponent,
    ClientDetailsComponent,
    AddRemoveComponent,
    ProductDetailsComponent,
    VehicleDetailsComponent,
    LinkVehicleComponent,
    VehicleContainerComponent,
    CreateVehicleComponent,
    CreateClientComponent,
    CreateProductComponent,
    CreateBillComponent,
    AddProductComponent,
    LoginComponent,
    RegisterComponent,
    UsersComponent,
    CompanyGeneralInfoComponent,
    ProfileComponent,
    DocumentationConfigComponent,
    DocumentationColorPickerComponent,
    DocumentationContentComponent,
    ClientformComponent,
    VehicleformComponent,
    UserformComponent,
    CreateUserComponent,
    VehicleOwnerSearchCreateComponent,
    CardRowContentComponent,
    CreateOr,
    DragComponent,
    DragGroupComponent,
    DragTaskComponent,
    CreateCustomProductComponent,
    ImageToggleComponent,
    ImgToggleItemComponent,
    ClientVehicleRowComponent,
    IvachangerComponent,
    PreviewDialog,
    CreateRecoverPasswordComponent,
    DeleteDialogComponent,
    DeleteButtonComponent,
    ClientInfoComponent,
    CifComponent,
    AlreadyExistsService,
    ProductinvoiceComponent,
    ProductmoveComponent,
    VehicleInfoComponent,
    SmtpComponent,
    TermsAndConditionsComponent,
    OrComponent,
    BudgetComponent,
    CreateEditBudgetComponent,
    EditOrComponent,
    SignatureComponent,
    ActionCreateEditParentComponent,
    InvoiceDialogComponent,
    GroupCardInvoiceGroupComponent,
    ModifyObservationsComponent,
    UserEmailAlwaysComponent,
    ClientConsentsComponent,
    DiscountDialogComponent,
    OrFormComponent,
    BeforeDeleteGroupComponent,
    DataUploadComponent,
    DataUploadItemComponent,
    DataUploadDialogComponent,
    TabbedCommentsComponent,
    TabbedCommentsPreviewComponent,
    ActionHistoryComponent,
    ConfirmExitDialogComponent,
    LanguageSelectorComponent,
    PluralPipe,
    WelcomeComponent,
    DragTutorialComponent,
    SubscriptionComponent,
    SubscriptionDaysLeftComponent,
    SubscriptionOptionComponent,
    TrustedUserComponent,
    TextWithLinkComponent,
    ContactUsTextComponent,
    TrialbuttonDirective,
    FooterComponent,
    IssuesViewComponent,
    TryProDialogComponent,
    CargaTallerParentComponent,
    DotComponent,
    AbonoInvoiceComponent,
    SelectAllOfTypeComponent,
    VehicleRowContainerComponent,
    ClientVehicleInDialogComponent,
    ClientVechileInfoDialogComponent,
    ClientSearcherHeaderComponent,
    AddTimeComponent,
    PricingComponent,
    TrustedUsersComponent,
    OrTypeSelectorComponent,
    LastActivityComponent,
    WhatsNewComponent,
    DashboardOrTableComponent,
    CustomLegendComponent,
    DialogWhatsNewsComponent,
    DialogCheckoutControlComponent,
    CancelationAndReturnPolicyComponent,
    PayCometResultComponent,
    CancelSubscriptionComponent,
    PayCometInvoicesComponent,
    SubscriptionStatusComponent,
    TemplateFieldsFormComponent,
    FloatDirective,
    IntegerDirective,
    TemplateFieldsDialog,
    PdfComponent,
    StandardDialogComponent,
    InvoiceFlotaOrDialogComponent,
    DialogAdvancePayComponent,
    AdvancesArrayComponent,
    ShowAdvanceClientComponent,
    DialogHistoryAdvancesComponent,
    StandardDialogComponent,
    DecimalInput,
    PayAllInvoiceDialogComponent,
    DialogCashTotalReturnComponent,
    ChangeDeliveryOrDialogComponent,
    TrasnferActionComponent,
    CreateInvoiceDialogComponent,
    ProTipsButtonComponent,
    ProTipsDialogComponent,
    InvoiceMovementsComponent,
    InvoicePayementFormComponent,
    InvoicedSiniestroDialogComponent,
    FrontendDesignComponent,
    DeleteCompanyComponent,
    ActionTotalsComponent,
    RaGroupsComponent,
    InvoicePayFormComponent,
    InvoiceTotalsComponent,
    ProductsLineHeaderComponent,
    ProductLineTitleComponent,
    MissingCompanyInfoComponent,
    ModelNotFoundComponent,
    DocumentationPdfMenuComponent,
    AdvancesClientDetailsDialogComponent,
    LastInterventionOrComponent,
    ClientDetailsVehicleTableComponent,
    PendingPaymentDialogComponent,
    WifiCheckerComponent,
    NoteComponent,
    OrNotesAndImagesComponent,
    LandingComponent,
    DemoVideoComponent,
    InvoiceDayCheckerDirective,
    AddCommentComponent,
    DialogConfirmPlateComponent,
    DicountMonthlyComponent,
    SendMailCodePromotionalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatBadgeModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatRippleModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTabsModule,
    MatMenuModule,
    MatDividerModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    DragDropModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonToggleModule,
    NgApexchartsModule,
    MatChipsModule,
    MatSliderModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatTreeModule,
    PdfViewerModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsId),
    NgxGoogleAnalyticsRouterModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSelectCountryModule.forRoot('es'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularLibModule.forRoot(coreConfiguration) /*--> La incluimos al proyecto*/
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', color: 'primary', subscriptSizing: 'dynamic' } },
    { provide: MAT_FAB_DEFAULT_OPTIONS, useValue: { color: 'p3' } },
    { provide: MAT_FAB_DEFAULT_OPTIONS_FACTORY, useValue: { color: 'p3' } },
    { provide: MatDialogRef, useValue: {} },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MY_STRING, useValue: '' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    MatDatepickerModule,
    DeactivateGuard,
    DatePipe
  ],
  bootstrap: [EntryPointComponent]
})

export class AppModule {
  constructor() { }
} 
