import { Component, OnInit, ViewChild } from '@angular/core';
import { PageStructureComponent, RouterService, getWarnColor } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { filter } from 'src/app/core/custom-classes/MasterFilter';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';
import { ParamsService } from 'src/app/core/services/params.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css']
})
export class VehiclesComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_Vehicle>;
  v = ViewPath;
  vehicles: M_Vehicle[] = [];
  f = filter;
  warn = getWarnColor
  constructor(apiS: ApiService, public routerS: RouterService, public params: ParamsService, public subS: SubscriptionService) {
    apiS.getVehicles().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
      }
    });
  }

  ngOnInit(): void {
  }

  vehicleType(v: M_Vehicle) {
    return v.typeName;
  }

  preIcon(v: M_Vehicle) {
    return v.icon;
  }

  getLicenseDate(v: M_Vehicle) {
    return v.license_date?.noHourFormat;
  }

  getOwner(v: M_Vehicle) {
    return v.client?.getName();
  }
  handleClick(event: MouseEvent,type?:string) {
   
    if (event.ctrlKey || event.metaKey) { // Verifica si la tecla Control o Command está presionada
      event.preventDefault(); // Prevenir el comportamiento por defecto
      this.openInNewTab(type);
    } else {
      this.navigate(type);
    }
  }
  handleContextMenu(event: MouseEvent,type?:string) {
    event.preventDefault(); // Prevenir el menú contextual por defecto

    this.openInNewTab(type);
  }

  openInNewTab(type?:string) {
    if(type ==="OR"){
      const url = "creteor?vehicle="+this.ps.selected!.vehicle_id;
      window.open(url, '_blank');
    }
    else if(type ==="V"){
      const url = "vehicledetails?vehicle="+this.ps.selected!.vehicle_id;
      window.open(url, '_blank');
    }
    
  }
  navigate(type?:string){
    if(type ==="OR"){
      this.params.go(this.v.createOr, this.ps.selected!.vehicle_id, 'vehicle');
    }else{
      this.params.go(this.v.vehicleDetails, this.ps.selected!.vehicle_id);
    }
  }
}

