<ng-template #customSkeleton>
  <div class="custom-loader">
    <app-skeleton [width]="180" [height]="50"></app-skeleton>
  </div>
</ng-template>

<!-- If this div is removed, remove also this css : 
  .whats-new {
      margin-top: 33px;
  }
-->
<div class="container">
  <div class="whats_new">
    <app-whats-new *ngIf="data" [news]="data.whats_new"></app-whats-new>
  </div>
  <div class="pending">
    <div class="open_budgets">
      <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined"
        class="top-card normal-padding-top bc_b0" (click)="goWorkload()">
        <app-card-subtitle [first]="true">Plazas disponibles</app-card-subtitle>
        <div class="numText">
          <p class="bigNum" *ngIf="data">{{data.companyPlacesDis < 0 ? 0 : data.companyPlacesDis}}</p>
        </div>
      </app-card>
    </div>
    <div class="or_to_invoice">
      <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined"
        class="top-card normal-padding-top bc_b0" (click)="goClosedOr()">
        <app-card-subtitle [first]="true">A facturar</app-card-subtitle>
        <div class="numText">
          <p class="bigNum"><span money [val]="data?.total_ORToInvoice"></span></p>
        </div>
      </app-card>
    </div>
    <div class="or_to_collect">
      <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined"
        class="top-card normal-padding-top bc_b0" (click)="goCollectPending()">
        <app-card-subtitle [first]="true">A cobrar</app-card-subtitle>
        <div class="numText">
          <p class="bigNum"><span money [val]="data?.total_ToCollect"></span></p>
        </div>
      </app-card>
    </div>
    <div class="open_new_or">
      <app-card [customSkeleton]="customSkeleton" [contentLoaded]="data != undefined"
        class="top-card normal-padding-top bc_r0" (click)="goCreateOR()">
        <app-card-subtitle [first]="true">Acción rápida</app-card-subtitle>
        <div class="numText">
          <p class="bigNum">Abrir OR</p>
        </div>
      </app-card>
    </div>
  </div>

  <div class="line-chart">
    <app-card [contentLoaded]="data != undefined">
      <app-card-title [first]="true" [noMarginBottom]="true">Previsión de taller</app-card-title>
      <apx-chart *ngIf="previsionChart" [series]="previsionChart.series!" [chart]="previsionChart.chart!"
        [xaxis]="previsionChart.xaxis!" [stroke]="previsionChart.stroke!" [colors]="previsionChart.colors!"
        [dataLabels]="previsionChart.dataLabels!" [legend]="previsionChart.legend!" [markers]="previsionChart.markers!"
        [grid]="previsionChart.grid!" [fill]="previsionChart.fill!" [yaxis]="previsionChart.yaxis!"
        [title]="previsionChart.title!"></apx-chart>
    </app-card>
  </div>

  <div class="workload" [ngClass]="{'loaded': data != undefined}">
    <app-card [contentLoaded]="data != undefined">
      <app-card-title>Carga de taller</app-card-title>
      <div class="two-apex-charts">
        <!-- Carga taller -->
        <div class="workload-parent-div">
          <apx-chart *ngIf="semiCircleChart" [series]="semiCircleChart.series!" [chart]="semiCircleChart.chart!"
            [labels]="semiCircleChart.labels!" [grid]="semiCircleChart.grid!"
            [plotOptions]="semiCircleChart.plotOptions!" [responsive]="semiCircleChart.responsive!"
            [colors]="semiCircleChart.colors!" [legend]="semiCircleChart.legend!" [stroke]="semiCircleChart.stroke!"
            [dataLabels]="semiCircleChart.dataLabels!"></apx-chart>
        </div>
      </div>
    </app-card>
  </div>

  <div class="latest-activity" [ngClass]="{'loaded': data != undefined}">
    <app-card [contentLoaded]="data != undefined">
      <app-card-title>Última actividad</app-card-title>
      <app-last-activity *ngIf="data" [allActivity]="data.latestActivity"></app-last-activity>
    </app-card>
  </div>
  <div class="or-table">
    <app-card [contentLoaded]="data != undefined">
      <app-card-title>OR para hoy</app-card-title>
      <app-dashboard-or-table *ngIf="data" [ors]="data.priorityOr"></app-dashboard-or-table>
    </app-card>
  </div>
</div>

<app-pro-tips-button></app-pro-tips-button>