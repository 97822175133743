<div class="preview-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="relative">
        <mat-icon class="drag-indicator">drag_indicator</mat-icon>
    </div>
<p mat-dialog-title>Control de caja</p>
    
<mat-dialog-content>

    <app-day-filter (onFilterChanges)="refreshInvoices($event)"  [filter]="filterDay"></app-day-filter>

    <div class="container">
        <app-card [noStyle]="false" class="card-p onboarding-checkout-control-filter">

            <table class="advantage-table">
                <tr>
                    <th></th>
                    <th></th>

                </tr>
                <tbody>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df"><mat-icon class="vam" [svgIcon]="'bizum'"></mat-icon><span
                                        class="container_text">Bizum</span></p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><span money val="{{totalBizum}}"></span></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df"><mat-icon class="vam">credit_card</mat-icon><span
                                        class="container_text">Tarjeta</span></p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><span money val="{{totalCard}}"></span></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df"><mat-icon class="vam">sync_alt</mat-icon><span
                                        class="container_text">Transf.</span></p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><span money val="{{totalTranferencia}}"></span></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df"><mat-icon class="vam">payments</mat-icon><span
                                        class="container_text">Efectivo</span></p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><span class="money-value" money val="{{totalEfectivo}}"></span></td>
                    </tr>
                    <!-- <tr>
                        <td>
                            <div class="lined">
                                <mat-divider></mat-divider>
                                <p class="c_t2 t_pay_prslz">Personalizados</p>
                            </div>
                        </td>
                    </tr> -->

                    <tr *ngFor="let a of this.data.type_payments; let last = last" [class]="'activity activity-0'">
                        <td>
                            <div class="lined">
                                <p class="df"><mat-icon class="vam">label</mat-icon><span
                                        class="container_text">{{a.payment}}</span></p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><span class="money-value" money [val]="getTotalmovement(a)"></span></td>
                    </tr>
                    <!-- <tr [class]="'activity activity-0'">
                        <td>
                            <div class="lined">
                                <p class="df"><mat-icon class="vam">label</mat-icon><span
                                        class="container_text">Otros</span></p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><span class="money-value" money val="{{totalDeletedTypePayment}}"></span></td>
                    </tr> -->
                </tbody>
            </table>
            <mat-divider></mat-divider>
            <div class="container-total">
                <app-card-subtitle class="total_title">Total:</app-card-subtitle>
                <div class="total-money">
                    <app-card-subtitle><span money val="{{totalPayType}}"></span></app-card-subtitle>
                </div>
            </div>

        </app-card>
    </div>
</mat-dialog-content>
</div>

<ng-template #noData>

</ng-template>