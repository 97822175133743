import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LayoutSendEmailComponent, PageStructureComponent, RouterService, SessionService, SpaceRemoverService } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { UserformComponent } from 'src/app/core/components/userform/userform.component';
import { M_User } from 'src/app/core/models/M_User';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { CreateUserComponent } from './create-user/create-user.component';
import { filter } from 'src/app/core/custom-classes/MasterFilter';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild(PageStructureComponent) ps!: PageStructureComponent<M_User>;
  @ViewChild(UserformComponent, { static: true }) form!: UserformComponent;
  @ViewChild(LayoutSendEmailComponent) sendEmail?: LayoutSendEmailComponent;
  isEdit = false;
  v = ViewPath;
  f = filter;
  disableCircle = false;
  loading = false;

  constructor(public routerS: RouterService, private apiS: ApiService, private dialog: MatDialog,
    public sessionS: SessionService, private chdRef: ChangeDetectorRef, public subS: SubscriptionService,
    private spaceRemover: SpaceRemoverService) {
    this.getAllusers();

    this.subS.onChangeSubscription.subscribe(v => {
      this.refreshCircle();
      this.chdRef.detectChanges();
    })
  }

  ngOnInit(): void { }

  getAllusers() {
    this.loading = true;
    this.apiS.users().then(resp => {
      if (this.ps) {
        this.ps.initTable(resp);
        this.refreshCircle();
        this.loading = false;
      }
    });
  }

  refreshCircle() {
    if (this.subS.status && this.ps.loaded_) {
      var users: number = this.ps.data.length;
      this.disableCircle = users >= this.subS.status.users || !this.subS.hasProPermissions;
      this.chdRef.detectChanges();
    }
    else {
      this.disableCircle = true;
    }
  }

  get circleTooltip() {
    if (this.subS.status) {
      if (this.subS.hasProPermissions){
        return 'Máximo ' + this.subS.status.users + ' usuarios por empresa'
      }
      else {
        return "Se necesita el plan PRO para poder crear usuarios"
      }
    }
    return undefined;
  }

  onChange() {
    if (this.sendEmail) {
      this.sendEmail.sended = false;
      this.sendEmail.disabled = false;
      this.chdRef.detectChanges();
    }
  }

  setEditMode() {
    this.isEdit = !this.isEdit;
  }

  editUser(u: UserformComponent, usr: M_User) {
    let newUser = new M_User(u.form.value);
    newUser.id = usr.id;
    this.spaceRemover.check(usr.name);
    this.apiS.editUser(newUser).then(_res => {
      this.getAllusers();
      this.ps.unselct();
    })
  }

  openDialog() {
    if (this.subS.isBasic) {
      this.subS.openTryProDialog("Crear usuarios");
    }
    else {
      let dref = this.dialog.open(CreateUserComponent);
      dref.afterClosed().subscribe(res => {
        if (res == true) {
          this.getAllusers();
        }
      })
    }
  }

  onDeleteUser(u: M_User | any) {
    this.ps.select(u);
    this.chdRef.detectChanges();
    this.ps.allDataOnInit.removeElement(u);
    this.ps.initTable(this.ps.allDataOnInit);
    this.refreshCircle();
  }

  async resendConfirm() {
    this.ps.selected!.confirm_sent = true;
    return this.apiS.reSendConfirmation(this.ps.selected!.id);
  }

}
