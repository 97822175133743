import { Component, Input, OnInit } from '@angular/core';
import { feature } from 'src/app/core/features-controller/FeaturesController';
import { SubscriptionService } from 'src/app/core/services/subscription.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  @Input() isLandingPage = false;
  features = feature;
  constructor(public subS : SubscriptionService) { }
  ngOnInit(): void {}

}
