import { Injectable } from '@angular/core';
import { CompanyInterface, CustomDate, M_BaseUser, ORInterface, WorkloadData, WorkloadInterface } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from '../api/api.service';
import { endpoints } from '../api/Enpoints';
import { AppointmentInterface } from '@sinigual/angular-lib/lib/interface/AppointmentInterface';
import { M_Action } from '../models/M_Action';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {
  workloadData : WorkloadData;
  constructor(private apiS : ApiService) { 

    var configuration : WorkloadInterface = {
      defaultView : "or",
      switch: false,
      general: {
        users: this.apiS.users.bind(this.apiS),
        company:this.apiS.company.bind(this.apiS)
      },
      or: {
        schedules: this.apiS.schedules.bind(this.apiS),
        updateSchedule: endpoints.updateSchedule,
        reorderAction: endpoints.reorderActions,
        assignAction: endpoints.assignAction,
        userPermission : true,
        views: {
          editOr:   ViewPath.editOr,
          createOr: undefined
        },
        draggable : {
          day : true,
          week : true,
          month : true,
        }
      },
      appointments: {
        appointments: function (from: CustomDate, to: CustomDate, showLoading: boolean): Promise<AppointmentInterface[]> {
          return new Promise<any[]>(resolve => {
            resolve([])
          })
        },
        updateAppointment: undefined,
        userPermission : true,
        views: {
          createAppointment: undefined,
          editAppointment: undefined
        },
        draggable : {
          day : true,
          week : true,
          month : true,
        }
      }
    }

    this.workloadData = new WorkloadData(configuration)
  }

  get wd(){
    return this.workloadData;
  }
}
