import { ChangeDetectorRef, Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmDialogService, CustomFile, FileUploadComponent, FormService, RouterService, SessionService, SnackService, toFormData } from '@sinigual/angular-lib';
import { ViewPath } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/core/api/api.service';
import { M_Company } from 'src/app/core/models/M_Company';
import { UserServiceService } from '../../profile/user-service.service';
import { CompanyComponent } from '../company.component';
import { R_Zip } from 'src/app/core/models/R_Zip';
import { MatSelectChange } from '@angular/material/select';
import { DeleteCompanyComponent } from '../../profile/delete-company/delete-company.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-company-general-info',
  templateUrl: './company-general-info.component.html',
  styleUrls: ['./company-general-info.component.css']
})
export class CompanyGeneralInfoComponent {
  @ViewChild(FileUploadComponent) upload!: FileUploadComponent;
  @Output() changeTab: EventEmitter<number> = new EventEmitter();

  v = ViewPath;
  c: M_Company | undefined;
  info = false;
  selectedPayment: any;
  personalizadoSelected = false;
  form: UntypedFormGroup;
  imgSaved = true;
  loadingZip = false;
  obj_zip: R_Zip | undefined;
  finalsearh = "";
  initialCCAA: number | undefined;
  arrayZips: R_Zip[] | undefined = [];
  constructor(@Inject(CompanyComponent) public parent: CompanyComponent,
    private formBuilder: UntypedFormBuilder, private fs: FormService,
    private snack: SnackService, public routerS: RouterService,
    private chdRef: ChangeDetectorRef,
    private sessionS : SessionService,
    private confirmD: ConfirmDialogService,
     private d: MatDialog,
    private apiS: ApiService, public userS: UserServiceService) {
    this.form = this.formBuilder.group({
      cif: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      default_vehicle: [1],
      address: ['', [Validators.required]],
      address2: [''],
      zip: ['', [Validators.required]],
      city: ['', [Validators.required]],
      ccaaObject: ['', [Validators.required]],
      ccaa: ['', [Validators.required]],
      province: ['', [Validators.required]],
      places: ['', [Validators.required]],
      price_hour: ['', [Validators.required]],
      iva: [0],
    });

    this.form.get('zip')?.valueChanges.subscribe(v => {
      this.onZipChange(v);
      this.chdRef.detectChanges();
    })

  }

  initTab(c: M_Company) {
    this.c = c;
    this.initialCCAA = c.ccaa;
    if (c.logo) {
      this.upload.setImageFromDatabase(c.logo);
    }
    this.form.patchValue(c)
    // this.form.patchValue({default_vehicle: c.default_vehicle}); 
    this.form.get('province')?.disable();
    this.form.get('ccaaObject')?.disable();
  }

  onFileupload(c: CustomFile) {
    this.c!.logo = c.src;
    this.imgSaved = false;
  }

  makeCall() {
    if (this.fs.isOk(this.form)) {

      if (!this.form.get("address2")?.value) {
        this.form.get("address2")?.patchValue("");
      }

      let formData = toFormData(this.form.getRawValue());
      var f = this.upload.getUploadedFile();
      if (f) {
        formData.set("logo", f)
      }
      this.apiS.updateCompany(formData).then(res => {
        this.c!.template_fields = res;
        this.parent.documentationTab.fillTemplateFields(this.c!.template_fields);
        this.userS.updateCompanyTax(this.form.get('iva')!.value)
        this.showCCAAChangeDialog(formData);
        this.userS.setMissingCompany(false);
        this.imgSaved = true;
        this.userS.updateDefaultVehicle(this.form.get('default_vehicle')!.value)
        this.snack.show("Datos de empresa guardados con éxito")
      })
    }
  }
  vehicleChange(event: MatSelectChange) {
    const selectedValue = event.value;
    this.form.patchValue({ default_vehicle: event.value });
  }
  showCCAAChangeDialog(formData: FormData) {
    let formCCAA = formData.get('ccaa')?.toString().getNumber();
    if (formCCAA && formCCAA != this.initialCCAA) {
      this.confirmD.show({
        title: "Cambio de comunidad autónoma",
        body: "Te recomendamos echar un vistazo a los campos adicionales de la pestaña 'Documentos'.",
        confirmTxt: "¡Vamos allá!",
        showCancel: true,
        type: "info"
      }).afterClosed().subscribe(res => {
        if (res) {
          this.changeTab.emit(1);
        }
      })
    }
    this.initialCCAA = formCCAA;
  }


  hasInfo(): boolean {
    if (this.c != undefined) {
      return this.c.hasInfo();
    }
    return false;
  }

  goLayout() {
    if (this.hasInfo()) {
      this.routerS.goTo(this.v.layout);
    }
  }

  onZipChange(searched: string) {

    this.finalsearh = searched;
    let zipControl = this.form.get('zip')!;

    if (!searched) {
      this.markZipAsInvalid(zipControl);
    }
    else if (searched && !this.loadingZip) {
      this.loadingZip = true;
      this.apiS.getLocationByZip(searched).then(res => {

        this.arrayZips = res;
        if (this.arrayZips?.length == 0) {
          this.markZipAsInvalid(zipControl);
        }

        if (this.arrayZips && this.arrayZips.length === 1) {
          // Si solo hay un registro, selecciona automáticamente ese registro
          let selectedZip = this.arrayZips[0];

          // Realiza las operaciones necesarias para marcar el ZIP como válido
          this.markZipAsValid(zipControl, selectedZip);

        } else {
          let selectedZip = this.arrayZips?.find(zip => zip.id == this.c?.city);
          if (selectedZip) {

            this.markZipAsValid(zipControl, selectedZip)
          }
        }

        this.loadingZip = false;

        /** Make another call if necessary */
        if (searched != this.finalsearh) {
          this.onZipChange(this.finalsearh);
        }
      });
    }
  }

  markZipAsValid(zip: AbstractControl, model: R_Zip) {
    zip.setErrors(null);
    this.form.patchValue({ 'city': model.id })
    this.form.patchValue({ 'province': model.provincia })
    this.form.patchValue({ 'ccaaObject': model.ccaa.ccaaname })
    this.form.patchValue({ 'ccaa': model.ccaa.id })
    this.chdRef.detectChanges();
  }
  markZipAsInvalid(zip: AbstractControl) {
    zip.markAsDirty();
    zip.markAsTouched();
    zip.setErrors({ notfound: true });
    this.form.patchValue({ 'city': undefined })
    this.form.patchValue({ 'province': undefined })
    this.form.patchValue({ 'ccaaObject': undefined })
    this.form.patchValue({ 'ccaa': undefined })
  }

  stateChange(event: MatSelectChange) {
    const selectedValue = event.value;

    this.selectedPayment = this.arrayZips?.find(
      (zip) => zip.id === selectedValue
    );
    let zipControl = this.form.get('zip')!;

    if (selectedValue) {

      this.markZipAsValid(zipControl, this.selectedPayment)
    }

    if (selectedValue === 'personalizado') {
      this.personalizadoSelected = true;
      this.form.get('city')?.setValidators([Validators.required]);
    }
    else {
      if (this.personalizadoSelected) {
        this.personalizadoSelected = false;
        this.form.get('city')?.clearValidators();
      }
    }

    this.form.get('city')?.updateValueAndValidity();
  }
  decimalFilter(event: any) {
    const reg = /^-?\d*([,.]\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }
  deleteCompany() {
    let title_ = "Eliminar empresa";
    let body_ = '¿Está seguro de que desea eliminar la empresa?';
    this.confirmD.show({ title: title_, body: body_, type: "danger" }).afterClosed().subscribe(confirmResult => {
      if (confirmResult) {
        this.d.open(DeleteCompanyComponent, { panelClass: "core-error-dialog" }).afterClosed().subscribe(deleteResult => {
          if (deleteResult == true) {
            this.apiS.deleteCompany().then(res => {
              if (res) {
                this.sessionS.logOut();
              } else {
                this.confirmD.showError("Error al eliminar la empresa", "Ponte en contacto con nosotros para solventar este problema");
              }
            });
          }
        });
      }
    });
  }
}
