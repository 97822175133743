<div [formGroup]="form">
    <div class="df fdc">

        <!-- Custom product/ add remnove input-->
        <div class="df fdr aib jcc add-remove-form-field" *ngIf="!isTime">
            <div *ngIf="addRemoveOutside" [ngTemplateOutlet]="minus"></div>
            <mat-form-field [appearance]="apparence" [class]="'add-remove-field' + ' ' + formClass"
                [ngClass]="{'insidebuttons' : !addRemoveOutside, 'disabled' : !canModify}">
                <div *ngIf="!addRemoveOutside" matPrefix [ngTemplateOutlet]="minus"></div>
                <mat-label *ngIf="showLabel">{{label ? label : 'Existencias'}}</mat-label>
                <input matInput [formControlName]="formCName" #tooltip="matTooltip" [matTooltip]="'Valor máx. : 999'"
                    [matTooltipDisabled]="!tooltipVisible" (focusout)="emitFocusEvent()" (change)="setNewTotal($event)"
                    (input)="detectLimit($event)" type="number" class="input-add-remove" [disabled]="!canModify">
                <mat-hint *ngIf="hint" [ngClass]="hintclass ? hintclass : ''">{{hint}}</mat-hint>
                <div *ngIf="!addRemoveOutside" matSuffix [ngTemplateOutlet]="plus"></div>
            </mat-form-field>
            <div *ngIf="addRemoveOutside" [ngTemplateOutlet]="plus"></div>
            <div *ngIf="showSaveIcon && formControl.value != initValue" class="attention-animation"
                [@openCloseSide]="showSave == true? 'open' : 'closed'"
                [ngClass]="showSave ? 'pointer-event' : 'no-pointer-event'"
                [matTooltip]="subS.isBasic ? 'Se requiere el plan pro' : ''">
                <button type="button" mat-icon-button (click)="save()" color="primary" [disabled]="subS.isBasic">
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </div>

        <!-- Time input-->
        <div class="df fdr aib jcc time-form-field" *ngIf="isTime">
            <mat-form-field [appearance]="apparence" [class]="'add-remove-field' + ' ' + formClass"
                [ngClass]="{'disabled' : !canModify}" (click)="openTimeDialog()">
                <mat-label *ngIf="showLabel">{{label ? label : 'Existencias'}}</mat-label>

                <!-- Input #1 : Hidden input, always contain  the hour value. Ex : 2.5 (hours)-->
                <input matInput [formControlName]="formCName" readonly style="display: none;">
                <button mat-icon-button matSuffix [disabled]="!canModify"><mat-icon>schedule</mat-icon></button>

                <!-- Input #2, visible input. Take the input #1 value an show it as hour time. Ex : Get 2.5h (hours) and shows 02:30-->
                <input matInput [disabled]="true" (change)="onChangeTime($event)" type="string" [value]="hourToTime()">

            </mat-form-field>
        </div>

    </div>
</div>


<ng-template #minus>
    <button [ngClass]="{'vh': !canModify}" type="button" #b mat-icon-button (click)="remove($event)"
        [disabled]="disableMinus || !canModify" [matTooltip]="'Quitar (-1)'">
        <mat-icon [ngClass]="{'disabledMinus' : b.disabled}">remove</mat-icon>
    </button>
</ng-template>


<ng-template #plus>
    <button [ngClass]="{'vh': !canModify}" #b type="button" mat-icon-button [attr.matSuffix]="addRemoveOutside"
        (click)="add($event)" [disabled]="!canModify" [matTooltip]="'Añadir (+1)'">
        <mat-icon>add</mat-icon>
    </button>
</ng-template>