import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Vehicle } from 'src/app/core/models/M_Vehicle';

@Component({
  selector: 'app-client-vechile-info-dialog',
  templateUrl: './client-vechile-info-dialog.component.html',
  styleUrls: ['./client-vechile-info-dialog.component.css']
})
export class ClientVechileInfoDialogComponent  {
  constructor(@Inject(MAT_DIALOG_DATA) public data: [M_Client|undefined, M_Vehicle|undefined]) {}
}
