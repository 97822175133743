import { Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/api/api.service';
import { ShowAdvanceClientComponent } from 'src/app/core/components/show-advance-client/show-advance-client.component';
import { M_Client } from 'src/app/core/models/M_Client';
import { M_Invoice } from 'src/app/core/models/M_Invoice';
import { M_LastMovements } from 'src/app/core/models/M_LastMovement';
import { M_TypePayment } from 'src/app/core/models/M_TypePayment';

/**
 * Pay a bill. You can apply advance payments.
 * If the user apply some advance, the dialog closes with the advance data.
 */

@Component({
  selector: 'app-pay-all-invoice-dialog',
  templateUrl: './pay-all-invoice-dialog.component.html',
  styleUrls: ['./pay-all-invoice-dialog.component.css']
})
export class PayAllInvoiceDialogComponent {
  
  checked = false;
  dOpened = false;
  selectedPayment: any;
  @ViewChildren(ShowAdvanceClientComponent)
  lsmv?: QueryList<ShowAdvanceClientComponent>;
  selectedValue: string = '';
  pending: boolean = false;
  mushroomChecked: boolean = false;
  showForm: boolean = false;
  selectedDate: Date | null = null;

  constructor(private apiS: ApiService, private fb: UntypedFormBuilder, public dRef: MatDialogRef<PayAllInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      invoice: M_Invoice;
      last_movements: M_LastMovements[];
      pending: boolean;
      typePayment: M_TypePayment[];
      client: M_Client;
    },@Inject(MAT_DIALOG_DATA) public data_ : {invoice:M_Invoice,isDialog:boolean}) {

      if(this.data_){
        this.data.invoice = this.data_.invoice;
      }
      console.log(this.data.invoice);
  }
}
