import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { or_types } from '@sinigual/angular-lib';

@Component({
  selector: 'app-select-all-of-type',
  templateUrl: './select-all-of-type.component.html',
  styleUrls: ['./select-all-of-type.component.css']
})
export class SelectAllOfTypeComponent implements OnInit {
  @Input() types: or_types[] = [];
  @Output() onSelect: EventEmitter<or_types> = new EventEmitter();
  constructor() { }
  ngOnInit(): void { }
}
