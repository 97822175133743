<div class="main-content">
    <app-card [contentLoaded]="subS.status != undefined || isLandingPage">
        <div *ngIf="subS.status || isLandingPage">
            <app-card-title>Precios</app-card-title>
            <app-subscription-option [isLandingpage]="isLandingPage" [price]="subS.status != undefined ? subS.status.typedetails.first_user : 30"
                [secondPrice]="subS.status != undefined ? subS.status.typedetails.first_user : 30" [label]="'Plan mensual'" [icon]="'star'"
                [color]="'var(--y)'" [granularity]="'mes'">
                <lord-icon src="https://cdn.lordicon.com/surjmvno.json" trigger="loop" delay="9999"
                    class="lord-small"></lord-icon>
            </app-subscription-option>

            <table class="advantage-table">
                <tr>
                    <th></th>
                    <th></th>

                </tr>
                <tbody>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Creación y edición de clientes, vehiculos y articulos</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr *ngIf="features.dataUpload">
                        <td>
                            <div class="lined">
                                <p class="df">Entrada de almacén</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Carga de taller para gestionar las órdenes de reparación</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Consulta histórico de presupuestos, ORs y facturas</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Consulta de clientes, vechiculos y artículos</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Gestión documental de clientes y vehiculos</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Facturar recambios y órdenes de reparación</p>
                                <div class="line"></div>
                            </div>
                        </td>

                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                    <tr>
                        <td>
                            <div class="lined">
                                <p class="df">Copias de seguridad de toda la información</p>
                                <div class="line"></div>
                            </div>
                        </td>
                        <td><mat-icon class="c_g">check_circle</mat-icon></td>
                    </tr>
                </tbody>
            </table>

            <div class="questions" *ngIf="!isLandingPage">
                <p class="nmb tar">¿Alguna pregunta?</p>
                <app-contact-us-text></app-contact-us-text>
            </div>
        </div>
    </app-card>
</div>