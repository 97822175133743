<div class="df jcsb">
    <p mat-dialog-title>{{isSomeOrAllInterno ? 'Tramitar': 'Facturar'}}</p>
    <app-documentation-pdf-menu class="mt15 mr15" [action]="data.action"></app-documentation-pdf-menu>
</div>

<mat-dialog-content>

    <!-- HIDDEN --> <app-client-vehicle-in-dialog #avid style="display: none;"></app-client-vehicle-in-dialog>
    <p mat-dialog-subtitle style="margin-top: 0px;">
        {{isSomeOrAllInterno ? '¿Qué intervenciones quieres tramitar?' : '¿Qué intervenciones quieres facturar?'}}
        <mat-icon class="ml5 vam c_b" [matTooltip]="isSomeOrAllInterno ? 'Sólo puedes tramitar aquellas intervenciones que estén cerradas' : 
            'Sólo puedes facturar aquellas intervenciones que estén cerradas'">
            info </mat-icon>
    </p>
    <app-select-all-of-type [types]="getTypes()" (onSelect)="selectOnlyTypeOf($event)"></app-select-all-of-type>
    <div *ngFor="let g of data.groups">
        <app-group-card-invoice-group #groups (OnChange)="refreshFranchise()" #gcig [disabled]="isDisabled(gcig)"
            [preSelected]="isPreSelectedGroup(g)" [g]="g"
            [invoiceClient]="screenCurrentClient"></app-group-card-invoice-group>
    </div>

    <app-action-totals style="margin-bottom: 1rem; justify-content: end;" [groups]="getSelectedGroups"
        [invoiceClient]="screenCurrentClient"></app-action-totals>

    <form class="form">
        <div class="df fww w100 mt20" style="gap: 10px" [ngClass]="{'dn' : isSomeOrAllInterno}">
            <div class="df fdc w100on750">
                <p mat-dialog-subtitle>Fecha de facturación</p>
                <mat-form-field appearance="outline" class="w100">
                    <mat-label>Fecha</mat-label>
                    <input matInput placeholder="dd/mm/aaaa" [matDatepicker]="picker_1" (click)="picker_1.open()"
                        [formControl]="date" appInvoiceDayChecker [fc]="date" #IDC="appInvoiceDayChecker">

                    <!--TODO: Its possible to make a component of this? -->
                    <mat-hint *ngIf="IDC.minDate" [ngClass]="{'c_r' : IDC.error}">{{IDC.error ? IDC.error :
                        IDC.hint}}</mat-hint>
                    <mat-spinner *ngIf="!IDC.loaded" matPrefix color="warn" [diameter]="18"
                        style="float: right; margin-left: 8px"></mat-spinner>
                    <!--<mat-error #error>{{IDC.error}}</mat-error>!-->
                    <!-- TODO end-->

                    <mat-datepicker-toggle matSuffix [for]="picker_1"></mat-datepicker-toggle>

                    <mat-datepicker #picker_1></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="df fdc w100on750">
                <p mat-dialog-subtitle>¿A quién quieres facturarlos?</p>
                <app-class-searcher #cs class="onboarding-invoice-select-client no-wrapper w100"
                    [selectedOnLoad]="data.action.defaultInvoiceClient?.client_id" [width100]="true"
                    [masterClass]="client" searchPlaceHolder="Buscar cliente" [specialRow]="'Nuevo cliente / empresa'"
                    [extendedInfo]="false" (onSpecialRow)="goCreateClient()">
                </app-class-searcher>
            </div>
            <div *ngIf="requiredExpedient" class="df fdc w100on750">
                <p mat-dialog-subtitle>¿Nº de expediente?</p>
                <mat-form-field appearance="outline" class="w100">
                    <mat-label>Nº Expediente</mat-label>
                    <input [formControl]="expedient" matInput placeholder="Nº Expediente">
                </mat-form-field>
            </div>
        </div>
    </form>

    <ng-container *ngIf="isSomeOrAllSiniestro">
        <p mat-dialog-subtitle>Franquicia
            <mat-icon class="ml5 vam c_b"
                [matTooltip]="'Al facturar intervención/es de tipo Siniestro, puedes establecer la franquicia'">
                info </mat-icon>
        </p>
        <mat-form-field class="w100" appearance="outline">
            <mat-label>Franquicia</mat-label>
            <input matInput type="number" placeholder="Franquicia" [formControl]="franchise" DecimalInput>
            <mat-error>
                El valor no puede ser mayor que la suma de las intervenciones seleccionadas.
            </mat-error>
        </mat-form-field>
    </ng-container>


    <!-- COMMENTS -->
    <p mat-dialog-subtitle>¿Algún comentario?
        <mat-icon class="ml5 vam c_b"
            [matTooltip]="
            isSomeOrAllInterno? 'Los comentarios aparecerán en el documento': 'Los comentarios aparecerán en la factura'">
            info</mat-icon>
    </p>
    <mat-form-field class="w100" appearance="outline">
        <mat-label>Comentario</mat-label>
        <input matInput placeholder="Introducir comentarios" [formControl]="comments">
    </mat-form-field>

</mat-dialog-content>
<div *ngIf="!expedient.value && this.data.action.defaultInvoiceClient?.cli_is_invoice_e" class="df jcc c_r p10">Estás a punto de facturar sin número de expediente.</div>

<div mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="warn" [disabled]="!isDataOk" (click)="invoiceGroups()">
        {{isSomeOrAllInterno? 'Tramitar': 'Facturar'}}
        ({{selectedGroups}})</button>
</div>