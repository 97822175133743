import { M_Client } from '../models/M_Client';
import { M_Product } from '../models/M_Product';
import { M_CustomProduct } from '../models/M_CustomProduct';


/** Diffenet IVA of the current M_Invoice or M_GroupTask
 * iva : The current tax (0, 4%, 10%, 21%)
 * subtotal : The sum of all iva rows
 * total : The amount of money to put on the invoice totals. subtotal * (iva / 100)
 */
type iva_rows = { iva: number, subtotal: number, total: number }[];

/**
 * This function constructs a M_TotalBreakdown class with the products and client of the parameters.
 * @param products Products of the M_Invoice or the M_GrouTask.
 * @param client The client to be invoiced to.
 * @returns A M_TotalsBreakdown object.
 */
export function calculateTotalsBreakdown(products: (M_Product | M_CustomProduct)[], client: M_Client | undefined, isInterno : boolean = false): M_TotalsBreakdown {
  return new M_TotalsBreakdown(products, client, isInterno);
}

/** This class calculates a M_Invoice or M_GrouTask totals breakdow */
export class M_TotalsBreakdown {

  /** The sum of all product / customproduct line totals */
  subtotal = 0;
  /** Result of apply the discount on the subtotal (extracting the IVA 0 rows)*/
  client_dis_subtotal = 0;
  /** The total of substracting the discount. Ignores the iva rows with 0 IVA.*/
  client_dis_substraction = 0;
  /** True if the client is 'tax_free'. If tax free, no iva rows are calculated */
  client_excent_iva = false;
  /** Array of 'iva_rows'. Contains each IVA, the IVA subtotal and de IVA total */
  iva_rows: iva_rows = [];
  /** Is the sum of all the totals of all the iva_rows */
  totalIva = 0;
  /** The total to invoice */
  total = 0;
  /** Is from a 'Interno' GT? */
  isInterno = false;

  constructor(products: (M_Product | M_CustomProduct)[], client: M_Client | undefined, isInterno : boolean = false) {
    this.calculate(products, client, isInterno);
  }

  /** Recalculates the subtotal, iva rows and total of a invoice */
  calculate(products: (M_Product | M_CustomProduct)[], client: M_Client | undefined,  isInterno : boolean = false) {

    this.isInterno = isInterno;

    this.client_excent_iva = client ? client.tax_free : false;

    this.subtotal = this.getSubTotal(products);

    this.iva_rows = this.getIva(products);

    this.totalIva = 0;
    this.iva_rows.forEach(iva_row => this.totalIva += iva_row.total);

    this.total = this.getTotal(this.subtotal)

    this.iva_rows.sort((a, b) => a.iva - b.iva);

  }

  /** The subtotal of the invoice (NO IVA) */
  getSubTotal(products: (M_Product | M_CustomProduct)[]) {
    let total = 0;
    let includeExtraField = !this.isInterno;
    products.forEach(product => {
      total += Number(product.getTotal(includeExtraField, this.isInterno))
    })
    return Number(total.toFixed(2));
  }

  get exento_iva() {
    let iv: iva_rows[number] | undefined = this.iva_rows.find(i => i.iva == 0);
    return iv?.total || 0;
  }

  calculateClientDiscount(client_discount: number | undefined) {
    if (!client_discount) { return 0 }
    let subtotalExentIVA = this.subtotal - this.exento_iva;
    this.client_dis_substraction = subtotalExentIVA * (client_discount / 100);
    return subtotalExentIVA - this.client_dis_substraction;
  }

  /** Generates all the iva rows of the inoice. For each different IVA on the products, it generates a new iva row. */
  getIva(products: (M_Product | M_CustomProduct)[]) {
    this.iva_rows = [];

    //Clien is tax_free ? Nothing to do here
    if (this.client_excent_iva || this.isInterno) { return this.iva_rows; }

    let ivaExtraFields = 0;

    products.forEach((product) => {
      var tax = product.tax != undefined ? product.tax : 0;
      var ptotal = Number(product.getTotal(false));
      this.addOrEditIVARow(tax, ptotal);

      if (product instanceof M_Product) {
        ivaExtraFields += product.getExtraFieldTotalPrice;
      }
    })

    /** Add extra fields iva if necessary */
    if (ivaExtraFields) { this.addOrEditIVARow(21, ivaExtraFields) }

    return this.iva_rows;
  }

  addOrEditIVARow(tax: number, ptotal: number) {
    /** Create iva row */
    if (this.needIncludeOnIVARows(tax)) {
      var total = this.calculateTotalFromIvaRow(ptotal, tax);
      this.iva_rows.push({ iva: tax, subtotal: ptotal, total: total })
    }

    /** Incrementing iva row  total */
    else {
      this.iva_rows.forEach(r => {
        if (r.iva == tax) {
          r.subtotal += ptotal;
          r.total = this.calculateTotalFromIvaRow(r.subtotal, r.iva)
        }
      })
    }
  }

  /** Calculate the total of a iva row */
  private calculateTotalFromIvaRow(subtotal: number, iva: number) {
    if (iva == 0) { return 0; }
    return Number((subtotal * (iva / 100)).toFixed(2));
  }

  /** Returns if the iva need a new entry on the iva_rows array */
  private needIncludeOnIVARows(iva: number) {
    return this.iva_rows.every(r => r.iva != iva)
  }

  /** Sum the total of all the iva rows
   * @returns Total of iva rows
   */
  private getTotalIvaRows() {
    var totalivarows = 0;
    this.iva_rows.forEach(r => totalivarows += r.total);
    return totalivarows;
  }

  /** Get the total of the invoice. Discount is applied */
  private getTotal(subtotal: number) {

    if (this.client_dis_subtotal && !this.isInterno) {
      return Number((this.client_dis_subtotal + this.getTotalIvaRows()).toFixed(2));
    }
    else {
      return Number((subtotal + this.getTotalIvaRows()).toFixed(2));
    }
  }

}
