import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { CancelSubscriptionComponent } from '../cancel-subscription/cancel-subscription.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/api/api.service';
import { ConfirmDialogService } from '@sinigual/angular-lib';
import { EnumBannerColor } from 'src/app/core/components/subscription-days-left/subscription-days-left.component';

@Component({
  selector: 'app-subscription-status',
  templateUrl: './subscription-status.component.html',
  styleUrls: ['./subscription-status.component.css', '../../../core/components/subscription-days-left/subscription-days-left.component.css']
})
export class SubscriptionStatusComponent {
  constructor(public subS: SubscriptionService, private d: MatDialog, private apiS: ApiService,
    private confirmD: ConfirmDialogService) { }

  get color() {
    return this.subS.status?.active ? EnumBannerColor.GREEN : EnumBannerColor.BLUE;
  }

  cancelSubscription() {
    this.d.open(CancelSubscriptionComponent, { panelClass: "core-error-dialog" }).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.cancelPayCometSubscription().then(res => {
          if (res) {
            this.subS.setSubscriptionStatus(res); // Back and front subscriptioon status sync !!
          }
          else {
            this.subS.genericSubscriptionError("Error al cancelar subscripción", "Ponte en contacto con nosotros para solventar este problema");
          }
        })
      }
    })
  }

  reactivateSubscription() {
    this.confirmD.show({
      title: "Reactivar subscripción",
      body: "¿Quieres reactivar tu subscripción?",
      type: 'info',
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.reActivatePayCometSubscription().then(res => {
          if (res) {
            this.subS.setSubscriptionStatus(res); // Back and front subscriptioon status sync !!
            this.confirmD.show({
              title: "¡Subscripción reactivada!",
              body: "Gracias por confiar en nostoros.",
              type: 'confirm',
              showCancel: false,
              confirmTxt: "OK"
            })
          }
          else {
            this.subS.genericSubscriptionError("Error al reactivar subscripción", "Ponte en contacto con nosotros para solventar este problema");
          }
        })
      }
    })
  }

  changeCard() {
    this.confirmD.show({
      title: "Cambiar tarjeta",
      body: "¿Quieres cambiar la tarjeta asociada a esta cuenta?",
      type: 'info',
    }).afterClosed().subscribe(res => {
      if (res == true) {
        this.apiS.payCometSubscribe().then(res => {
          if (res) {
            window.open(res, "_self");
          }
          else {
            this.subS.genericSubscriptionError("Error al intentar cambiar la tarjeta", "Ponte en contacto con nosotros para solventar este problema");
          }
        })
      }
    })
  }
}
