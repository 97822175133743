import { CustomDate, match, SearchFiltrable } from "@sinigual/angular-lib"
import { M_CustomProduct } from "./M_CustomProduct";
import { invoiceCallProduct, M_ProductManager } from "./M_ProductManager";
import { I_ClassSearcher } from "../interfaces/I_ClassSearcher";
import { endpoints } from "../api/Enpoints";
import { ProductCategory } from "../enums/ProductCategory";
import { getSignusById, SIGAUS_PRICE_LITER, Signus, SIGNUS_N1, SIGNUS_N4 } from "../constants/constants";


export class M_Product_Move implements SearchFiltrable {
    id: number;
    invoice_id: number;
    buy_id: number;
    product_id: number;
    user_id: number;
    quantity: number
    type: "manual" | "import";
    details: "rem" | "add";
    created_at: CustomDate;
    updated_at: CustomDate | undefined;

    constructor(d: any) {
        this.id = d.id;
        this.invoice_id = d.invoice_id;
        this.buy_id = d.buy_id;
        this.user_id = d.user_id;
        this.product_id = d.product_id;
        this.quantity = d.quantity;
        this.type = d.type;
        this.details = d.details;
        this.created_at = new CustomDate(d.created_at);
        this.updated_at = d.updated_at ? new CustomDate(d.updated_at) : undefined;
    }

    isAdd() {
        return this.details == 'add';
    }

    isRemove() {
        return this.details == 'rem';
    }

    isImport() {
        return this.type == 'import';
    }

    defaultSearchFilter(text: string): boolean {
        const invoiceIdString = this.invoice_id !== null && this.invoice_id !== undefined ? this.invoice_id.toString() : '';
        const quantityString = this.quantity !== null && this.quantity !== undefined ? this.quantity.toString() : '';
        const type = this.type || ''; // Asegúrate de que type no sea null o undefined
        const isAddString = this.isAdd() ? 'importado' : 'salida';

        return match(text, invoiceIdString, quantityString, type, isAddString);
    }
}


export class M_Product_Invoice implements SearchFiltrable {
    id: number;
    product_id: number;
    invoice_id: number;
    invoice_id_company: string;
    quantity: number;
    details: string;
    discount: number | null;
    custom: number;
    created_at: CustomDate;
    token: string;
    type: "invoice" | "abono" | "action";

    constructor(d: any) {
        this.id = d.id;
        this.type = d.type;
        this.product_id = d.product_id;
        this.invoice_id = d.invoice_id;
        this.quantity = d.quantity;
        this.details = d.details;
        this.discount = d.discount;
        this.custom = d.custom;
        this.created_at = new CustomDate(d.created_at);
        this.token = d.token;
        this.invoice_id_company = d.invoice_id_company;
    }

    get isAbono() {
        return this.type == "abono";
    }

    isAdd() {
        return this.details == 'add';
    }

    defaultSearchFilter(text: string): boolean {
        return match(text, this.invoice_id_company, this.quantity.toString());
    }
}

export class M_Product extends M_ProductManager implements SearchFiltrable, I_ClassSearcher<M_Product> {
    reference: string;
    product_id: number | undefined;
    stock: number;
    cs_stock: number;
    recycle: string;
    product_invoice: M_Product_Invoice[] = []
    product_move: M_Product_Move[] = []
    created_at: CustomDate | undefined;
    updated_at: CustomDate | undefined;
    category: ProductCategory = ProductCategory.GENERAL;
    extra_field: number | Signus | undefined;

    constructor(d: any) {
        super(d);
        this.reference = d.reference;
        this.product_id = d.id ? d.id : d.product_id ? d.product_id : undefined;
        this.tax = d.tax;
        this.recycle = d.recycle;
        this.stock = d.stock;
        this.buy_price = d.buy_price ? d.buy_price : 0;
        this.created_at = d.created_at ? new CustomDate(d.created_at) : undefined;
        this.updated_at = d.updated_at ? new CustomDate(d.updated_at) : undefined;

        if (typeof d.category == "number") {
            this.category = d.category;
        }

        /** Product invoice */
        if (d.invoices) {
            for (let i = 0; i < d.invoices.length; i++) {

            }
        }

        if (d.hist) {
            for (let i = 0; i < d.hist.length; i++) {
                if (d.hist[i].type) {
                    if (d.hist[i].type == "manual" || d.hist[i].type == "import") {
                        this.product_move.push(new M_Product_Move(d.hist[i]))
                    }
                    else if (d.hist[i].type == "invoice" || d.hist[i].type == "abono" || d.hist[i].type == "action") {
                        this.product_invoice.push(new M_Product_Invoice(d.hist[i]))
                    }
                }
            }
        }

        this.cs_stock = this.stock;
        this.setupExtraField(d.extra_field);
    }

    /** Set up the product extra field. */
    setupExtraField(extra_field: number | Signus | undefined) {
        if (extra_field) {
            if ((this.isLiquid && typeof extra_field == "number") || (this.isWheel && typeof extra_field != "number")) {
                this.extra_field = extra_field;
            }
            else if (this.isWheel && typeof extra_field == "number") {
                this.extra_field = getSignusById(extra_field);
            }
            else if (typeof extra_field == "string") {
                //throw Error("El campo extra de un producto NO PUEDE SER DE TIPO STRING")
            }
        }
        else if (this.category == ProductCategory.LIQUID) {
            this.extra_field = 1; //By default 1L
        }
        else if (this.category == ProductCategory.WHEEL) {
            this.extra_field = SIGNUS_N1;
        }
    }

    get price_iva() {
        return (this.price + (this.price * (this.tax / 100))).toFixed(2)
    }

    getOverStock(val: number) {
        return Math.abs(this.stock - val);
    }

    override getTotal(extrafields = false, isInterno = false): number {
        let total = super.getTotal(extrafields, isInterno);
        if (extrafields && !isInterno) {
            total += this.getExtraFieldTotalPrice;
        }
        return total;
    }

    copyToCustom() {
        return new M_CustomProduct(
            {
                name: this.name,
                reference: this.reference,
                quantity: this.quantity,
                buy_price: this.buy_price ? this.buy_price : 0,
                price: this.price,
                discount: this.discount
            }
        )
    }

    override getInvoiceCallObject(modifyStock: boolean) {
        let obj: invoiceCallProduct = {
            prod_id: this.line_id!,
            price : this.price,
            quantity: !modifyStock ? "noaction" : undefined,
            custom: modifyStock ? this.quantity : undefined,
            discount: this.discount,
        };
        return obj;
    }

    /** Copy the main product properties from other product */
    copyCoreAttributes(other: M_Product) {
        this.reference = other.reference;
        this.name = other.name;
        this.stock = other.stock;
        this.recycle = other.recycle;
        this.category = other.category;
        this.extra_field = other.extra_field;
        this.buy_price = other.buy_price;
        this.price = other.price;
        this.tax = other.tax;
    }

    defaultSearchFilter(text: string): boolean {
        text = text.toLocaleLowerCase();
        return match(text,
            this.reference,
            this.name,
            this.price.toString(),
            this.buy_price.toString(),
            this.stock.toString());
    }

    /** Get product extra field :
     * @returns Liters of the product on LIQUID category. By default 1.
     * @returns SIGNUS (price x neumatic diameter) on WHEEL. By default SIGNUS_N1
     */
    get getExtraField() {
        if (!this.isLiquid && !this.isWheel) { return 0; }
        if (this.isLiquid) {
            if (typeof this.extra_field == "number") { return this.extra_field; }
            return 1; //Default 1L per product
        }
        else if (this.isWheel) {
            if (!this.extra_field) { return SIGNUS_N1.price; }
            if (typeof this.extra_field != "number") { return this.extra_field.price; }
            return SIGNUS_N1.price; //Default SIGNUS_N1
        }
        return 1;
    }

    get isLiquid() { return this.category == ProductCategory.LIQUID }
    get isWheel() { return this.category == ProductCategory.WHEEL }

    /** CLASS SEARCHER INTERFACE */
    get endpoint() { return endpoints.products }
    get cs_id() { return this.product_id ? this.product_id : -1; }
    get icon() {
        switch (this.category) {
            case ProductCategory.GENERAL:
                return "barcode"
            case ProductCategory.WRENCH:
                return "build_circle"
            case ProductCategory.WHEEL:
                return "tire_repair"
            case ProductCategory.LIGHT:
                return "lightbulb"
            case ProductCategory.LIQUID:
                return "water_drop"
            case ProductCategory.BOUTIQUE:
                return "checkroom"
            default:
                return "barcode"
        }
    }

    get getExtraFieldTotalPrice() {
        if (this.isLiquid) { return this.quantity * this.getExtraField * SIGAUS_PRICE_LITER; }
        else if (this.isWheel) { return this.quantity * this.getExtraField; }
        return 0;
    }
    get getNotExtraFieldTotalPrice() {
        if (this.isLiquid) { return this.quantity}
        else if (this.isWheel) { return this.quantity}
        return 0;
    }

    get getCategoryName() {
        switch (this.category) {
            case ProductCategory.GENERAL:
                return "General";
            case ProductCategory.WRENCH:
                return "Recambios";
            case ProductCategory.WHEEL:
                return "Neumáticos";
            case ProductCategory.LIGHT:
                return "Electrónica";
            case ProductCategory.LIQUID:
                return "Aceite";
            case ProductCategory.BOUTIQUE:
                return "Boutique";
            default:
                return "Sin categoría"
        }

    }

    get onlyCopies() { return true }
    get typeof() { return M_Product }
    getInputText(): string { return this.name; }
    createNew(d: any) { return new M_Product(d) }
    getOptionText(): [string, string] {
        return ([this.name, this.reference])
    }

    get leftNumber() {
        return {
            value: this.cs_stock ? this.cs_stock > 0 ? this.cs_stock + ' unidades' : "¡Fuera de stock!" : "Sin stock",
            class_: "product-hint-class-searcher " + (this.cs_stock ? this.cs_stock > 0 ? "c_t2" : "c_r" : "c_r")
        }
    }
}