<app-close-dialog-button></app-close-dialog-button>

<p mat-dialog-title>Política de Cancelación y Devolución de Eina</p>

<mat-dialog-content>
    <p mat-dialog-subtitle>1. Introducción</p>
    <p>La presente política de cancelación y devolución se aplica a todos los usuarios de la plataforma en línea "Eina",
        que ofrece servicios de suscripción mensual. En Eina, nuestra prioridad es brindar un servicio excepcional a
        nuestros usuarios, y esta política tiene como objetivo proporcionar pautas claras y transparentes para las
        cancelaciones y devoluciones.</p>

    <p mat-dialog-subtitle>2. Cancelación de la Suscripción</p>
    <p>Los usuarios de Eina pueden cancelar su suscripción mensual en cualquier momento. Para solicitar la cancelación
        de la suscripción, el usuario debe seguir los siguientes pasos:</p>
    <ol>
        <li>Iniciar sesión en su cuenta de usuario en el sitio web de Eina.</li>
        <li>Acceder a la sección de "Mi Cuenta" o "Configuración de Suscripción".</li>
        <li>Seleccionar la opción de "Cancelar Suscripción" y seguir las instrucciones proporcionadas.</li>
    </ol>
    <p>Una vez que se haya solicitado la cancelación de la suscripción, el acceso a los servicios de Eina seguirá
        vigente hasta el final del período de suscripción mensual actual. No se efectuarán reembolsos por el tiempo
        restante en el período de suscripción actual.</p>

    <p mat-dialog-subtitle>3. Devoluciones y Reembolsos</p>
    <p>Eina ofrece una garantía de satisfacción de 30 días para los nuevos usuarios que se hayan registrado por primera
        vez. Si un nuevo usuario no está satisfecho con los servicios de Eina dentro de los primeros 30 días de su
        suscripción inicial, puede solicitar un reembolso completo.</p>
    <p>Para solicitar un reembolso en el período de garantía de satisfacción de 30 días, el usuario debe ponerse en
        contacto con nuestro equipo de atención al cliente a través de los canales de comunicación proporcionados en el
        sitio web de Eina. El equipo de atención al cliente revisará la solicitud y procesará el reembolso en un plazo
        de 5 a 7 días hábiles.</p>
    <p>Es importante destacar que una vez transcurridos los primeros 30 días de la suscripción inicial, Eina no ofrece
        reembolsos por cancelaciones. Los usuarios pueden cancelar su suscripción en cualquier momento, pero no se
        realizarán reembolsos por el tiempo no utilizado en el período de suscripción mensual actual.</p>

    <p mat-dialog-subtitle>4. Cambios en la Política</p>
    <p>Eina se reserva el derecho de modificar esta política de cancelación y devolución en cualquier momento. Los
        cambios se harán efectivos una vez que se publiquen en el sitio web de Eina. Se recomienda a los usuarios
        revisar regularmente esta política para mantenerse informados de cualquier modificación.</p>

    <p mat-dialog-subtitle>5. Contacto</p>
    <p>Para obtener más información sobre la política de cancelación y devolución de Eina o para realizar consultas
        relacionadas, los usuarios pueden ponerse en contacto con nuestro equipo de atención al cliente a través de los
        canales de comunicación proporcionados en el sitio web.</p>
</mat-dialog-content>
<div mat-dialog-actions align="center">
    <button mat-flat-button color="warn" mat-dialog-close>OK</button>
  </div>