
<div class="df jcc">
    <div class="df fdr aic m10">
        <input #primary type="color" (input)="refresh()" [value]='defaultColor()' 
        [style]="'border: 2px solid ' + getPrimary().darkenColor() +'; overflow: inherit;'">
        <p class="hex">{{primary.value}}</p>
        <!--<p style="margin-top: 10px;">Color primario</p>!-->
    </div>
    <!--<div class="df fdc aic m10">
        <input #secondary type="color" (input)="refresh()" [value]='defaultColorSecondary()' style="box-shadow: 5px 3px 15px 0px rgba(0,0,0,0.86);">
        <p style="margin-top: 10px;">Color del texto</p>
    </div>!-->
</div>
