<div class="advanced-details">
    <div *ngFor="let d of data; let last = last">
        <div class="keyvalueparent" [ngClass]="{'nopaddingleft' : noPaddingLeft, 'dn': d[0]=='invisible'}">
            <div class="key" [ngClass]="{'free-align':  freeAlign}">
                <p *ngIf="d[0] !='spacer'">{{d[0]}}
                    <button color="primary" mat-icon-button *ngIf="hiddenOnLoad == true" (click)="toggleVisibility($event)">
                        <mat-icon>{{ hidden ? "visibility" : "visibility_off"}}</mat-icon>
                    </button>
                </p>
            </div>
            <div class="value">
                <p *ngIf="d[0] !='spacer'"
                    [class]="getClass(d)"
                    [ngClass]="{'c_t2' : !d[1], 'visible' : !hidden, 'hidden' : hidden, 'df aic' : isEmailAlways(d)}">


                    <span *ngIf="!isMoney(d)">{{!d[1] && !isConsents(d) ? 'Sin datos' : isConsents(d) ? '' :  d[1] + (haveSymbol(d) ? appendSymbol(d) : '')}}</span>
                    <span *ngIf="isMoney(d)" money [val]="d[1]"></span>
                    
                    <span *ngIf="d[2] != undefined && d[2].otherRow" [class]="'otherclassTxt ' + d[2].otherRow.class">
                        {{d[2].otherRow.text}}
                    </span>
                    
                    <!-- Modifiable field-->
                    <span *ngIf="d[2]?.modifiable" class="fss c_a cp ml5" (click)="onClickModify.emit();">Modificar</span>

                    <!-- DOB -->
                    <lord-icon *ngIf="isBirthday(d)" src="https://cdn.lordicon.com/mocuiwph.json" trigger="hover"
                    class="lord-small">
                    </lord-icon>

                    <!-- EMAIL ALWAYS -->
                    <app-user-email-always *ngIf="isEmailAlways(d)" [client]="getUser(d)"></app-user-email-always>

                    <!-- CONSENTS -->
                    <app-client-consents *ngIf="isConsents(d)" [client]="getUser(d)"></app-client-consents>
                </p>
                <div *ngIf="d[2] && d[2].template" [ngTemplateOutlet]="d[2].template"></div>
            </div>
        </div>
        <mat-divider *ngIf="d[0] !='spacer' &&  d[0] != 'invisible'"></mat-divider>
    </div>
</div>