<!--Nº or, estado, operario, client, fecha entrega → Si sobra espacio (matricula, marca modelo…)-->
<table mat-table [dataSource]="dataSource" *ngIf="client.hasVehicles(); else noData">
    <ng-container matColumnDef="license">
        <th mat-header-cell *matHeaderCellDef> Matrícula </th>
        <td mat-cell *matCellDef="let element"> 
            <mat-icon class="vam">{{element.icon}}</mat-icon>
            {{element.license}}
        </td>
    </ng-container>

    <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef> Marca </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.brand; else noRow">{{element.brand}}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="model" *ngIf="responsiveS.w > 500">
        <th mat-header-cell *matHeaderCellDef> Modelo </th>
        <td mat-cell *matCellDef="let element"> 
            <span *ngIf="element.model; else noRow">{{element.model}}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsByScreenSize"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsByScreenSize;" (click)="goVehicle(row)"></tr>
</table>

<ng-template #noRow>
    <span class="c_t2">Sin datps</span>
</ng-template>

<ng-template #noData>
    <p *ngIf="!client?.hasVehicles()">Este cliente aún no tiene asociado ningún vehículo. Puedes asociarle un
        nuevo vehículo haciendo clic en el siguiente botón:</p>
</ng-template>

<div style="margin-top: 10px;">
    <button class="w100" mat-stroked-button (click)="paramS.go(v.createvehicle, client!.client_id, 'client')">
        <mat-icon>add</mat-icon>
        Dar de alta vehículo
    </button>
</div>