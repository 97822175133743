/**
 * ENTORNO DE PRODUCCIÓN
 * 
 * Para arrancar en local :
 * ng serve --configuration=pro
 * 
 * Para generar versión en producción
 * ng build --configuration=pro
 */

export const environment = {
  local : false,
  production: true,
  preproduction : false,
  dev : false,
  apiUrl : "https://backerp.sinigual.com",
  analyticsId : "G-4L0E60XS6T"
};
