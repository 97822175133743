import { CustomDate, M_Parser, parseOBJ } from "@sinigual/angular-lib";
import { M_RAGroup } from "./M_RAGroup";

interface optionalAttributes {
    assigned_to?: number | undefined;
    schedule?: CustomDate | undefined;
    delivery?: CustomDate | undefined;
    km?: number | undefined;
    notes?: string | undefined;
    title?: string | undefined;
    fuel: number;
}

export class M_ActiveReception implements optionalAttributes, M_Parser {
    needsToBeParsed: boolean = true;
    client_id: number;
    vehicle_id: number;
    fuel: number;
    groups: M_RAGroup[];
    schedule: CustomDate | undefined;
    delivery: CustomDate | undefined;
    km: number | undefined;
    notes: string | undefined;
    assigned_to: number | undefined;
    title: string | undefined;
    id: number | undefined;
    clientInvoice_id: number | undefined;

    constructor(client_id: number, clientInvoice_id: number | undefined, vehicle_id: number, optional: optionalAttributes, groups: M_RAGroup[]) {
        this.client_id = client_id;
        this.clientInvoice_id = clientInvoice_id;
        this.vehicle_id = vehicle_id;
        this.fuel = optional.fuel;
        this.schedule = optional.schedule;
        this.delivery = optional.delivery;
        this.km = optional.km;
        this.notes = optional.notes;
        this.assigned_to = optional.assigned_to;
        this.title = optional.title;
        this.groups = groups;
    }

    parse() {
        let obj = parseOBJ(this);
        if (this.delivery) {
            obj['delivery'] = new CustomDate(obj['delivery']).dataBaseFormat;
        }
        if (this.schedule) {
            obj['schedule'] = new CustomDate(obj['schedule']).dataBaseFormat;
        }

        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].parse();;
        }
        return obj;
    }
}