import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard, Views } from '@sinigual/angular-lib';
import { BillsComponent } from './views/bills/bills.component';
import { BudgetComponent } from './views/budget/budget.component';
import { ClientDetailsComponent } from './views/client-details/client-details.component';
import { ClientsComponent } from './views/clients/clients.component';
import { CreateRecoverPasswordComponent } from './views/create-recover-password/create-recover-password.component';
import { CreateBillComponent } from './views/create-bill/create-bill.component';
import { CreateClientComponent } from './views/create-client/create-client.component';
import { CreateProductComponent } from './views/create-product/create-product.component';
import { CreateVehicleComponent } from './views/create-vehicle/create-vehicle.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LinkVehicleComponent } from './views/link-vehicle/link-vehicle.component';
import { LoginComponent } from './views/login/login.component';
import { OrComponent } from './views/or/or.component';
import { CreateOr } from './views/or-create-edit/create-or/create-or.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { ProductDetailsComponent } from './views/product-details/product-details.component';
import { ProductsComponent } from './views/products/products.component';
import { ProfileComponent } from './views/profile/profile.component';
import { RegisterComponent } from './views/register/register.component';
import { VehicleDetailsComponent } from './views/vehicle-details/vehicle-details.component';
import { VehiclesComponent } from './views/vehicles/vehicles.component';
import { CreateEditBudgetComponent } from './views/create-budget/create-edit-budget.component';
import { EditOrComponent } from './views/or-create-edit/edit-or/edit-or.component';
import { DataUploadComponent } from './core/components/data-upload/data-upload.component';
import { DeactivateGuard } from './core/guards/deactivate-guard-';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { IssuesViewComponent } from './views/issues-view/issues-view.component';
import { SubscribedUserGuard } from './core/guards/subscribed-user.guard';
import { CargaTallerParentComponent } from './views/carga-taller-parent/carga-taller-parent.component';
import { AbonoInvoiceComponent } from './views/abono-invoice/abono-invoice.component';
import { CompanyComponent } from './views/company/company.component';
import { RolesEnum } from './core/enums/RolesEnum';
import { environment } from 'src/environments/environment';
import { FrontendDesignComponent } from './views/frontend-design/frontend-design.component';
import { LandingComponent } from './landing/landing.component';

export const ViewPath = {
  login :                   new Views("login",                  "Login", true),
  landing :                 new Views("landing",                "Landing", true),
  registerCompleted :       new Views("register-completed",     "Login", true),
  createRecoverPassword :   new Views("newpassword",            "Nueva contraseña", true),
  register :                new Views("register",               "Registrarse en Eina", true),
  dashboard :               new Views("",                       "Dashboard", false),
  profile :                 new Views("profile",                "Perfil", false),
  clientes :                new Views("clientes",               "Clientes", false),
  createClient :            new Views("createclient",           "Crear cliente", false),
  vehiculos :               new Views("vehiculos",              "Vehiculos", false),
  createvehicle :           new Views("createvehicle",          "Crear Vehículo", false),
  linkvehicle :             new Views("linkvehicle",            "Vincular Vehículo", false),
  products :                new Views("products",               "Productos", false),
  createProduct :           new Views("createproducts",         "Crear Producto", false),
  facturas :                new Views("facturas",               "Facturas", false),
  createBill :              new Views("createbill",             "Crear factura", false),
  business :                new Views("empresa",                "Empresa", false, [RolesEnum.ADMIN]),
  clientDetails :           new Views("clientdetails",          "Detalles del cliente", false),
  layout :                  new Views("layout",                 "Creación de plantillas", false),
  vehicleDetails :          new Views("vehicledetails",         "Detalles del vehiculo", false),
  productDetails :          new Views("producdetails",          "Detalles del producto", false),
  or :                      new Views("or",                     "Ordenes de reparación", false),
  createOr :                new Views("creteor",                "Crear OR", false),
  editOr :                  new Views("editor",                 "Editar or", false),
  budget :                  new Views("budget",                 "Presupuestos", false),
  createEditBudget :        new Views("createeditbudget",       "Presupuesto", false),
  cargataller :             new Views("workload",               "Carga de taller", false),
  dataupload :              new Views("dataupload",             "Importación de datos", false),
  subscription :            new Views("subscription",           "Subscripción", false, [RolesEnum.ADMIN]),
  abono :                   new Views("abono",                  "Abono", false),
  issues :                  new Views("issues",                 "Incidencias", false),
  frontend :                new Views("frontend",               "Frontend", false, environment.local || environment.dev ? [] : [-1]),
  pageNotFound :            new Views("notfound",               "Error", true),
}

const routes: Routes = [
  { path: ViewPath.login.path,                  component: LoginComponent},  //👍
  { path: ViewPath.landing.path,                component: LandingComponent},  //👍
  { path: ViewPath.registerCompleted.path,      component: LoginComponent},  //👍
  { path: ViewPath.createRecoverPassword.path,  component: CreateRecoverPasswordComponent}, //👍
  { path: ViewPath.register.path,               component: RegisterComponent}, //👍
  { path: ViewPath.dashboard.path,              component: DashboardComponent,        canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.profile.path,                component: ProfileComponent,          canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.clientes.path,               component: ClientsComponent,          canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.createClient.path,           component: CreateClientComponent,     canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.linkvehicle.path,            component: LinkVehicleComponent,      canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.vehiculos.path,              component: VehiclesComponent,         canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.createvehicle.path,          component: CreateVehicleComponent,    canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.products.path,               component: ProductsComponent,         canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.createProduct.path,          component: CreateProductComponent,    canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.facturas.path,               component: BillsComponent,            canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.createBill.path,             component: CreateBillComponent,       canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.business.path,               component: CompanyComponent,          canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.clientDetails.path,          component: ClientDetailsComponent,    canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.vehicleDetails.path,         component: VehicleDetailsComponent,   canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.productDetails.path,         component: ProductDetailsComponent,   canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.or.path,                     component: OrComponent,               canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.budget.path,                 component: BudgetComponent,           canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.createOr.path,               component: CreateOr,                  canActivate:[AuthGuardGuard]}, //🟥
  { path: ViewPath.editOr.path,                 component: EditOrComponent,           canActivate:[AuthGuardGuard], canDeactivate: [DeactivateGuard]},//🟥
  { path: ViewPath.createEditBudget.path,       component: CreateEditBudgetComponent, canActivate:[AuthGuardGuard], canDeactivate: [DeactivateGuard]},//🟥
  { path: ViewPath.cargataller.path,            component: CargaTallerParentComponent,canActivate:[AuthGuardGuard, SubscribedUserGuard]},//🟥
  { path: ViewPath.dataupload.path,             component: DataUploadComponent,       canActivate:[AuthGuardGuard, SubscribedUserGuard]},//👍
  { path: ViewPath.subscription.path,           component: SubscriptionComponent,     canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.abono.path,                  component: AbonoInvoiceComponent,     canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.issues.path,                 component: IssuesViewComponent,       canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.frontend.path,               component: FrontendDesignComponent,   canActivate:[AuthGuardGuard]}, //👍
  { path: ViewPath.pageNotFound.path,           component: PageNotFoundComponent}, //Error 404 //👍
  { path: '**', pathMatch: 'full',              component: PageNotFoundComponent}, //Error 404 //👍

];

@NgModule({
  imports: [RouterModule.forRoot(routes , {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
