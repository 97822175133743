<div class="linkParent">

    <app-go-back [show]="ve != undefined" [text]="'Volver atrás'" (click)="returnClient()"></app-go-back>

    <app-view-title>CAMBIAR PROPIETARIO</app-view-title>
    <div class="mt20">
        <app-card [contentLoaded]="ve != undefined">
            <app-card-title>Propietario actual</app-card-title>
            <div *ngIf="ve != undefined">
                <div class="df fww jcsa">
                    <app-client-info [client]="ve.client" [small]="true"></app-client-info>
                    <app-vehicle-info [vehicle]="ve" [small]="true"></app-vehicle-info>
                </div>
            </div>
        </app-card>
    </div>
    <div class="df jcc mb20 mt20">
        <lord-icon class="rotate90 lord-small" src="https://cdn.lordicon.com/pvcozflw.json" trigger="loop" delay="3000"
            colors="primary:#121331,secondary:{{warn()}},tertiary:{{primary()}}">
        </lord-icon>
    </div>
    <div class="mb20">
        <app-vehicle-owner-search-create [contentLoaded]="ve != undefined" [title]="'Nuevo propietario'"
            [isLinkVehicle]="true" [currentOwner_id]="ve?.client?.client_id">
            <div class="df jcc">
                <button appTrialbutton mat-flat-button color="warn" style="margin-top: 10px" (click)="linkVehicle()">
                    <span>
                        Establecer nuevo propietario
                    </span>
                </button>
            </div>
        </app-vehicle-owner-search-create>
    </div>

</div>