import { M_Brand } from "../../models/M_Brand";
import { M_Model } from "../../models/M_Model";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { UntypedFormGroup } from "@angular/forms";
import { ApiService } from "../../api/api.service";
import { ChangeDetectorRef } from "@angular/core";
import { SearchService } from "@sinigual/angular-lib";
import { VehicleType } from "../../enums/VehicleType";

export class BrandModelManager {

    /** All brands suggested on screen */
    showingBrands: M_Brand[] = [];
    /** Filtered brands depen according to models form input*/
    filteredBrands: M_Brand[] = [];

    /** All models suggested on screen */
    filteredModels: M_Model[] = [];
    /** Filtered models according to models form input*/
    showingModels: M_Model[] = [];


    /** Saved Brands */
    savedBrands: [VehicleType, M_Brand[]][] = [];

    private form: UntypedFormGroup;
    private apiS: ApiService;
    private searchS: SearchService;
    private chdRef: ChangeDetectorRef;

    constructor(form: UntypedFormGroup, apiS: ApiService, searchS: SearchService, chdRef: ChangeDetectorRef) {
        this.form = form;
        this.apiS = apiS;
        this.searchS = searchS;
        this.chdRef = chdRef;

        this.form.get("brand")?.valueChanges.subscribe(val => {
            this.filterBrands(val)
        })

        this.form.get("model")?.valueChanges.subscribe(val => {
            this.filterModels(val);
        })

        this.form.get("type")?.valueChanges.subscribe(val => {
            this.initByVehicleType(val);
        })
    }


    /** Api call accaording to the vehicle type on screen */
    initByVehicleType(vt: VehicleType) {
        return new Promise<any>(resolve => {
            let isSaved = this.isbrandSaved(vt);
            if (isSaved) {
                this.initShowingBrands(isSaved[1]);
                resolve(true);
            }
            else {
                if (vt == VehicleType.bike) {
                    this.apiS.brands(0).then(res => {
                        this.initShowingBrands(res);
                        this.savedBrands.push([vt, res]);
                        resolve(true);
                    })
                }
                else if (vt == VehicleType.car) {
                    this.apiS.brands(1).then(res => {
                        this.initShowingBrands(res);
                        this.savedBrands.push([vt, res]);
                        resolve(true);
                    })

                }
                else {
                    console.error("No vehicle type");
                    resolve(false);
                }
            }
        });
    }

    isbrandSaved(vt: VehicleType) {
        let sb = this.savedBrands.find(bf => {
            return bf[0] == vt;
        })
        return sb;
    }

    initShowingBrands(v: M_Brand[]) {
        this.showingBrands = v;
        this.refreshModelsBasedOnBrand(this.form.get('brand')?.value);
    }

    onChangeVehicleType(vt: VehicleType) {
        this.initByVehicleType(vt);
    }

    refreshModelsBasedOnBrand(val: MatAutocompleteSelectedEvent | string | undefined, clearModel = false) {
        let value = val ? typeof val == "string" ? val : val.option.value : undefined;
        if (typeof value == "string") {

            let founded = false;

            this.showingBrands.forEach(b => {
                if (b.name.toLocaleLowerCase() == value.toLocaleLowerCase()) {
                    founded = true;
                    this.apiS.brandModel(b.id).then(res => {
                        this.showingModels = res;
                        /** Show the options */
                        this.filterModels('');
                        this.chdRef.detectChanges();
                    })
                }
            })

            if (!founded) {
                this.showingModels = [];
                this.chdRef.detectChanges();
            }

        }

        if (clearModel) {
            this.clearModel();
        }
    }

    filterBrands(val: string | undefined) {
        this.filteredBrands = val ? this.showingBrands.filter(option => this.searchS.match(val, option.name)) : this.showingBrands;
    }

    filterModels(val: string | undefined) {
        this.filteredModels = val ? this.showingModels.filter(option => this.searchS.match(val, option.name)) : this.showingModels;
    }

    clearModel() {
        this.form.patchValue({ 'model': undefined });
    }
}