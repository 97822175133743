<div>
    <div [ngClass]="state=='create'? 'double-card' : 'create-item'">
        <app-go-back *ngIf=" ve != undefined" [show]="state == 'edit'" [text]="ve!.getName()" (click)="goBackVehicle()"></app-go-back>
        <app-card [contentLoaded]="state != undefined && vfc.loaded" class="section">
            <app-view-title>{{getTitle()}}</app-view-title>
            <div *ngIf="state == 'alta_vehiculo'">
                <div *ngIf="c != undefined">
                    <p class="mt10">Puedes crear un vehículo y vincularlo con el cliente 
                        <span class="c_r fwb cp" (click)="params.go(v.clientDetails, c.client_id)">{{c.getName()}}</span>
                    </p>
                </div>
            </div>
            <app-vehicleform #vfc></app-vehicleform>
           
            <div class="df jcc">
                <button appTrialbutton mat-flat-button color="warn" *ngIf="state == 'alta_vehiculo' || state == 'edit'" (click)="create()" style="margin-top: 20px;">
                    <span>{{getButton()}}</span>
                </button>
            </div>
        </app-card>
        <div *ngIf="state != 'alta_vehiculo'" class="mb20"></div> 
        <div *ngIf="state == 'create'" class="section">
            <app-vehicle-owner-search-create [contentLoaded]="vfc.loaded">
                <div class="df jcc">
                    <button appTrialbutton mat-flat-button color="warn" class="onboarding-vehicle-finish" (click)="create()" style="margin-top: 20px;">
                        <span>{{getButton()}}</span>
                    </button>
                </div>
            </app-vehicle-owner-search-create>
        </div>
    </div>

</div>
